import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { avatarApplication as app } from '../scene/application';

function loadAnimation(id) {

    if (!!id) {

        const web = app.web;
        const mixer = app.mixer;
        const model = app.model;
    
        const dirName = app.isFemale() ? 'girl' : 'boy';
        const ext = web ? '_web.glb' : '.fbx';
    
        let path = id.indexOf('/') > -1 ? id : 'animations/' + dirName + '/' + id;
        path = path + ext;
        
        // console.log('PATH', path);
    
        const animationLoader = web ? new GLTFLoader() : new FBXLoader();
    
        const animationOverride = {
            'ACa_G18_Lotos_Meditation_Loop': {
                modelPositionRegular: [0, 0, 0],
                modelPositionWeb: [0, -60, 0]
            }
        };
    
        animationLoader.load(`${path}`, (anim) => {
        
            model.animations[0] = anim.animations[0];
    
            const modelPosition = !!animationOverride[id] 
                ? web 
                    ? animationOverride[id].modelPositionWeb 
                    : animationOverride[id].modelPositionRegular  
                : [0, -100, 0];
    
            model.position.set(modelPosition[0], modelPosition[1], modelPosition[2]);
    
            mixer.stopAllAction();
            const action = mixer.clipAction(model.animations[0]);
            action.play();
        
        },
        (xhr) => {},
        (error) => {
            console.log('Animation loading failed');
        });

    }
    
}

export { loadAnimation }