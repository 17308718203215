import api from "../api";


export const avatarService = {

    deleteAvatar: (id) => api.delete(`users/avatar/${id}`),

    postItems: (id, obj) =>
        api.post(
            'users/avatar/items', 
            {
                avatarId: id,
                itemIds: JSON.stringify(obj),
            }
        ),
    
    createAvatar: (id) => 
        api.post(
            'users/avatar',
            {
                userId: id,
                avatarType:'AvatarEngine'
            }
        ).then((response) => {
            return !!response ? response.data.id : '';
        }),

    fetchUserAvatars: (id) => api.get(`users/${id}/avatar`)

}