import Stats from 'stats.js'
import { avatarApplication as app } from './application';

function addStats() {

    const stats = new Stats();
    app.stats = stats;

    stats.setMode(1);

    stats.domElement.style.position = 'absolute';
    stats.domElement.style.left = '0';
    stats.domElement.style.top = '0';
    if(true){
        document.body.appendChild(stats.dom);
    }
    stats.showPanel(0);

}

export { addStats }