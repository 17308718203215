// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__section/side-menu__section.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__section-content/side-menu__section-content.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".side-menu {\n  height: fit-content;\n  display: flex;\n  flex-direction: column;\n  visibility: visible;\n  transition: all 0.3s ease;\n  opacity: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/css/components/side-menu/side-menu.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,UAAU;AACZ","sourcesContent":["@import url(./__section/side-menu__section.css);\n@import url(./__section-content/side-menu__section-content.css);\n\n.side-menu {\n  height: fit-content;\n  display: flex;\n  flex-direction: column;\n  visibility: visible;\n  transition: all 0.3s ease;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
