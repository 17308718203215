import {authService} from "../services";
import api, {setApiTokens} from "../api";
import {cookies, getDateAfter30Days} from "../utils/cookies";

async function signUp(e, form, signInContainer, signUpContainer) {
    e.preventDefault();
    let inputs;
    const button = signUpContainer.querySelector('.js-sign-up-form-button');
    const errorBlock = signUpContainer.querySelector('.auth-form-error');

    inputs = form;

    const signUpObj = {
        email: inputs[1].value,
        firstname: inputs[0].value.split(' ')[0],
        lastname: inputs[0].value.split(' ')[1],
        password: inputs[2].value,
    };

    const response = await fetch('https://pg.sysdyn.ru/api/v1/auth/signup', {
        body: JSON.stringify(signUpObj),
        headers: {
            Accept: '*/*',
            Authorization: '',
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });

    if (response.status === 200) {
        signInContainer.style.display = 'flex';
        signUpContainer.style.display = 'none';
        inputs[0].value = '';
        inputs[1].value = '';
        inputs[2].value = '';
    } else if (response.status === 401) {
        inputs[0].style.color = '#FA7268';
        inputs[1].style.color = '#FA7268';
        inputs[2].style.color = '#FA7268';
        errorBlock.innerHTML = 'Такой пользователь уже зарегистрирован!';
    }

    button.addEventListener('blur', () => {
        inputs[0].style.color = '#ffffff';
        inputs[1].style.color = '#ffffff';
        inputs[2].style.color = '#ffffff';
        if (errorBlock.innerHTML.trim() !== '') {
            inputs[0].value = '';
            inputs[1].value = '';
            inputs[2].value = '';
            errorBlock.innerHTML = '';
        }
    });
}

async function signIn(e, form, signInContainer, signUpContainer) {
    e.preventDefault();
    let inputs;
    const buttonSignIn = signInContainer.querySelector('.js-sign-in-form-button');
    const errorBlockSignIn = signInContainer.querySelector('.auth-form-error-sign-in');
    inputs = form;

    let jsonResp;
    await authService.sighIn(inputs[0].value, inputs[1].value).then((e) => {
        jsonResp = e?.data;

        if (e?.data.accessToken) {
            //jsonResp = event;

            //     setApiTokens({auth: event.accessToken, refreshToken: event.refreshToken})
            signUpContainer.style.display = 'flex';
            signInContainer.style.display = 'none';
            inputs[0].value = '';
            inputs[1].value = '';
            api.defaults.headers.Authorization = `Bearer ${e.data.accessToken}`;
            cookies.set(
                'auth',
                {auth: e.data.accessToken, refreshToken: e.data.refreshToken},
                {
                    path: '/',
                    secure: true,
                    sameSite: 'lax',
                    expires: getDateAfter30Days(),
                }
            );
        } else {
            inputs[0].style.color = '#FA7268';
            inputs[1].style.color = '#FA7268';
            errorBlockSignIn.innerHTML = 'Неправильный логин или пароль!';
        }


    });

    return jsonResp;

}

export {signUp, signIn};
