import { avatarApplication as app } from '../scene/application';

function setBonesParams(id) {

    const body = app.getAvatarBodyMesh();
    const nails = app.model.getObjectByName(app.isFemale() ? 'CHs_G18_Nails' : 'CHs_B18_Nails');

    if (!!body && !!nails) {

        const bodyToeLeft = body.skeleton.getBoneByName('Joint_Toe_L');
        const bodyToeRight = body.skeleton.getBoneByName('Joint_Toe_R');

        const bodyFootLeft = body.skeleton.getBoneByName('Joint_Foot_L');
        const bodyFootRight = body.skeleton.getBoneByName('Joint_Foot_R');

        const nailsToeLeft = nails.skeleton.getBoneByName('Joint_Toe_L');
        const nailsToeRight = nails.skeleton.getBoneByName('Joint_Toe_R');

        if (id === 'WR_G18_Boots_001') {
            bodyToeLeft.rotation.y = -1;
            bodyToeLeft.position.z = 1.5;
            bodyToeLeft.scale.setScalar(0.1) ;

            bodyToeRight.rotation.y = -1;
            bodyToeRight.position.z = -1.5;
            bodyToeRight.scale.setScalar(0.1) ;

            bodyFootLeft.rotation.y = -0.2;
            bodyFootRight.rotation.y = -0.2;

            nailsToeLeft.rotation.y = -1;
            nailsToeRight.rotation.y = -1;
        }
        
        if (id === 'WR_G18_Shoes_Slippers_Banny') {
            bodyToeLeft.position.z = 1.5;
            bodyToeLeft.rotation.y = -1;
            bodyToeLeft.scale.setScalar(0.1) ;

            bodyToeRight.position.z = -1.5;
            bodyToeRight.rotation.y = -1;
            bodyToeRight.scale.setScalar(0.1) ;

            nailsToeLeft.rotation.y = -1;
            nailsToeRight.rotation.y = -1;
        }
        
        if (id === 'WR_G18_Sneakers_CnvrsAnkle') {
            // bodyToeLeft.rotation.y = -1;
            bodyToeLeft.scale.setScalar(0.1) ;

            // bodyToeRight.rotation.y = -1;
            bodyToeRight.scale.setScalar(0.1) ;

            bodyFootLeft.scale.setScalar(0.9) ;
            bodyFootRight.scale.setScalar(0.9) ;

            nailsToeLeft.rotation.y = -0.8;
            nailsToeRight.rotation.y = -0.8;
        }

        if (id === 'WR_G18_Sneakers_Classic') {
            // bodyToeLeft.rotation.y = -1;
            bodyToeLeft.scale.setScalar(0.1) ;

            // bodyToeRight.rotation.y = -1;
            bodyToeRight.scale.setScalar(0.1) ;

            // bodyFootLeft.scale.setScalar(0.1) ;
            // bodyFootRight.scale.setScalar(0.1) ;

            nailsToeLeft.rotation.y = -0.8;
            nailsToeRight.rotation.y = -0.8;
        }

        if (id === 'WR_G18_Shoes_Boots_MickeyMouse') {
            bodyToeLeft.rotation.y = -1;
            bodyToeLeft.scale.setScalar(0.5) ;

            bodyToeRight.rotation.y = -1;
            bodyToeRight.scale.setScalar(0.5) ;

            bodyFootLeft.scale.setScalar(0.5) ;
            bodyFootRight.scale.setScalar(0.5) ;

            nailsToeRight.rotation.y = -1;
            nailsToeLeft.rotation.y = -1;
        }

        if (id === 'WR_G18_Shoes_Boots_SciFiJumpsuit') {
            bodyToeLeft.scale.setScalar(0.5) ;
            bodyToeRight.scale.setScalar(0.5) ;

            bodyFootLeft.scale.setScalar(0.8) ;
            bodyFootRight.scale.setScalar(0.8) ;

            nailsToeRight.rotation.y = -0.5;
            nailsToeLeft.rotation.y = -0.5;
        }

        if (id === 'WR_G18_Shoes_HeelsClassic') {
            bodyToeLeft.rotation.y = -1;
            bodyToeLeft.scale.setScalar(0.5) ;

            bodyToeRight.rotation.y = -1;
            bodyToeRight.scale.setScalar(0.5) ;

            bodyFootRight.rotation.y = -0.2;
            bodyFootLeft.rotation.y = -0.2;
        }

        if (id === 'WR_G18_Shoes_Cossacs') {
            bodyToeLeft.rotation.y = -1;
            bodyToeLeft.scale.setScalar(0.5) ;

            bodyToeRight.rotation.y = -1;
            bodyToeRight.scale.setScalar(0.5) ;

            bodyFootLeft.scale.setScalar(0.9) ;
            bodyFootRight.scale.setScalar(0.9) ;
        }

        if (id === 'WR_G18_Shoes_Sandals_Egyptian') {
            // bodyToeLeft.position.z = 1.5;
            // bodyToeRight.position.z = -1.5;

            bodyFootLeft.rotation.y = -0.1;
            bodyFootRight.rotation.y = -0.1;

            nailsToeLeft.position.z = 1.5;
            nailsToeRight.position.z = -1.5;
        }

        if (id === 'WR_G18_HeelStrap_001') {
            // bodyToeLeft.position.z = 1.5;
            // bodyToeRight.position.z = -1.5;

            bodyFootLeft.rotation.y = -0.2;
            bodyFootRight.rotation.y = -0.2;

            nailsToeLeft.position.z = 1.5;
            nailsToeRight.position.z = -1.5;
        }
    }
}

function resetFootBoneParams() {

    const body = app.getAvatarBodyMesh();
    const nails = app.model.getObjectByName(app.isFemale() ? 'CHs_G18_Nails' : 'CHs_B18_Nails')

    if (!!body && !!nails) {
        const bodyFootLeft = body.skeleton.getBoneByName('Joint_Foot_L');
        const bodyFootRight = body.skeleton.getBoneByName('Joint_Foot_R');
    
        const bodyToeLeft = body.skeleton.getBoneByName('Joint_Toe_L');
        const bodyToeRight = body.skeleton.getBoneByName('Joint_Toe_R');
    
        const nailsToeLeft = nails.skeleton.getBoneByName('Joint_Toe_L');
        const nailsToeRight = nails.skeleton.getBoneByName('Joint_Toe_R');

        bodyToeLeft.rotation.y = 0;
        bodyToeLeft.position.z = 0;
        bodyToeLeft.scale.setScalar(1) ;

        bodyToeRight.rotation.y = 0;
        bodyToeRight.position.z = 0;
        bodyToeRight.scale.setScalar(1) ;

        bodyFootLeft.rotation.y = 0;
        bodyFootLeft.scale.setScalar(1) ;

        bodyFootRight.rotation.y = 0;
        bodyFootRight.scale.setScalar(1) ;

        nailsToeLeft.rotation.y = 0;
        nailsToeLeft.position.z = 0;

        nailsToeRight.rotation.y = 0;
        nailsToeRight.position.z = 0;
    }
}


export { setBonesParams, resetFootBoneParams }