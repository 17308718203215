import axios from 'axios';
import { cookies } from '../utils/cookies';

function renderUsermenu (name, lastname, email) {

    const logoutDropBtn = document.querySelector('.third-block');
    const logout = document.querySelector('.js-logout');
    const arrowDown = document.querySelector('.js-arrow-down');
    const arrowUp = document.querySelector('.js-arrow-up');

    const initialsBlock = document.querySelector('.first-block');
    const nameBlock = document.querySelector('.name-block');
    const emailBlock = document.querySelector('.email-block');

    initialsBlock.innerHTML = makeInitials(name, lastname);
    nameBlock.innerText = name;
    emailBlock.innerText = email;

    logoutDropBtn.addEventListener('click', () => {
        if (logout.classList.contains('active')) {
            logout.classList.remove('active');
            arrowDown.style.display = 'none';
            arrowUp.style.display = 'block';
        } else {
            logout.classList.add('active');
            arrowUp.style.display = 'none';
            arrowDown.style.display = 'block';
        }
    });
  
    logout.addEventListener('click', () => {
        cookies.remove('auth', { path: '/' });
        cookies.remove('user', { path: '/' });
        cookies.remove('avatar-info', { path: '/' });
        window.location.reload();
    });
    

}

function makeInitials (name, lastname) {
    if (name && lastname) {
      return name[0] + lastname[0] || '';
    } else {
      return name[0];
    }
};

function deleteUser(id) {
    const { auth } = cookies.get('auth');

    axios.delete(`https://pg.sysdyn.ru/api/v1/users/avatar/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth}`,
        },
    });

}


export { renderUsermenu }
