import * as THREE from 'three';

import { avatarApplication as app } from '../scene/application';
import * as SkeletonUtils from './SkeletonUtils.js';
import { isAffectedByMorphing } from '../store/store';
import { getBonesWithColors } from './bones.js'; 
import { getGarmentMesh } from '../clothes/common';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { getActiveBodyMorphs } from '../menu/data.js'

const fittingDebugMode = false;
const fittingDebugLogs = false;

function debugLog(log1, log2, log3) {
    if (fittingDebugLogs) {
        if (!!log3) {
            console.log(log1, log2, log3);
        } else if (!!log2) {
            console.log(log1, log2);
        } else {
            console.log(log1);
        }
    }
}

const raycaster = new THREE.Raycaster();

const enableBVH = true;

if (enableBVH) {
    // Add the BVH extension functions
    THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    THREE.Mesh.prototype.raycast = acceleratedRaycast;

    // Setting "firstHitOnly" to true means the Mesh.raycast function will use the
    // bvh "raycastFirst" function to return a result more quickly.
    raycaster.firstHitOnly = true;
}


let _vertex = new THREE.Vector3();
let _target = new THREE.Vector3();
let _line = new THREE.Line3();

let _tempA = new THREE.Vector3();
let _morphA = new THREE.Vector3();

let _triangle = new THREE.Triangle();
let _box = new THREE.Box3();
let _box2 = new THREE.Box3();


// Копирует экземляр меша модели в app.fittingScene

function cloneBodyMeshForFitting(model) {

    if (app.enableFitting) {
        const bodyMesh = getGarmentMesh(model, app.getAvatarBodyName());
        const clonedModel = SkeletonUtils.clone(model);
        const clonedBodyMesh = getGarmentMesh(clonedModel, app.getAvatarBodyName());
    
        if (fittingDebugMode) {
           bodyMesh.visible = false;
        }
    
        clonedBodyMesh.geometry = bodyMesh.geometry.clone();
        clonedBodyMesh.material = new THREE.MeshStandardMaterial({
            color: '#888888',
            wireframe: true,
            visible: fittingDebugMode
        });
    
        const modelSlot = new THREE.Group();
        modelSlot.name = 'model';
        modelSlot.add(clonedBodyMesh);
        app.fittingScene.add(modelSlot);
    
        createFittingRegistry(clonedBodyMesh);    
    }
}


// создание fittingRegistry - вершины модели, к которым будут привязываться вершины шмоток

function createFittingRegistry(mesh) {

    const vertices = mesh.geometry.getAttribute('position');

    const meshBones = mesh.skeleton.bones;
    const bonesColors = getBonesWithColors(meshBones);

    const excludedBones = ['Joint_Root', 'Joint_Head', 'Joint_Toe_L', 'Joint_Toe_R', 'Joint_Hand_L', 'Joint_Hand_R'];

    let vertexBones = [];
    let lastValuedIndex = 0;

    let progress = -1;
    const progressStep = 25;
    let progressChange = 0;

    debugLog('Initializing fitting registry...');
    
    for(let vertexIndex = 0; vertexIndex < vertices.count; vertexIndex ++) {

        progress = Math.trunc((vertexIndex + 1) / vertices.count * 100);
        
        if (progress % progressStep === 0 && progress === progressChange) {
            debugLog('Model vertices processed:', progress + '%');
            progressChange = progress + progressStep;
        } 

        vertexBones = getVertexBonesSortedByWeight(mesh, vertexIndex);

        if (bonesColors[vertexBones[0].boneIndex].color !== '#000000' && !excludedBones.includes(vertexBones[0].boneName)) {
            app.fittingRegistry.vertices.push({
                modelVertex: _vertex.fromBufferAttribute(vertices, vertexIndex).clone(),
                modelVertexBones: vertexBones
            }); 
            lastValuedIndex = vertexIndex;
        } else {
            app.fittingRegistry.vertices.push({});
        };
    }

    app.fittingRegistry.lastValuedIndex = lastValuedIndex;

    debugLog('Fitting registry created.', 'Last valued index: ' + lastValuedIndex + ' / ' + vertices.count);

}

// Возращает связанные с вершиной кости из скелета меша

function getVertexBonesSortedByWeight(mesh, vertexIndex) {

    const meshBones = mesh.skeleton.bones;
    
    const meshSkinIndex = mesh.geometry.getAttribute('skinIndex');
    const meshSkinWeights = mesh.geometry.getAttribute('skinWeight');

    let _boneIndex = new THREE.Vector4();
    let _boneWeight = new THREE.Vector4();
    
    const vertexBonesArray = [];

    const vertexBones = {
        boneIndex: _boneIndex.fromBufferAttribute(meshSkinIndex, vertexIndex).clone(),
        boneWeight: _boneWeight.fromBufferAttribute(meshSkinWeights, vertexIndex).clone()
    }

    Array.of('x', 'y', 'z', 'w').forEach(index => {
        vertexBonesArray.push({
            index: index,
            boneIndex: _boneIndex[index],
            boneName: meshBones[_boneIndex[index]].name,
            boneWeight: _boneWeight[index],
        });
    });

    vertexBonesArray.sort((a, b) => b.boneWeight - a.boneWeight);

    return vertexBonesArray;
}


// Возвращает склонированный меш
function cloneGarmentMesh(originalGarment, name) {

    const garmentMesh = getGarmentMesh(originalGarment, name);

    const clonedGarment = SkeletonUtils.clone(originalGarment);
    const clonedMesh = getGarmentMesh(clonedGarment);

    clonedMesh.geometry = garmentMesh.geometry.clone();
    clonedMesh.material = new THREE.MeshStandardMaterial({
        color: '#ffff00',
        wireframe: true,
        visible: fittingDebugMode
    });

    return clonedMesh;
}


// Обработка меша одежды - поиск ближайших вершин модели и сохранение в fittingRegistry

function addGarmentToFittingRegistry(clonedMesh) {
    
    const vertices = clonedMesh.geometry.getAttribute('position').clone();
    let closestModelVertexIndex, distance, minDistance;
    let registryItem;

    let meshVertex = new THREE.Vector3();
    let bodyVertex = new THREE.Vector3();

    const lineAlignment = [];
    const verticesWithLinks = [];

    let modelVertices = [];

    let progress = -1;
    const progressStep = 10;
    let progressChange = 0;

    debugLog('Adding mesh to fitting registry:', clonedMesh.name, ' (' + vertices.count + ' vertices)');

    // для всех вершин меша шмотки
    for(let vertexIndex = 0; vertexIndex < vertices.count; vertexIndex ++) {

        distance = 100000;
        minDistance = 100000;
        closestModelVertexIndex = -1;

        const vertexBones = getVertexBonesSortedByWeight(clonedMesh, vertexIndex);

        for (let boneIndex = 0; boneIndex < 2; boneIndex++) {
            if (vertexBones[boneIndex].boneWeight > 0.3) {
                modelVertices = app.fittingRegistry.bones[vertexBones[boneIndex].boneName].vertices;

                progress = Math.trunc((vertexIndex + 1) / vertices.count * 100);
        
                if (progress % progressStep === 0 && progress === progressChange) {
                    debugLog('Garment mesh vertices processed:', progress + '%');
                    progressChange = progress + progressStep;
                } 
                            
                meshVertex = _target.fromBufferAttribute(vertices, vertexIndex).clone();
        
                // ищем ближайшую вершину из меша тела
                modelVertices.forEach((registryItem, index) => {        
                    if (index < app.fittingRegistry.lastValuedIndex && !!registryItem.modelVertex) {
        
                        distance = meshVertex.distanceTo(registryItem.modelVertex);
                
                        if (distance < minDistance) {
                            closestModelVertexIndex = registryItem.index;
                            minDistance = distance;
                            bodyVertex = registryItem.modelVertex.clone();
                        }
                    }
                });    
            }
        }

        if (closestModelVertexIndex > -1) {
            verticesWithLinks.push(closestModelVertexIndex);

            lineAlignment.push({
                index: vertexIndex,
                start: meshVertex.clone(),
                end: bodyVertex.clone(),
                length: minDistance,
            });
    
            registryItem = app.fittingRegistry.vertices[closestModelVertexIndex];
            registryItem.garments = registryItem.garments || {};
            registryItem.garments[clonedMesh.name] = registryItem.garments[clonedMesh.name] || [];
            registryItem.garments[clonedMesh.name].push(vertexIndex);
        }

    }

    app.fittingRegistry.processedMeshes.push(clonedMesh.name);

    if (fittingDebugMode) {
        garmentBodyVerticesAlignHelper(lineAlignment, clonedMesh.name);
    }

}


function clearGarmentFromFittingScene (garmentId) {
    const groupsToClear = ['garments', 'helpers'];

    Object.keys(app.fittingSlots).forEach(meshId => {
        if (app.fittingSlots[meshId].garmentId === garmentId) {
            groupsToClear.forEach(groupName => {
                const fittingGarmentsGroup = app.fittingScene.getObjectByName(groupName);
                fittingGarmentsGroup.children.forEach(mesh => {
                    if (mesh.name === meshId) {
                        fittingGarmentsGroup.remove(mesh);
                    }
                });
            });
        }
    });
}


// Показывает привязку вершин шмоток к вершинам модели (по наименьшему расстоянию)

function garmentBodyVerticesAlignHelper(linksRegistry, name) {

    const helperGroup = new THREE.Group();
    helperGroup.name = name || 'garmentBodyAlignHelper';

    const pointsArray = [];

    linksRegistry.forEach(item => {
        pointsArray.push(item.start.x, item.start.y, item.start.z);
        pointsArray.push(item.end.x, item.end.y, item.end.z);
    });

    const vertices = new Float32Array(pointsArray);
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
    const material = new THREE.LineBasicMaterial({ color: 0x00ff00 });

    const lineSegments = new THREE.LineSegments(geometry, material);
    helperGroup.add( lineSegments );

    const fittingHelpers = app.fittingScene.getObjectByName('helpers');

    fittingHelpers.remove(app.fittingScene.getObjectByName(helperGroup.name));
    fittingHelpers.add(helperGroup);

    return helperGroup;
}


// Добавление меша шмотки в fittingSlots, fittingRegistry, fittingScene

function cloneGarmentForFitting(options) {
    const { garment, garmentType, id } = options;

    if (app.enableFitting && isAffectedByMorphing(garmentType)) {
        const clonedMesh = cloneGarmentMesh(garment);

        if (!app.fittingSlots[clonedMesh.name]) {
            app.fittingSlots[clonedMesh.name] = {
                garmentId: id,
                vertexPositions: clonedMesh.geometry.getAttribute('position').clone()
            };
        };
    
        if (!app.fittingRegistry.processedMeshes.includes(clonedMesh.name)) {
            addGarmentToFittingRegistry(clonedMesh);
        }
    
        const garmentsGroup = app.fittingScene.getObjectByName('garments');
        if (!garmentsGroup.getObjectByName(clonedMesh.name)) {
            garmentsGroup.add(clonedMesh);
        }
    }
    
}

// обновление позиций вершин в fittingScene (например, после приминания)
function updateGarmentForFitting(options) {
    const { garmentId, positions } = options;

    if (!!garmentId && !!positions) {
        Object.keys(app.fittingSlots).forEach(meshId => {
            if (app.fittingSlots[meshId].garmentId === garmentId) {
                app.fittingSlots[meshId].vertexPositions = positions.clone();
            }
        });
    }
}


// Основной скрипт, вызываемый при изменении морф-таргета и при добавлении одежды на модель
// Принимает:
//   - morphId (id morphTarget-а из меша тела модели)
//   - morphValue (величина изменения morphTarget-а)
//   - mesh (меш одежды для подгонки)
//   - если mesh не задан, то будут браться все активные меши (надетые шмотки)
function applyMorphTargetImpact(options) {
    options = options || {};
    
    let meshPositions;

    // массив отрезков (индекс вершины модели (index), start, end, длина отрезка (length))
    const morphDelta = options.updateMorphDelta !== false ? getBodyMorphDelta() : app.fittingRegistry.currentMorphDelta;
  
    // отрисовка отрезков на сцене
    if (fittingDebugMode) {
        morphDeltaHelper(morphDelta);
    }
    
    const activeGarments = [];
    const garmentsPositions = {};

    if (!!options.mesh) {
        garmentsPositions[options.mesh.name] = {
            positions: app.fittingSlots[options.mesh.name].vertexPositions.clone()
        };
        activeGarments.push(app.fittingSlots[options.mesh.name].garmentId);
    } else {
        const meshesToFit = app.fittingScene.getObjectByName('garments').children;
        meshesToFit.forEach(mesh => {
            if (mesh.type && mesh.type === 'SkinnedMesh' ) {    
                const garmentId = app.fittingSlots[mesh.name].garmentId;
                if (!!app.getGarmentMeshFromScene(garmentId)) {
                    activeGarments.push(garmentId);
                    garmentsPositions[mesh.name] = {
                        positions: app.fittingSlots[mesh.name].vertexPositions.clone()
                    };
                }
            }
        });
    
    }

    // для всех сдвинутых вершин тела - находим по fittingRegistry ассоциированные вершины одежды и сдвигаем в ту же сторону
    morphDelta.forEach((morphedVertex, index) => {

        const morphVector = new THREE.Vector3(0,0,0);

        if (JSON.stringify(morphedVertex) !== '{}') {
            morphVector.subVectors(morphedVertex.end, morphedVertex.start);
        }

        const fittingRegistryItem = app.fittingRegistry.vertices[index];

        if (!!fittingRegistryItem.garments) {

            Object.keys(fittingRegistryItem.garments).forEach((meshName,index) => {

                const garmentId = app.fittingSlots[meshName].garmentId;

                if (activeGarments.includes(garmentId)) {

                    meshPositions = garmentsPositions[meshName].positions;

                    fittingRegistryItem.garments[meshName].forEach(vertexIndex => {

                        _target = _vertex.fromBufferAttribute(meshPositions, vertexIndex).clone(),
                        _target.add(morphVector);

                        meshPositions.setXYZ(vertexIndex, _target.x, _target.y, _target.z)
                    });
                }
            });

        }

    });

    if (fittingDebugMode) {
        const garmentsGroup = app.fittingScene.getObjectByName('garments');
        garmentsGroup.children.forEach(mesh => {
            if (garmentsPositions[mesh.name]) {
                garmentsPositions[mesh.name].positions.needsUpdate = true;
                mesh.geometry.setAttribute('position', garmentsPositions[mesh.name].positions);
            }
        });
    }

    activeGarments.forEach(garmentId => {
        const mesh = options.mesh || app.getGarmentMeshFromScene(garmentId);
        if (garmentsPositions[mesh.name]) {
            garmentsPositions[mesh.name].positions.needsUpdate = true;
            mesh.geometry.setAttribute('position', garmentsPositions[mesh.name].positions);
        }
    });

}


// Вычисляет векторы изменения позиций вершин модели при применении активных морф-таргетов

function getBodyMorphDelta() {

    const bodyMesh = getGarmentMesh(app.model, app.getAvatarBodyName());
    const clonedBodyMesh = getGarmentMesh(app.fittingScene.getObjectByName('model'));

    const morphSliders = getActiveBodyMorphs().map(morph => morph.source);

    morphSliders.forEach(morphId => {
        clonedBodyMesh.morphTargetInfluences[morphId] = bodyMesh.morphTargetInfluences[morphId];
    });

    const modelMeshPositions = clonedBodyMesh.geometry.getAttribute('position').clone();
    const newPositionsArray = [];

    const verticesCount = app.fittingRegistry.lastValuedIndex;

    const morphDelta = [];
    let changedPositionsCounter = 0;
    let maxDelta = 0;

    for ( let vertexIndex = 0; vertexIndex < verticesCount; vertexIndex++ ) {
        _vertex.fromBufferAttribute( modelMeshPositions, vertexIndex );
        getVertexPosition(clonedBodyMesh, vertexIndex, _target);

        newPositionsArray.push(_target.x, _target.y, _target.z);

        const delta = _vertex.distanceTo(_target);

        morphDelta.push( delta > 0.0001 ? { start: _vertex.clone(), end: _target.clone(), length: delta } : {} );

        if (fittingDebugLogs) {
            maxDelta = Math.max(delta, maxDelta);

            if (delta > 0.0001) {
                changedPositionsCounter++;
            }
        }
    }

    debugLog('Influenced positions: ' + changedPositionsCounter + '/' + verticesCount);    
    debugLog('Max delta:', maxDelta);    

    // morphSliders.forEach(morphId => {
    //     clonedBodyMesh.morphTargetInfluences[morphId] = 0;
    // });

    app.fittingRegistry.currentMorphDelta = morphDelta;

    debugLog('Current morph delta updated.');

    return morphDelta;
}


// Отображает на сцене линии изменения позиций вершин модели

function morphDeltaHelper(morphDelta) {

    const helperGroup = new THREE.Group();
    helperGroup.name = 'morphDeltaHelper';

    const pointsArray = [];

    morphDelta.forEach(item => {
        if (JSON.stringify(item) !== '{}') {
            pointsArray.push(item.start.x, item.start.y, item.start.z);
            pointsArray.push(item.end.x, item.end.y, item.end.z);
        }
    });

    const vertices = new Float32Array(pointsArray);
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
    const material = new THREE.LineBasicMaterial({ color: 0xff0000 });

    const lineSegments = new THREE.LineSegments(geometry, material);
    helperGroup.add( lineSegments );

    app.fittingScene.remove(app.fittingScene.getObjectByName(helperGroup.name));
    app.fittingScene.add(helperGroup);

    return helperGroup;
}


// Скопировано из новой версии (r152) three.js
// Get the local-space position of the vertex at the given index, 
// taking into account the current animation state of both morph targets and skinning.
// https://threejs.org/docs/index.html#api/en/objects/Mesh.getVertexPosition

function getVertexPosition( mesh, vert, target ) {

    const geometry = mesh.geometry;
    const position = geometry.attributes.position;
    const morphPosition = geometry.morphAttributes.position;
    const morphTargetsRelative = geometry.morphTargetsRelative;

    target.fromBufferAttribute( position, vert );

    const morphInfluences = mesh.morphTargetInfluences;

    if ( morphPosition && morphInfluences ) {
        _morphA.set( 0, 0, 0 );

        for ( let i = 0, il = morphPosition.length; i < il; i ++ ) {
            const influence = morphInfluences[ i ];
            const morphAttribute = morphPosition[ i ];

            if ( influence === 0 ) continue;

            _tempA.fromBufferAttribute( morphAttribute, vert );

            if ( morphTargetsRelative ) {
                _morphA.addScaledVector( _tempA, influence );
            } else {
                _morphA.addScaledVector( _tempA.sub( target ), influence );
            }
        }

        target.add( _morphA );
    }

    if ( mesh.isSkinnedMesh ) {
        mesh.boneTransform( vert, target );
    }

    return target;
}


export { 
    cloneBodyMeshForFitting, 
    cloneGarmentForFitting, 
    clearGarmentFromFittingScene,
    getBodyMorphDelta,
    updateGarmentForFitting,
    applyMorphTargetImpact, 
 }