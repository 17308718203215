import * as THREE from 'three';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';

import { avatarApplication as app } from '../scene/application';

function loadBodyMaterial(meshName) {

    const mesh = !!meshName ? app.scene.getObjectByName(meshName) : app.getAvatarBodyMesh();

    const mapsPath = app.avatarConfig.common.materialMaps.pathBody;
    const mapsConfig = app.avatarConfig[app.avatarGender].materialMaps;

    const web = app.web;

    const textureLoader = new THREE.TextureLoader();
    const tgaLoader = new TGALoader();

    function getLoader(name) {
        return name.endsWith('.tga') ? tgaLoader : textureLoader;
    }

    if (!!mesh && !!mapsConfig) {

        mesh.material = new THREE.MeshStandardMaterial({
            metalness: 0,
            roughness: 0.8,
        });

        if (!!mapsConfig.bodyBaseMap) {
            const baseMapPath = mapsPath + mapsConfig.bodyBaseMap;
            const baseMap = getLoader(mapsConfig.bodyBaseMap).load(`${baseMapPath}`);
            mesh.material.map = baseMap;
        }

        if (!!mapsConfig.bodyAoMap) {
            const aoMapPath = mapsPath + mapsConfig.bodyAoMap;
            mesh.material.aoMap = getLoader(mapsConfig.bodyAoMap).load(`${aoMapPath}`);
            mesh.material.aoMapIntensity = 0.5;
        }

        if (!!mapsConfig.bodyNormalMap) {
            const normalMapPath = mapsPath + mapsConfig.bodyNormalMap;
            mesh.material.normalMap = getLoader(mapsConfig.bodyNormalMap).load(`${normalMapPath}`);
        }

        if (!!mapsConfig.bodyRoughnessMap) {
            const roughnessMapPath = mapsPath + mapsConfig.bodyRoughnessMap;
            mesh.material.roughnessMap = getLoader(mapsConfig.bodyRoughnessMap).load(`${roughnessMapPath}`);
        }

        if (!!mapsConfig.bodyMetalnessMap) {
            const metalnessMapPath = mapsPath + mapsConfig.bodyMetalnessMap;
            mesh.material.metalnessMap = getLoader(mapsConfig.bodyMetalnessMap).load(`${metalnessMapPath}`);
        }

        if (web) {
            !!mesh.material.map && (mesh.material.map.flipY = false);
            !!mesh.material.aoMap && (mesh.material.aoMap.flipY = false);
            !!mesh.material.normalMap && (mesh.material.normalMap.flipY = false);
        }

    }

}

function loadTeethMaterial() {
    const mesh = app.getModelMouthMesh();
    const mapsPath = app.avatarConfig.common.materialMaps.pathTeeth;
    const mapsConfig = app.avatarConfig[app.avatarGender].materialMaps;

    // mesh.geometry.attributes.uv2 = mesh.geometry.attributes.uv;

    if (!!mesh && !!mapsConfig) {

        mesh.material = new THREE.MeshStandardMaterial({
            metalness: 0,
            roughness: 0.8,
        });

        const textureLoader = new THREE.TextureLoader();
   
        if (!!mapsConfig.teethBaseMap) {
            const baseMapPath = mapsPath + mapsConfig.teethBaseMap;
            const baseMap = textureLoader.load(`${baseMapPath}`);
            mesh.material.map = baseMap;
        }

        if (!!mapsConfig.teethAoMap) {
            const aoMapPath = mapsPath + mapsConfig.teethAoMap;
            mesh.material.aoMap = textureLoader.load(`${aoMapPath}`);
            mesh.material.aoMapIntensity = 1;
        }

        if (!!mapsConfig.teethNormalMap) {
            const normalMapPath = mapsPath + mapsConfig.teethNormalMap;
            mesh.material.normalMap = textureLoader.load(`${normalMapPath}`);
        }
        
        if (!!mapsConfig.teethRoughnessMap) {
            const roughnessMapPath = mapsPath + mapsConfig.teethRoughnessMap;
            mesh.material.roughnessMap = textureLoader.load(`${roughnessMapPath}`);
        }
        
    }
}

function loadEyesMaterial() {

    const mesh = app.getModelEyesMesh();
    const mapsPath = app.avatarConfig.common.materialMaps.pathEyes;
    const mapsConfig = app.avatarConfig[app.avatarGender].materialMaps;

    if (!!mesh && !!mapsConfig) {

        mesh.material = new THREE.MeshStandardMaterial({
            metalness: 0,
            roughness: 1,
        });

        const textureLoader = new THREE.TextureLoader();

        if (!!mapsConfig.eyesBaseMap) {
            const baseMapPath = mapsPath + mapsConfig.eyesBaseMap;
            const baseMap = textureLoader.load(`${baseMapPath}`);
            mesh.material.map = baseMap;
        }

        if (!!mapsConfig.eyesAoMap) {
            const aoMapPath = mapsPath + mapsConfig.eyesAoMap;
            mesh.material.aoMap = textureLoader.load(`${aoMapPath}`);
            mesh.material.aoMapIntensity = 1;
        }

    }

    mesh.castShadow = true;
    
}
  

export { loadBodyMaterial, loadTeethMaterial, loadEyesMaterial }