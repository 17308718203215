import api from "../api";


export const authService = {
refreshToken:(refreshToken)=>
    api.post('auth/refresh-token',{
        refreshToken: refreshToken
    }),
    sighIn:(email,password)=>
api.post('auth/signin',{
    email,password
}),

}