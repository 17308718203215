import { cookies, getDateAfter30Days } from '../utils/cookies';

import { avatarService } from '../services/';
import { avatarApplication } from '../scene/application';
import { setAvatarProperty, resetStore, defaultAvatarProps, removeEmptyProps, saveAvatarProps } from '../avatar/avatarProps';
import { launchAvatarEditor } from '../menu/avatarEditor';

function renderAvatarsList(avatars) {

    const avatarsContainer = document.querySelector('.created-avatars-container');

    // const createdAvatarName = document.querySelector('.created-avatar-name');
    // const createdAvatar = document.querySelector('.created-avatar');

    avatars.forEach((avatar) => {

        const avatarData = removeEmptyProps(JSON.parse(avatar['itemIds']));

        const avatarContainer = document.createElement('div');
        const avatarImgContainer = document.createElement('div');
        const avatarImg = document.createElement('img');
        const avatarName = document.createElement('h2');
        const avatarSettings = document.createElement('div');
        const avatarSettingsDropDown = document.createElement('div');
        const avatarSettingsRenameBtn = document.createElement('div');
        const avatarSettingsDeleteBtn = document.createElement('div');

        avatarSettings.classList.add('avatar-settings');
        avatarContainer.classList.add('created-avatar');
        avatarImgContainer.classList.add('created-avatar-img-container');
        avatarSettingsDropDown.classList.add('js-avatar-menu', 'dropdown-menu');
        avatarSettingsRenameBtn.classList.add('dropdown-item');
        avatarSettingsDeleteBtn.classList.add('dropdown-item', 'dropdown-danger');
        avatarName.classList.add('created-avatar-name');

        avatarImg.src = !!avatarData && avatarData.gender === 'boy' 
            ? 'img/assets/male.png'
            : 'img/assets/avatar-plug.png';
        
        avatarName.innerHTML = avatarData === null
            ? 'Девочка ' + avatar.id
            : !!avatarData.name 
                ? avatarData.name 
                : 'Аватар ' + avatar.id;

        avatarSettingsRenameBtn.innerHTML = `<span class="icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0003 13.9996H8.66701M1.66699 14.3329L5.36651 12.91C5.60313 12.819 5.72145 12.7735 5.83214 12.7141C5.93046 12.6613 6.02419 12.6004 6.11235 12.532C6.21161 12.4549 6.30124 12.3653 6.48051 12.186L14.0003 4.66622C14.7367 3.92984 14.7367 2.73593 14.0003 1.99955C13.264 1.26317 12.0701 1.26317 11.3337 1.99955L3.81385 9.51936C3.63458 9.69864 3.54494 9.78827 3.46792 9.88752C3.39951 9.97569 3.33859 10.0694 3.28582 10.1677C3.2264 10.2784 3.18089 10.3967 3.08988 10.6334L1.66699 14.3329ZM1.66699 14.3329L3.03907 10.7655C3.13726 10.5102 3.18635 10.3826 3.27055 10.3241C3.34414 10.273 3.43519 10.2537 3.52319 10.2705C3.62388 10.2898 3.72058 10.3865 3.91398 10.5799L5.42004 12.0859C5.61344 12.2793 5.71014 12.376 5.72937 12.4767C5.74617 12.5647 5.72685 12.6558 5.67576 12.7293C5.61729 12.8135 5.48965 12.8626 5.23437 12.9608L1.66699 14.3329Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg></span><span class="text">Переименовать</span>`;

        avatarSettingsDeleteBtn.innerHTML = `<span class="icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"  stroke="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6667 3.99967V3.46634C10.6667 2.7196 10.6667 2.34624 10.5213 2.06102C10.3935 1.81014 10.1895 1.60616 9.93865 1.47833C9.65344 1.33301 9.28007 1.33301 8.53333 1.33301H7.46667C6.71993 1.33301 6.34656 1.33301 6.06135 1.47833C5.81046 1.60616 5.60649 1.81014 5.47866 2.06102C5.33333 2.34624 5.33333 2.7196 5.33333 3.46634V3.99967M6.66667 7.66634V10.9997M9.33333 7.66634V10.9997M2 3.99967H14M12.6667 3.99967V11.4663C12.6667 12.5864 12.6667 13.1465 12.4487 13.5743C12.2569 13.9506 11.951 14.2566 11.5746 14.4484C11.1468 14.6663 10.5868 14.6663 9.46667 14.6663H6.53333C5.41323 14.6663 4.85318 14.6663 4.42535 14.4484C4.04903 14.2566 3.74307 13.9506 3.55132 13.5743C3.33333 13.1465 3.33333 12.5864 3.33333 11.4663V3.99967" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg></span><span class="text">Удалить</span>`;

        avatarSettingsDropDown.appendChild(avatarSettingsRenameBtn);
        avatarSettingsDropDown.appendChild(avatarSettingsDeleteBtn);
        avatarImgContainer.appendChild(avatarImg);
        avatarImgContainer.appendChild(avatarSettings);
        avatarImgContainer.appendChild(avatarSettingsDropDown);
        avatarContainer.appendChild(avatarImgContainer);
        avatarContainer.appendChild(avatarName);
        avatarsContainer.appendChild(avatarContainer);

        avatarSettings.innerHTML = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99984 10.834C10.4601 10.834 10.8332 10.4609 10.8332 10.0007C10.8332 9.54041 10.4601 9.16732 9.99984 9.16732C9.5396 9.16732 9.1665 9.54041 9.1665 10.0007C9.1665 10.4609 9.5396 10.834 9.99984 10.834Z" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.99984 5.00065C10.4601 5.00065 10.8332 4.62755 10.8332 4.16732C10.8332 3.70708 10.4601 3.33398 9.99984 3.33398C9.5396 3.33398 9.1665 3.70708 9.1665 4.16732C9.1665 4.62755 9.5396 5.00065 9.99984 5.00065Z" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.99984 16.6673C10.4601 16.6673 10.8332 16.2942 10.8332 15.834C10.8332 15.3737 10.4601 15.0007 9.99984 15.0007C9.5396 15.0007 9.1665 15.3737 9.1665 15.834C9.1665 16.2942 9.5396 16.6673 9.99984 16.6673Z" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;

        avatarSettingsDeleteBtn.addEventListener('click', (e) => {
            e.stopPropagation();
            avatarService.deleteAvatar(avatar.id).then(() => {
                window.location.assign('/');
            });
        });

        avatarSettingsRenameBtn.addEventListener('click', (e) => {
            e.stopPropagation();
            avatarName.setAttribute('contentEditable', true);
            avatarName.focus();
        });

        avatarName.addEventListener('blur', (e) => {
            avatarName.setAttribute('contentEditable', false);
            avatarSettingsDropDown.classList.remove('active');
        });

        avatarName.addEventListener('blur', (e) => {
            resetStore(avatarData);
            avatarData.name = avatarName.innerText;
            setAvatarProperty('name', avatarName.innerText);
            saveAvatarProps({avatarId: avatar.id});
        });

        avatarName.addEventListener('keypress', (e) => {
            let maxChars = 24;
            let currentChars = avatarName.innerText.length;

            if (e.key === 'Enter') {
                e.preventDefault();
                avatarName.blur();
            }

            function checkLength(event) {
                if (currentChars >= maxChars) {
                    event.preventDefault();
                } else {
                    currentChars++;
                }
            }
            checkLength(e);
        });

        avatarSettings.addEventListener('click', (e) => {
            e.stopPropagation();
            if (avatarSettingsDropDown.classList.contains('active')) {
                avatarSettingsDropDown.classList.remove('active');
            } else {
                avatarSettingsDropDown.classList.add('active');
            }
        });

        avatarImgContainer.addEventListener('click', () => {
            if (avatarData === null) {
                resetStore();
                setAvatarProperty('name', `Девочка ${avatar.id}`);
            } else {
                resetStore(avatarData);
            }

            cookies.set(
                'avatar-info',
                { avatarId: avatar.id },
                {
                    path: '/',
                    secure: true,
                    sameSite: 'lax',
                    expires: getDateAfter30Days(),
                }
            );

            avatarApplication.applyGender(!avatarData ? defaultAvatarProps.gender : avatarData.gender);
            avatarApplication.avatarId = avatar.id;
    
            launchAvatarEditor(!avatarData ? defaultAvatarProps : avatarData);
        });
    });

    addNavigationButtons({
        avatarsCount: avatars.length,
        container: avatarsContainer
    });
}

function addNavigationButtons(options) {

    const { avatarsCount, container} = options;

    let position = 0;

    if (avatarsCount > 3){
        const navButtons = document.getElementById('navigationBlock');
        navButtons.classList.remove('hidden')
    }

    const buttonRight = document.getElementById('button-right');
    const buttonLeft = document.getElementById('button-left');

    buttonRight.addEventListener('click',() => {

        if (position > (avatarsCount - 3) * (-500)) {
            position = position - 510;
            container.style = `margin-left: ${position}px`;
            buttonLeft.classList.remove('hidden');
        }

        if (position < (avatarsCount - 3) * (-500)){
            buttonRight.classList.add('hidden');
        }
    });

    buttonLeft.addEventListener('click', () => {

        if (position < 0) {
            position = position + 510;
            container.style = `margin-left: ${position}px`;
            buttonRight.classList.remove('hidden');
        }

        if (position === 0) {
            buttonLeft.classList.add('hidden');
        }
    });

}

export { renderAvatarsList }