import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass";
import {SSAARenderPass} from "three/examples/jsm/postprocessing/SSAARenderPass";

import { avatarApplication as app } from './application';

import voiceAssistant from "../voiceAssistant";


function addScene() {
  const stats = app.stats;

  const scene = new THREE.Scene();

  const mainScene = new THREE.Group();
  mainScene.name = 'mainScene';
  scene.add(mainScene);

  const smoothingScene = new THREE.Group();
  smoothingScene.name = 'smoothingScene';
  scene.add(smoothingScene);

  const fittingScene = new THREE.Group();
  fittingScene.name = 'fittingScene';

  const garmentsGroup = new THREE.Group();
  garmentsGroup.name = 'garments';
  fittingScene.add(garmentsGroup);
  
  const helpersGroup = new THREE.Group();
  helpersGroup.name = 'helpers';
  fittingScene.add(helpersGroup);
  
  scene.add(fittingScene);
  
  app.globalScene = scene;
  app.scene = mainScene;
  app.smoothingScene = smoothingScene;
  app.fittingScene = fittingScene;
  
  const camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 1500);

  app.camera = camera;

  let antialias = true;
  let pixelRatio;
  let shadows = false;
  let alpha = false;
  let bloom = false;
  let  composer;
  const params = new URLSearchParams(window.location.search);
  if (params.toString() === 'bloom=') {
    bloom = true;
    antialias = false;

  }
  if (/Android|webOS|iPhone|iPad|iPod|diordnA 9|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    pixelRatio = window.devicePixelRatio / 2;
  } else {
    alpha = true;
    antialias = true;
    shadows = true;
    pixelRatio = window.devicePixelRatio;
  }

  const renderer = new THREE.WebGLRenderer({
    antialias: antialias,
    alpha: alpha,
    preserveDrawingBuffer: true,
    logarithmicDepthBuffer: true,
    powerPreference: 'high-performance',
  });
  renderer.setPixelRatio(pixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  renderer.shadowMap.enabled = shadows;
  renderer.shadowMap.type = THREE.PCFShadowMap;

  renderer.domElement.className = 'scene-3d';
  document.body.appendChild(renderer.domElement);
  app.canvas = renderer.domElement;
  window.addEventListener( 'resize', onWindowResize, false );

  camera.position.set(0, 100, 500);

  // Controls
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.target.set(0, 100, 0);
  controls.maxPolarAngle = Math.PI * 0.55;
  controls.minDistance = 200;
  controls.maxDistance = 450;

if(bloom){
  var ssaaRenderPass = new SSAARenderPass( scene, camera,0x000000, 0);
  ssaaRenderPass.sampleLevel = 1;

  const renderScene = new RenderPass(scene, camera);
   composer = new EffectComposer(renderer);


  const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.clientWidth, window.clientHeight),
      0.2,
      1,
      0,
  );
  composer.addPass(renderScene);
  composer.setPixelRatio(2)
 // composer.addPass(ssaaRenderPass);
  composer.addPass(bloomPass);
}


  function onWindowResize(){

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
    if(bloom){
      composer.setSize( window.innerWidth, window.innerHeight );
    }


  }
  const clock = new THREE.Clock();

 /* const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition;*/

  function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
/*  class Recognizer {
    constructor() {
      this.recognition = new SpeechRecognition();
      this.recognition.lang = "ru-RU";
      if (!isMobile()) {
        this.recognition.continuous = true;
        this.recognition.interimResults = true;
      }
      this.isRecognizing = false;
      this.transcript = "";
    }



    start(handler) {
      this.transcript = "";
      this.recognition.onresult = (event) => {
        this.onResult(event, handler);
      };
      this.recognition.start();
      this.isRecognizing = true;

    }

    stop() {
      this.recognition.abort();
      this.isRecognizing = false;

    }
    onResult(event, handler) {
      var interim_transcript = "";
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        var result = event.results[i];
        if (result.isFinal) {
          this.transcript += result[0].transcript;
        } else {
          interim_transcript += result[0].transcript;
        }
      }

      voiceAssistant({interim_transcript, recognizer , app})

    }

  }
  const recognizer = new Recognizer();*/





  function render() {
    if(bloom){
      composer.render();
    } else {
      renderer.render(scene, camera);
    }

  }

  function animate() {
/*    if(recognizer.isRecognizing === false){
     // recognizer.start();
    }*/
    requestAnimationFrame(animate);
    controls.update();
    if (app.mixer) app.mixer.update(clock.getDelta() / 1.4);
    render();

    stats.update();
  }

  animate();
}

function saveAsImage(elem, input) {
  elem.addEventListener('click', () => {
    var imgData;

    try {
      var strMime = 'image/jpeg';
      imgData = app.canvas.toDataURL(strMime);

      saveFile(imgData.replace(strMime, 'image/octet-stream'), `${input.value || 'untitled'}.jpg`);
    } catch (e) {
      console.log(e);
    }
  });
}

var saveFile = function (strData, filename) {
  var link = document.createElement('a');
  if (typeof link.download === 'string') {
    document.body.appendChild(link); //Firefox requires the link to be in the body
    link.download = filename;
    link.href = strData;
    link.click();
    document.body.removeChild(link); //remove the link when done
  } else {
    location.replace(uri);
  }
};

export { addScene, saveAsImage };
