import { avatarApplication as app } from "../scene/application";

import { currentAvatarProps, resetStore, saveAvatarProps } from '../avatar/avatarProps';
import { isGarment, isRemovable } from "../store/store";
import { dressGarment } from '../clothes/dressGarment';
import { undressGarment } from '../clothes/common';

import { setSkinColor } from '../avatar/skinColor';
import { loadAnimation } from '../avatar/animation';
import { applyFaceMorph, clearFaceMorph, applyBodyMorph } from '../avatar/morphs';
import { getBodyMorphDataById, getActiveBodyMorphs } from '../menu/data';
import { applyMorphTargetImpact } from '../processing/fitting'

const enableDebugLogs = false;

function debugLog(log1, log2, log3) {
    if (enableDebugLogs) {
        if (!!log3) {
            console.log(log1, log2, log3);
        } else if (!!log2) {
            console.log(log1, log2);
        } else {
            console.log(log1);
        }
    }
}


function addUndoRedoHandlers() {
    Array.of('undo', 'redo').forEach(action => {
        const actionButton = document.querySelector('.js-button-' + action);
        actionButton.addEventListener('click', () => {
            if (!actionButton.classList.contains('disabled')) {
                restoreStateFromHistory(action);
            }
        });    
    });
}

function initHistoryByState() {
    return {
        currentUndoIndex: 0,
        savedStates: []
    }
}

function saveStateToHistory() {

    const states = app.historyByState.savedStates;
    const newStateIndex = app.historyByState.currentUndoIndex + 1;

    if (states.length > 0 && states.length > newStateIndex) {
        app.historyByState.savedStates = states.slice(0, newStateIndex - 1);
    }

    app.historyByState.savedStates.push({ ...currentAvatarProps });
    app.historyByState.currentUndoIndex = newStateIndex;

    debugLog('Save state to history', app.historyByState);

    document.querySelector('.js-button-undo').classList.remove('disabled');
    document.querySelector('.js-button-redo').classList.add('disabled');

}

function restoreStateFromHistory(action) {

    debugLog('Restore state from history: action -', action);

    if (action !== 'redo' && app.historyByState.currentUndoIndex === app.historyByState.savedStates.length) {
        app.historyByState.savedStates.push({ ...currentAvatarProps });
    } 

    const direction = action === 'redo' ? 1 : -1;
    const newStateIndex = app.historyByState.currentUndoIndex + direction;

    if (newStateIndex > -1 && newStateIndex < app.historyByState.savedStates.length) {
        applyStateFromHistory(newStateIndex);
        app.historyByState.currentUndoIndex = newStateIndex;
    }

    if (action === 'undo') {
        document.querySelector('.js-button-redo').classList.remove('disabled');
        if  (newStateIndex === 0) {
            document.querySelector('.js-button-undo').classList.add('disabled');
        }
    } else {
        document.querySelector('.js-button-undo').classList.remove('disabled');
        if (newStateIndex === app.historyByState.savedStates.length - 1) {
            document.querySelector('.js-button-redo').classList.add('disabled');
        }
    }

    debugLog('Restore state from history:', app.historyByState);

}

function applyStateFromHistory(index) {

    const faceMorphs = [ 'face', 'eyes', 'nose', 'mouth' ];
    const bodyMorphs = getActiveBodyMorphs().map(morph => morph.id);

    const configProps = app.avatarConfig[app.avatarGender].startupProps;
    const savedState = app.historyByState.savedStates[index];
    const stateChanges = getDifferenceWithCurrent(savedState);

    stateChanges.forEach((item, index) => {

        debugLog('Apply state from history. Change ' + (index + 1) + ':', item.type, item);

        if (item.type === 'skin') {

            setSkinColor( !!item.missingProp ? '' : item.value);

        } else if (item.type === 'pose') {

            const poseUrl = !!item.missingProp ? configProps.pose : item.value;
            loadAnimation(poseUrl);

        } else if (faceMorphs.includes(item.type)) {

            if (!!item.missingProp) {
                clearFaceMorph(item.value.type);
            } else {
                applyFaceMorph({ 
                    morphType: item.value.type,
                    id: item.value.source, 
                });
            }

        } else if (bodyMorphs.includes(item.type)) {

            const morphData = getBodyMorphDataById(item.type);

            if (!!morphData && !!morphData.isActive) {
                const morphValue = !!item.missingProp ? morphData.defaultValue : item.value;

                document.getElementById(item.type + 'Slider').value = morphValue + '';

                applyBodyMorph({
                    morphId: morphData.source, 
                    morphValue: morphValue
                });
                applyMorphTargetImpact();
            }

        } else if (isGarment(item.type)) {

            if (!!item.missingProp) {
                if (isRemovable(item.type)) {
                    undressGarment(item.type);
                } else if (item.type === 'hair') {
                    const hairProps = {
                        garmentType: 'hair',
                        id: configProps.hair,
                    }
                    if (configProps.hairColor) {
                        hairProps.color = configProps.hairColor
                    }
                    dressGarment(hairProps);
                } else if (item.type === 'brows') {
                    dressGarment({
                        garmentType: 'brows',
                        id: configProps.brows
                    });
                }
            } else {
                dressGarment({
                    garmentType: item.type,
                    id: item.value.model,
                    color: item.value.color, 
                });
            }

        }

    });

    resetStore(savedState);
    saveAvatarProps({ avatarProps: savedState });
}


function getDifferenceWithCurrent(savedState) {
    const differences = [];
    const nonTrackable = ['gender', 'name'];

    const currentState = { ...currentAvatarProps };

    Object.keys(savedState).forEach(type => {

        if (typeof savedState[type] === 'string' ) {
            if (!nonTrackable.includes(type) && savedState[type] !== currentState[type]) {
                differences.push({
                    type: type,
                    value: savedState[type],
                });
            }
        } else {
            if (!currentState[type] 
                || savedState[type].model !== currentState[type].model
                || savedState[type].color !== currentState[type].color
                || savedState[type].source !== currentState[type].source) {

                differences.push({
                    type: type,
                    value: savedState[type]
                });
            }
        }

        if (!!currentState[type]) {
            delete currentState[type];
        }
        
    });

    Object.keys(currentState).forEach(type => {
        differences.unshift({
            type: type,
            value: currentState[type],
            missingProp: true
        });
    });

    return differences;
}

export {
    initHistoryByState,
    saveStateToHistory,
    addUndoRedoHandlers,
}