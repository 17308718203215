import * as THREE from 'three';
import { avatarApplication as app } from './application';

function addLights() {

    const lights = new THREE.Group();
    lights.name = 'lights';
    app.globalScene.add(lights);
  
    const scene = lights;

    const ambient = new THREE.AmbientLight(0x404040, 1);
   //scene.add(ambient);

    const spotLight = new THREE.SpotLight(0xFFF3E6,0.5);

    spotLight.position.set(-200, 500, 302);
    spotLight.rotation.set(55,25,0);
    spotLight.castShadow = true;
    spotLight.shadow.radius = 2


    spotLight.shadow.camera.near = 1;
    spotLight.shadow.camera.far = 1000;
    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.height = 1024;

    spotLight.angle = 12;
    spotLight.penumbra =0.5;

    scene.add(spotLight);
    const shadowLight = new THREE.DirectionalLight(0xFFE5C6, 0.5);

    shadowLight.position.set(262, 328, 150);
    shadowLight.rotation.set(2.5,-5.6,0);
//    scene.add(shadowLight);




    const leftLight = new THREE.DirectionalLight(0xFFE5C6, 1);
/*    leftLight.castShadow = true;
    leftLight.shadow.radius = 1
    leftLight.shadow.blurSamples = 25
    leftLight.shadow.camera.left = -400;
    leftLight.shadow.camera.right = 400;
    leftLight.shadow.camera.top = 400;
    leftLight.shadow.camera.bottom = -400;
    leftLight.shadow.camera.near = 1;
    leftLight.shadow.camera.far = 2000;
    leftLight.shadow.mapSize.width = 2048;
    leftLight.shadow.mapSize.height = 2048;*/
    leftLight.position.set(-191, 74, 60);
    leftLight.rotation.set(-6,98,0);

  //  scene.add(leftLight);

    const hemiLight = new THREE.HemisphereLight(0xFFE5C6,0x1f1f1f,1.5);

    scene.add(hemiLight)

}

export {addLights}