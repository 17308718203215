import * as THREE from 'three';
import * as SkeletonUtils from './SkeletonUtils.js';

import { getGarmentMesh } from '../clothes/common.js';
import { garmentLayers, getSlotsByType, getLayerInnerTypes } from '../store/store.js';
import { SkeletonBoneHelper } from './skeleton.js';
import { getBoneColor, getBonesWithColors, getColorByName } from './bones.js'; 
import { getBonesListToCheck, getCollisionMeshName, bonesAdjacent } from './relations.js';
import { pointsHelper } from './pointsHelper.js';
import { onlyUnique } from '../utils/helpers.js';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { avatarApplication as app } from '../scene/application';
import { updateGarmentForFitting } from './fitting.js'

// Установить smoothingDebugMode в true, чтобы увидеть на сцене процесс вычисления приминания на клонированных шмотках
const smoothingDebugMode = false;
const smoothingDebugLogs = false;

function debugLog(log1, log2, log3) {
    if (smoothingDebugLogs) {
        if (!!log3) {
            console.log(log1, log2, log3);
        } else if (!!log2) {
            console.log(log1, log2);
        } else {
            console.log(log1);
        }
    }
}

const raycaster = new THREE.Raycaster();
const defaultRayLength = 5;

const enableBVH = true;

if (enableBVH) {
    // Add the BVH extension functions
    THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    THREE.Mesh.prototype.raycast = acceleratedRaycast;

    // Setting "firstHitOnly" to true means the Mesh.raycast function will use the
    // bvh "raycastFirst" function to return a result more quickly.
    raycaster.firstHitOnly = false;
}

let _vertex = new THREE.Vector3();
let _target = new THREE.Vector3();
let _line = new THREE.Line3();

let _vertexSkinIndex = new THREE.Vector4();
let _vertexSkinWeight = new THREE.Vector4();


// Возвращает склонированный меш
function cloneGarmentMesh(originalGarment, name) {

    const garmentMesh = getGarmentMesh(originalGarment, name);

    const clonedGarment = SkeletonUtils.clone(originalGarment);
    const clonedMesh = getGarmentMesh(clonedGarment);

    clonedMesh.geometry = garmentMesh.geometry.clone();
    clonedMesh.material = new THREE.MeshBasicMaterial({
        color: '#888888',
        visible: smoothingDebugMode
    });

    // const clonedBones = [];

    // garmentMesh.skeleton.bones.forEach(bone => {
    //     clonedBones.push(bone.clone());
    // });

    // clonedMesh.skeleton.bones = clonedBones;

    return clonedMesh;
}

function disposeMesh(mesh) {
    mesh.geometry.dispose();
    mesh.material.dispose();
    mesh.removeFromParent();
}


function cloneBodyMeshForSmoothing(model) {

    const bodyMesh = getGarmentMesh(model, app.getAvatarBodyName());
    const clonedModel = SkeletonUtils.clone(model);
    const clonedBodyMesh = getGarmentMesh(clonedModel, app.getAvatarBodyName());

    clonedBodyMesh.geometry = bodyMesh.geometry.clone();
    clonedBodyMesh.material = new THREE.MeshStandardMaterial({
        color: '#888888',
        wireframe: true,
        visible: smoothingDebugMode
    });

    const modelSlot = new THREE.Group();
    modelSlot.name = 'model';
    modelSlot.add(clonedBodyMesh);
    app.smoothingScene.add(modelSlot);

    const faces = calculateFacesFromMesh(clonedBodyMesh);

    splitMeshToObjects(clonedBodyMesh, faces);

}


// Клонирование меша в слот для приминания
function cloneForSmoothing(options) {

    const { id, garment, garmentType } = options;

    app.smoothingSlots[garmentType] = {};
    const slot = app.smoothingSlots[garmentType];

    const garmentMesh = getGarmentMesh(garment);
    
    slot.originalGarment = garment;
    slot.originalMesh = garmentMesh;
    slot.garmentId = id;
    slot.originalMeshPos = garmentMesh.geometry.getAttribute('position').clone();

    slot.stage = new THREE.Group();

    const clonedMesh = cloneGarmentMesh(garment);

    slot.clonedMesh = clonedMesh;
    slot.faces = calculateFacesFromMesh(clonedMesh);

    slot.stage.name = garmentType;
    slot.stage.clear();

    delete slot.collisionMesh;

    const collisionMeshName = getCollisionMeshName(id);
    
    if (!!collisionMeshName) {
        const collisionMesh = cloneGarmentMesh(garment, collisionMeshName);
        if (!!collisionMesh) {

            collisionMesh.material = new THREE.MeshBasicMaterial({
                color: 0x000000,
                visible: smoothingDebugMode
            });

            slot.collisionMesh = collisionMesh;
            collisionMesh.name = 'collisionMesh';

            const originalCollisionMesh = getGarmentMesh(garment, collisionMeshName);

            disposeMesh(originalCollisionMesh);
        }
    }

    debugLog('Cloning into smoothing slots completed:', slot.garmentId, slot);
}


// Возвращает пары для приминания на основе надетых типов шмоток
// Пример garmentTypes = ['jacket', 'jumpsuit', 'belt', 'shoes', 'socks', 'bag']
function getSmoothingPairs(garmentTypes) {

    const typesCopy = [...garmentTypes];

    let pairs = [];
    let intersections = [];

    garmentTypes.forEach(type => {
        intersections = typesCopy.filter(value => garmentLayers[type].innerTypes.includes(value));

        if (intersections.length) {
            intersections.forEach(item => {
                pairs.push({
                    outerType: type,
                    innerType: item,
                    outerWeight: garmentLayers[type].weight,
                    innerWeight: garmentLayers[item].weight
                })
            })
        }
    });

    let outerTypes = [];
    let smoothedTypes = [];
    let smoothingPairs = [];
    let path = [];

    garmentTypes.forEach(type => {
        outerTypes = pairs.filter(pair => pair.innerType === type);

        if (outerTypes.length) {
            outerTypes.sort((a, b) => b.outerWeight - a.outerWeight);

            smoothedTypes.push({
                'name': type,
                'innerWeight': outerTypes[0].innerWeight,
                'outerTypes': outerTypes.map(outerType => outerType.outerType)
            });
        }

    });

    smoothedTypes.sort((a, b) => b.innerWeight - a.innerWeight);

    smoothedTypes.forEach(innerType => {
        path = [];
        innerType['outerTypes'].forEach(outerType => {
            smoothingPairs.push({
                'innerType': innerType.name,
                'outerType': outerType,
                'path': [...path]
            });
            path.push(outerType);
        })
    });

    return smoothingPairs;
}


// Инициализация приминания шмоток
// - определяет пары 'приминаемая шмотка / приминающая шмотка' и запускает для каждой процесс приминания 
function initGarmentsSmoothing(selectedSlots) {

    if (smoothingDebugMode) {
        const body = app.scene.getObjectByName(app.getAvatarBodyName());
        body.material.visible = false;
    } 

    debugLog('========== Init Garments Smoothing ===========');

    const activeGarmentTypes = !!selectedSlots ? selectedSlots : app.getActiveGarmentTypesForSmoothing();
    
    debugLog('activeGarmentTypes', activeGarmentTypes);

    const smoothingPairs = getSmoothingPairs(activeGarmentTypes);

    debugLog('smoothingPairs:', smoothingPairs);

    let display = '';
    smoothingPairs.forEach((pair,index) => {
        display = display + (index+1) + '.[' + pair.outerType + '/' + pair.innerType + '] '; 
    });
    debugLog('Пары для приминания:', !!display ? display : '-');

    app.smoothingQueue?.forEach(item => {
        clearInterval(item);
    })

    app.bonesLeft = [];
    app.smoothingProgress = Array(smoothingPairs.length).fill(false);
    app.smoothingQueue = Array(smoothingPairs.length).fill(0);

    if (smoothingPairs.length > 0) {
        smoothingPairs.forEach((pair,index) => {
            if (index === 0) {
                // восстанавливаем вершины клонированных шмоток в smoothingSlots для нового приминания
                // и запускаем приминание для первой пары
                let slot;
                activeGarmentTypes.forEach(type => {
                    slot = app.smoothingSlots[type];
                    if (!!slot && !!slot.clonedMesh) {
                        slot.clonedMesh.geometry.setAttribute('position', slot.originalMeshPos.clone());
                    }
                });
                debugLog('---------- Smoothing:', (index+1) + '/' + smoothingPairs.length + ' [' + pair.outerType + '-' + pair.innerType + '] ', '----------');
                startGarmentsSmoothing({ pair: pair, index: index });
            } else {
                // приминание для остальных пар ставим в очередь
                app.smoothingQueue[index] = setInterval(function() {
                    if (app.smoothingProgress[index - 1]) {
                        debugLog('---------- Smoothing:', (index+1) + '/' + smoothingPairs.length + ' [' + pair.outerType + '-' + pair.innerType + '] ', '----------');
                        startGarmentsSmoothing({ pair: pair, index: index });
                        clearInterval(app.smoothingQueue[index]);
                    }
                }, 200);
    
            }
        });
    } else {
        app.processUpdateQueue();
    }

}


// Рreprocessing
// - для приминаемой шмотки (layerIndex = 0): создание вспомогательного скелета и новых групп вершин 
// - для приминающей шмотки (layerIndex = 1): разделение меша на объекты по костям
function processMeshFaces(slot, layerIndex) {

    const faces = slot.faces;
    const mesh = slot.clonedMesh;
    
    slot.stage.clear();
    slot.stage.add(mesh);

    if (layerIndex === 0 && !mesh.processed) {
        createSkeletonFromBones(mesh, () => {
            // drawLinesToBones(mesh, faces)

            createNewColorGroups(mesh, faces);

            if (smoothingDebugMode) {
                repaintMeshByBones(mesh);
            }
        });
    }

    if (layerIndex > 0) {
        if (smoothingDebugMode) {
            createSkeletonFromBones(mesh);
        }
        splitMeshToObjects(mesh, faces);

        if (!!slot.collisionMesh) {
            slot.stage.add(slot.collisionMesh);
        }
    }
}


// Создание массива треугольников (faces)
// Треугольник содержит:
// - три вершины (vertices), 
// - индекс в массиве всех вершин меша
// - привязку к индексам и весам костей (bones, weights) 
// - индекс кости с максимальным весом (materialIndex)
function calculateFacesFromMesh(mesh) {

    const faces = [];
    let face = {};

    let vertices = mesh.geometry.getAttribute('position');
    let bonesIndex = mesh.geometry.getAttribute('skinIndex');
    let bonesWeights = mesh.geometry.getAttribute('skinWeight');

    let countVertices = vertices.count;
    let countFaces = countVertices / 3;

    let vertexIndex = 0;
    let maxWeightKey;

    for (let faceIndex = 0; faceIndex < countFaces; faceIndex++ ) {
        face = {vertices: []};

        _vertexSkinIndex.fromBufferAttribute(bonesIndex, vertexIndex);
        _vertexSkinWeight.fromBufferAttribute(bonesWeights, vertexIndex);

        for(vertexIndex = faceIndex * 3; vertexIndex < faceIndex * 3 + 3; vertexIndex++) {
            _vertex.fromBufferAttribute( vertices, vertexIndex );
            face.vertices.push(_vertex.clone());
        }

        face.index = faceIndex;
        face.bones = _vertexSkinIndex.clone();
        face.weights = _vertexSkinWeight.clone();

        maxWeightKey = Object.keys(_vertexSkinWeight).reduce((a, b) => _vertexSkinWeight[a] > _vertexSkinWeight[b] ? a : b);
        face.materialIndex = face.bones[maxWeightKey];

        faces.push(face);
    }

    return faces;
}


// Скрипт построения вспомогательного скелета
// У костей скелета посредством BoneHelper вычисляются координаты вершин кости в пространстве (start, end) в дефолтном состоянии
// При этом BoneHelper-ы добавляются в группу, родительскую к передаваемом мешу
function createSkeletonFromBones(mesh, callback) {
    debugLog('createSkeletonFromBones started for:', mesh.name);

    let meshBones = mesh.skeleton.bones;

    // const bonePositions = new THREE.Float32BufferAttribute( meshBones.length * 2 * 3, 3 );

    let boneLine = new THREE.BufferAttribute();
    let boneStart = new THREE.Vector3();
    let boneEnd = new THREE.Vector3();

    let color = '';

    const boneHelpers = [];

    meshBones.forEach((bone, index) => {
        color = getBoneColor(bone);

        boneHelpers.push(new SkeletonBoneHelper(mesh, bone, color, smoothingDebugMode));

        mesh.parent.add(boneHelpers[index]);

        setTimeout(function() {
            boneLine = boneHelpers[index].geometry.getAttribute('position');

            boneStart.fromBufferAttribute(boneLine, 0);
            boneEnd.fromBufferAttribute(boneLine, 1);

            if (boneStart.distanceTo(boneEnd) === 0) {
                debugLog('Bone has zero length:', bone.name, '(' + mesh.name + ')');
            }
  
            meshBones[index].start = boneStart.clone();
            meshBones[index].end = boneEnd.clone();

            // bonePositions.setXYZ(index * 2, boneStart.x, boneStart.y, boneStart.z);
            // bonePositions.setXYZ(index * 2 + 1, boneEnd.x, boneEnd.y, boneEnd.z);

            if (!smoothingDebugMode) {
                boneHelpers[index].removeFromParent();
            }

            if (index === meshBones.length - 1) {
                if (typeof callback === 'function') {
                    callback();
                }
            }
    
        }, 50);
    });

}


// Создание в геометрии шмотки новых групп треугольников, объединенных по признаку принадлежности к одной кости
function createNewColorGroups(mesh, faces) {

    debugLog('createNewColorGroups');

    let group = {
        start: 0,
        count: 0,
        materialIndex: -1
    };
    let counter = 0, 
        groups = [];

    faces.forEach((face, index) => {
        if (face.materialIndex === group.materialIndex) {
            counter++;
        } else {
            if (counter > 0) {
                group.count = counter * 3;
                groups.push({ ...group });
            }
            group.materialIndex = face.materialIndex;
            group.start = index * 3;
            counter = 1;
        }
    });

    group.count = counter * 3;
    groups.push({ ...group });

    mesh.geometry.deleteAttribute('color');
    mesh.geometry.clearGroups();

    groups.forEach((group, index) => {
        mesh.geometry.addGroup(group.start, group.count, group.materialIndex);
        if (index === groups.length -1) {
            mesh.processed = true;
        }
    });

}


// Для приминающей шмотки - создание вместо одной целой шмотки новых мешей-объектов
// Каждый объект содержит треугольники, объединенные по максимальному весу кости треугольника - materialIndex
function splitMeshToObjects(mesh, faces) {

    debugLog('splitMeshToObjects:', mesh.name);

    const newObjects = [];

    const meshBones = mesh.skeleton.bones;
    const bonesColors = getBonesWithColors(meshBones);

    meshBones.forEach((bone,index) => {
        newObjects[index] = {
            vertices: []
        };

        if (mesh.name === app.getAvatarBodyName()) {
            app.fittingRegistry.bones[bone.name] = {
                vertices: []
            }
        }
    });

    let boneName = '';

    faces.forEach((face, index) => {
        boneName = meshBones[face.materialIndex].name;
        for(let i = 0; i < 3; i++) {
            newObjects[face.materialIndex].vertices.push(
                    face.vertices[i].x,
                    face.vertices[i].y,
                    face.vertices[i].z,
                );
            
            if (mesh.name === app.getAvatarBodyName()) {
                app.fittingRegistry.bones[boneName].vertices.push({
                    index: index * 3 + i,
                    modelVertex: new THREE.Vector3(
                        face.vertices[i].x,
                        face.vertices[i].y,
                        face.vertices[i].z)
                });    
            }
        }
    });

    newObjects.forEach((object,index) => {

        if (object.vertices.length && bonesColors[index].color !== '#000000') {

            object.geometry = new THREE.BufferGeometry();
            object.geometry.setAttribute( 'position', 
                new THREE.BufferAttribute( new Float32Array(object.vertices), 3 ) );
    
            // Generate associated BVH
            if (enableBVH) {
                object.geometry.computeBoundsTree();
            }

            object.material = new THREE.MeshBasicMaterial({ 
                color: bonesColors[index].color,
                visible: smoothingDebugMode,
                // transparent: true,
                // wireframe: true,
            });
    
            object.mesh = new THREE.Mesh(object.geometry,object.material);
            object.mesh.name = meshBones[index].name;
    
            mesh.parent.add(newObjects[index].mesh);
        }

        if (index === newObjects.length - 1) {
            mesh.removeFromParent();
        }
    });

}


// Возвращает объекты по названию кости (boneName), созданные из меша приминающей шмотки
// - возвращается объект с названием кости и смежные с ним (используется массив связанных костей bonesAdjacent)
function getOuterObjects(outerGarment, boneName) {

    // debugLog('boneName', boneName);

    let object;
    const objects = [];
    const bonesToCheck = [];

    if (!!outerGarment) {
        bonesToCheck.push(boneName);

        const bonesAdjacentIndex = bonesAdjacent.findIndex(bone => bone.name === boneName);
    
        if (bonesAdjacentIndex > -1) {
            bonesAdjacent[bonesAdjacentIndex].adjacent.forEach(bone => bonesToCheck.push(bone));
        }
    
        bonesToCheck.forEach(bone => {
            object = outerGarment.isMesh 
                ? outerGarment
                : getGarmentMesh(outerGarment, bone);
    
            if (!!object) {
                objects.push(object);
            }
        });    
    }

    return objects;
}


// Вычисление направления от вершины к кости по ближайшему расстоянию
// (возвращается нормализованный вектор)
function getNormalToBone(vertex, bone) {

    const boneStart = bone.start;
    const boneEnd = bone.end;
    const vector = new THREE.Vector3();

    if (boneStart.distanceTo(boneEnd) === 0) {
        vector.subVectors(boneStart, vertex);            
    } else {
        _line = new THREE.Line3(boneStart, boneEnd);
        _line.closestPointToPoint(vertex, true, _target);
        vector.subVectors(_target, vertex);
    }
   
    vector.rayLength = vector.length();

    return vector.normalize();
}


// Скрипт расчёта приминания для группы вершин (по кости с именем boneName)
// - берутся вершины меша из групп со значением materialIndex, соответствующему индексу кости с именем boneName в скелете меша
// - от этих вершин строится raycaster по направлению к кости
// - если raycaster пересекает объекты из массива garmentObjects, то вершина сдвигается по направлению raycaster-а за точку пересечения
function meshGroupSmoothing(options) {

    const {mesh, garmentObjects, modelObjects, boneName, timestamp} = options;
    debugLog('Smoothing for ' + boneName + '. Objects to check: ' + garmentObjects.length);

    let _normal = new THREE.Vector3();
    let vertexPoint = new THREE.Vector3();
    let offsetPoint = new THREE.Vector3();

    const geometry = mesh.geometry;
    const skeleton = mesh.skeleton;
    const bones = skeleton.bones;
    let groups = geometry.groups;

    let vertices = geometry.getAttribute('position');
    let countVertices = 0;

    let arrowHelper = new THREE.ArrowHelper();

    let intersectsCount = 0;
    let intersectsArray = [];

    let intersects = [];
    let modelIntersects = []

    let intersectPointIndex = 0;
    let intersectionDistance = 0;

    const intersectionPointsArray = [];
    const offsetPointsArray = [];

    const boneSkeletonIndex = bones.map(bone => bone.name).indexOf(boneName);

    if (boneSkeletonIndex > -1) {    
        groups = groups.filter(group => group.materialIndex === boneSkeletonIndex);

        groups.forEach(group => {
            for (let index = group.start; index < group.start + group.count; index++) {
                vertexPoint.fromBufferAttribute( vertices, index );

                offsetPoint = app.getOffsetFromCache(vertexPoint);
                if (!!offsetPoint) {

                    // Если смежную точку из соседнего треугольника уже сдвигали, берём её новые координаты
                    intersectsCount++;
                    vertices.setXYZ(index, offsetPoint.x, offsetPoint.y, offsetPoint.z);

                } else {

                    _vertex = vertexPoint.clone();

                    // строим нормаль в сторону связанной с группой костью
                    _normal = getNormalToBone(_vertex, bones[boneSkeletonIndex]);

                    // отодвигаем точку немного от кости, чтобы найти больше вершин, которые следует примять
                    _vertex.addScaledVector( _normal, -0.25 );
        
                    // arrowHelper = new THREE.ArrowHelper(_normal, _vertex, 1, 0xff00ff);            
                    // app.smoothingScene.add( arrowHelper.clone() );
                    
                    raycaster.far = _normal.rayLength * 1.25 || defaultRayLength;
                    raycaster.set(_vertex,_normal);

                    // посылаем луч в сторону кости
                    intersects = raycaster.intersectObjects(garmentObjects, false);

                    // если есть пересечение с объектами приминающей шмотки
                    if (intersects.length) {

                        // берем самое ближнее к кости пересечение
                        intersectPointIndex = intersects.length - 1;

                        intersectionDistance = intersects[intersectPointIndex].distance;
                        intersectsArray.push(intersectionDistance);

                        offsetPoint = intersects[intersectPointIndex].point.clone();
                        offsetPoint.addScaledVector( _normal, 0.5 );

                        intersectionDistance = _vertex.distanceTo(offsetPoint);

                        if (modelObjects.length > 0) {
                            raycaster.far = intersectionDistance;
                            modelIntersects = raycaster.intersectObjects(modelObjects, false);

                            if (modelIntersects.length > 0) {
                                offsetPoint = modelIntersects[0].point.clone();
                                offsetPoint.addScaledVector( _normal, -0.05 );
                            }
                        }
                        // *** сдвигаем вершину за точку пересечения
                        vertices.setXYZ(index, offsetPoint.x, offsetPoint.y, offsetPoint.z);

                        // записываем новые координаты в кэш
                        app.saveOffsetToCache(vertexPoint, offsetPoint);

                        intersectsCount++;
        
                        intersectionPointsArray.push(
                                intersects[intersectPointIndex].point.x, 
                                intersects[intersectPointIndex].point.y, 
                                intersects[intersectPointIndex].point.z
                            );
                        offsetPointsArray.push(offsetPoint.x, offsetPoint.y, offsetPoint.z);
                    }  else {
                        app.saveOffsetToCache(vertexPoint);
                    }

                }
                countVertices++;
            }
        });
    
    }

    debugLog('- Intersections found (out of vertices): ' + intersectsCount + ' / ' + countVertices);

    if (intersectsArray.length > 0) {
        debugLog('- Intersection max distance:', Math.max(...intersectsArray));
    }

    debugLog('- Bone process finished at (sec):', (Date.now() - timestamp) / 1000);

    if (false && smoothingDebugMode) {
        setTimeout(function() {
            const intersectionPointsHelper = pointsHelper({
                pointsArray: intersectionPointsArray, 
                name: 'Intersection Points'
            });
            app.smoothingScene.add(intersectionPointsHelper);

            const offsetPointsHelper = pointsHelper({
                pointsArray: offsetPointsArray, 
                color: getColorByName(boneName), 
                name: 'Intersection Points'
            });
            app.smoothingScene.add(offsetPointsHelper);
        }, 2000);
    }

}


function onGarmentSmoothingFinish(options) {

    const {smoothingPairIndex, innerGarmentId, outerGarmentId, outerGarmentPath, smoothingResult} = options;

    app.smoothingProgress[smoothingPairIndex] = true;

    if (smoothingPairIndex === app.smoothingQueue.length - 1) {
        debugLog('========== Smoothing finished ===========');

        if (smoothingDebugMode) {
            app.smoothingScene.position.z = -100;
        }

        app.processUpdateQueue();
    }

    if (!!innerGarmentId && !!outerGarmentId && !!smoothingResult) {
        app.saveSmoothingSavedPositions({
            innerGarmentId,
            outerGarmentId,
            outerGarmentPath,
            smoothingResult
        });

        updateGarmentForFitting({ 
            garmentId: innerGarmentId,
            positions: smoothingResult
        });
    }

}


// Скрипт запуска обработки шмоток (все вычисления происходят на клонированных шмотках)
// - запускает препроцессинг для шмоток, если он не был сделан раньше (processMeshFaces)
// - определяет кости по которым нужно сделать приминание (bonesToCheck)
// - для каждой группы вершин, связанных с костями из списка выполняет приминание (meshGroupSmoothing)
// - копирует в шмотку на аватаре новые значения расположения вершин из клонированной шмотки (newPositions)
function startGarmentsSmoothing(options) {
    const smoothingPairIndex = options.index;

    const innerGarmentType = options.pair.innerType;
    const outerGarmentType = options.pair.outerType;

    const slotInnerGarment = app.smoothingSlots[innerGarmentType];
    const slotOuterGarment = app.smoothingSlots[outerGarmentType];

    const outerGarmentPath = options.pair.path;
    
    const modelSmoothingSlot = app.smoothingScene.getObjectByName('model');

    if (!app.enableSmoothing || !slotInnerGarment || !slotOuterGarment) {
        debugLog('Nothing to align...');
        onGarmentSmoothingFinish({
            smoothingPairIndex: smoothingPairIndex
        });
        
        return;
    }

    const innerGarmentId = slotInnerGarment.garmentId;
    const outerGarmentId = slotOuterGarment.garmentId;

    const originalInnerMesh = slotInnerGarment.originalMesh;

    const clonedInnerMesh = slotInnerGarment.clonedMesh;
    const clonedOuterMesh = slotOuterGarment.clonedMesh;

    const savedPositions = app.getSmoothingSavedPositions({innerGarmentId, outerGarmentId, outerGarmentPath});

    if (!!savedPositions) {

        debugLog('Applying saved calculations...');
        
        clonedInnerMesh.geometry.setAttribute('position', savedPositions.clone());

        app.updateRenderQueue({
            method: 'update',
            id: innerGarmentId,
            garment: originalInnerMesh,
            garmentType: innerGarmentType,
            newPositions: savedPositions
        });

        updateGarmentForFitting({ 
            garmentId: innerGarmentId,
            positions: savedPositions
        });

        onGarmentSmoothingFinish({
            smoothingPairIndex: smoothingPairIndex
        });

        return;
    }

    const originalVerticesCount = originalInnerMesh.geometry.getAttribute('position').count;

    let innerMeshProcessing = true;
    let outerMeshProcessing = true;

    const clonedInnerGarment = slotInnerGarment.stage;
    const clonedOuterGarment = slotOuterGarment.stage;

    app.smoothingScene.add(clonedInnerGarment);
    processMeshFaces(slotInnerGarment, 0);

    app.smoothingScene.add(clonedOuterGarment);
    processMeshFaces(slotOuterGarment, 1);

    const smoothingLayers = getSlotsByType(innerGarmentType);

    let bonesToCheck = [];

    debugLog('Smoothing slots', slotInnerGarment.garmentId, smoothingLayers);

    smoothingLayers.forEach(slotName => {
        bonesToCheck = bonesToCheck.concat(getBonesListToCheck(slotName, innerGarmentId, outerGarmentId));
    });

    bonesToCheck = onlyUnique(bonesToCheck);

    debugLog('bonesToCheck', bonesToCheck);

    let garmentObjects = [], modelObjects = [];

    if (!!slotOuterGarment.collisionMesh) {
        if (enableBVH) {
            slotOuterGarment.collisionMesh.geometry.computeBoundsTree();
        }
    
        garmentObjects.push(slotOuterGarment.collisionMesh);
    }

    app.bonesLeft[smoothingPairIndex] = bonesToCheck.length;

    let timestamp = Date.now();
    let countdownTimeout = 4000;

    const waitForOuterMeshProcessing = setInterval(function() {

        countdownTimeout--;
        if (countdownTimeout < 0) {
            innerMeshProcessing = false;
            outerMeshProcessing = false;
        } 

        if (!!clonedInnerMesh && !!clonedInnerMesh.processed) {
            innerMeshProcessing = false;
        } 

        if (!clonedOuterMesh || !clonedOuterMesh.parent) {
            outerMeshProcessing = false;
        } 

        if (!outerMeshProcessing && !innerMeshProcessing) {

            debugLog('PreProcess time (sec):', (Date.now() - timestamp) / 1000);

            timestamp = Date.now();

            app.resetVerticesCache();
    
            bonesToCheck.forEach((boneName, index) => {
        
                if (!slotOuterGarment.collisionMesh) {
                    garmentObjects = getOuterObjects(clonedOuterGarment, boneName);
                }

                modelObjects = getOuterObjects(modelSmoothingSlot, boneName);
            
                if (garmentObjects.length) {
                    meshGroupSmoothing({
                        mesh: clonedInnerMesh, 
                        garmentObjects, 
                        modelObjects, 
                        boneName, 
                        timestamp
                    });
                }
                
                app.bonesLeft[smoothingPairIndex]--;
            });

            clearInterval(waitForOuterMeshProcessing);
        }
    }, 10);

    const checkReadyness = setInterval(function() {

        if (app.bonesLeft[smoothingPairIndex] === 0) {
            // clearInterval(checkReadyness);

            const newPositions = clonedInnerMesh.geometry.getAttribute('position');
            newPositions.needsUpdate = true;
    
            debugLog('Checksum: ' + originalVerticesCount + ' / ' + newPositions.count);
                
            app.updateRenderQueue({
                method: 'update',
                id: innerGarmentId,
                garment: originalInnerMesh,
                garmentType: innerGarmentType,
                newPositions: newPositions
            });
                                
            debugLog('Process time (sec):', (Date.now() - timestamp) / 1000);
    
            if (!smoothingDebugMode) {
                app.smoothingScene.remove(clonedInnerGarment, clonedOuterGarment);
            }                

            clearInterval(checkReadyness);

            onGarmentSmoothingFinish({
                smoothingPairIndex: smoothingPairIndex,
                innerGarmentId: innerGarmentId, 
                outerGarmentId: outerGarmentId,
                outerGarmentPath: outerGarmentPath,
                smoothingResult: newPositions.clone()
            });

            // debugLog(app.globalScene);
            // debugLog(app.smoothingSlots);
        }

    }, 100);

    if (smoothingDebugMode) {
        addOuterObjectsHelper(clonedOuterGarment);

        // отрисовка лучей от вершин примятой шмотки к костям (для визуализации)
        // setTimeout(function(){
        //     const faces = calculateFacesFromMesh(clonedInnerMesh);
        //     drawLinesToBones(clonedInnerMesh, faces)

        // }, 2000);
    }

}


// Создание нового массива материалов для шмотки в соответствии с костями и их предустановленными цветами
// (для визуального контроля распределения вершин по костям, в вычислениях не используется)
function repaintMeshByBones(mesh) {

    const bonesColors = getBonesWithColors(mesh.skeleton.bones);
    const materialsArray = [];
    let material;

    for ( let i = 0; i < bonesColors.length; i++ ) {
        material = new THREE.MeshStandardMaterial({
            color: bonesColors[i].color,
            roughness: 0.9,
            metalness: 0.1,
            visible: smoothingDebugMode,
            transparent: true,
            wireframe: true
        });
        material.name = bonesColors[i].name;
        materialsArray.push(material);
    }

    mesh.material = materialsArray;
}

// Восстановление оригинальных позиций вершин примятой шмотки
function restoreInnerMeshes(options) {    
    const {garmentType, undressMode} = options;
    const innerTypes = getLayerInnerTypes(garmentType);
    
    debugLog('Restore inner meshes for', garmentType, options);

    let smoothingSlot = {};
    let innerSlots = [];

    innerTypes.forEach(type => {
        smoothingSlot = app.smoothingSlots[type];

        if (!!smoothingSlot) {
            innerSlots.push(type);            

            app.updateRenderQueue({
                method: 'update',
                id: smoothingSlot.garmentId,
                garment: smoothingSlot.originalMesh,
                garmentType: type,
                newPositions: smoothingSlot.originalMeshPos
            });
         
            updateGarmentForFitting({ 
                garmentId: smoothingSlot.garmentId,
                positions: smoothingSlot.originalMeshPos
            });
        }        
    });

    if (undressMode) {
        initGarmentsSmoothing();
    }

}

// Отрисовка лучей от вершин к костям по наименьшему расстоянию
// (только для визуализации, в вычислениях не используется)
function drawLinesToBones(mesh, faces) {
    let faceBone = new THREE.Bone();
    let linesGroup = app.smoothingScene.getObjectByName('LinesToBones');

    if (!linesGroup) {
        linesGroup = new THREE.Group({name: 'LinesToBones'});
        app.smoothingScene.add(linesGroup);
    } else {
        linesGroup.clear();
    }

    setTimeout(function() {
        faces.forEach((face) => {
            faceBone = mesh.skeleton.bones[face.materialIndex];
            for(let i = 0; i < 3; i++) {
                drawLineToBone(face.vertices[i], faceBone, linesGroup);
            }
        });
    }, 2000);

}

function drawLineToBone(vertex, bone, linesGroup) {
    const boneStart = bone.start;
    const boneEnd = bone.end;
    const lineColor = getBoneColor(bone);
    const lineSegment = new THREE.Line(
        new THREE.BufferGeometry(),
        new THREE.LineBasicMaterial({ color: lineColor })
    );
    let bonePoint = new THREE.Vector3(); 

    if (boneStart.distanceTo(boneEnd) === 0) {
        bonePoint = boneStart;
    } else {
        _line = new THREE.Line3(bone.start, bone.end);
        _line.closestPointToPoint(vertex, true, bonePoint);
    }

    if (!isNaN(bonePoint.x) && !isNaN(bonePoint.y) && !isNaN(bonePoint.z)) {
        lineSegment.geometry.setFromPoints([vertex, bonePoint]);
        linesGroup.add(lineSegment);
    } else {
        debugLog('The closest point to the bone could not be found. Bone:', bone.name);
    }
}


// Хелпер для вывода в консоль информации об объекте из массива garments при клике мышкой на него
const helperRaycaster = new THREE.Raycaster();

let checkIntersectHandler = {};

const checkObjectsIntersect = function(objects) {
    return checkIntersectHandler[objects] || (checkIntersectHandler[objects] = function(event) {
            
        let intersections = [];
    
        const mouse = {
            x: (event.clientX / app.canvas.clientWidth) * 2 - 1,
            y: -(event.clientY / app.canvas.clientHeight) * 2 + 1,
        }
        helperRaycaster.setFromCamera(mouse, app.camera);
    
        const intersects = helperRaycaster.intersectObjects(objects, true);
        if (intersects.length) {
    
            intersects.forEach(intersection => {
                if (!!intersection.object.parent && !!intersection.object.name) {
                    intersections.push(intersection.object.name);
                }
            });
    
            //убрать дубликаты
            intersections = onlyUnique(intersections);
    
            if (intersections.length) {
                console.log('Objects found:', intersections.length, intersections);
            }
        }

    });
}

function addOuterObjectsHelper(garment) {

    app.canvas.removeEventListener('pointerup', checkObjectsIntersect);

    let objects = [];

    if (garment.children.length > 0) {
        garment.children.forEach(object => {
            if (object.isMesh) {
                objects.push(object);
            }
        });
    }

    app.canvas.addEventListener('pointerup', checkObjectsIntersect(objects));
}


export { 
    smoothingDebugMode, 
    cloneForSmoothing, 
    getGarmentMesh,
    cloneGarmentMesh,
    restoreInnerMeshes, 
    initGarmentsSmoothing, 
    calculateFacesFromMesh, 
    cloneBodyMeshForSmoothing 
}