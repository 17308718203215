// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__back-btn/editor-selector__back-btn.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__features/editor-selector__features.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__features-item/editor-selector__features-item.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__features-item-content/editor-selector__features-item-content.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editor-selector {\n  color: var(--color-primary-white);\n  transition: all 0.3s ease;\n}\n", "",{"version":3,"sources":["webpack://./src/css/components/editor-selector/editor-selector.css"],"names":[],"mappings":"AAKA;EACE,iCAAiC;EACjC,yBAAyB;AAC3B","sourcesContent":["@import url(./__back-btn/editor-selector__back-btn.css);\n@import url(./__features/editor-selector__features.css);\n@import url(./__features-item/editor-selector__features-item.css);\n@import url(./__features-item-content/editor-selector__features-item-content.css);\n\n.editor-selector {\n  color: var(--color-primary-white);\n  transition: all 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
