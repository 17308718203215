import svgIconMorphBreast from './icons/icon-morph-girl-breast.svg';
import svgIconMorphButt from './icons/icon-morph-girl-butt.svg';
import svgIconMorphHeight from './icons/icon-morph-girl-height.svg';
import svgIconMorphHips from './icons/icon-morph-girl-hips.svg';
import svgIconMorphShoulders from './icons/icon-morph-girl-shoulders.svg';
import svgIconMorphWeight from './icons/icon-morph-girl-weight.svg';

import svgIconTabModel from './icons/icon-tab-model.svg';
import svgIconTabClothes from './icons/icon-tab-clothes.svg';
import svgIconTabTatoo from './icons/icon-tab-tatoo.svg';

import svgIconSectionSkin from './icons/icon-section-skin.svg';
import svgIconSectionBody from './icons/icon-section-body.svg';
import svgIconSectionEyesColor from './icons/icon-section-eyes-color.svg';
import svgIconSectionEyesShape from './icons/icon-section-eyes-shape.svg';
import svgIconSectionHead from './icons/icon-section-head.svg';
import svgIconSectionHair from './icons/icon-section-hair.svg';
import svgIconSectionNose from './icons/icon-section-nose.svg';
import svgIconSectionBrows from './icons/icon-section-brows.svg';
import svgIconSectionMouth from './icons/icon-section-mouth.svg';
import svgIconSectionPose from './icons/icon-section-pose.svg';

import svgIconSectionSets from './icons/icon-section-sets.svg';
import svgIconSectionTshirt from './icons/icon-section-tshirt.svg';
import svgIconSectionPants from './icons/icon-section-pants.svg';
import svgIconSectionSkirt from './icons/icon-section-skirt.svg';
import svgIconSectionDress from './icons/icon-section-dress.svg';
import svgIconSectionJacket from './icons/icon-section-jacket.svg';
import svgIconSectionJumpsuit from './icons/icon-section-jumpsuit.svg';
import svgIconSectionBodysuit from './icons/icon-section-bodysuit.svg';
import svgIconSectionSocks from './icons/icon-section-socks.svg';
import svgIconSectionBoots from './icons/icon-section-boots.svg';
import svgIconSectionHat from './icons/icon-section-hat.svg';
import svgIconSectionGlove from './icons/icon-section-glove.svg';
import svgIconSectionGlasses from './icons/icon-section-glasses.svg';
import svgIconSectionNecklace from './icons/icon-section-necklace.svg';
import svgIconSectionBackpack from './icons/icon-section-backpack.svg';

export const colorIconActive = '#F7D254';
export const colorIconInactive = '#6A6E75';

export const svgFor1stTabBtn = svgIconTabModel;
export const svgFor2ndTabBtn = svgIconTabClothes;

export const menuStructure = [
    {
        tabId: 'tab-button__category-1',
        submenuId: 'tab__category-1',
        icon: svgIconTabModel,
        title: 'Тело',
        subitems: [
            {
                id: 'skin-color',
                icon: svgIconSectionSkin,
            },
            {
                id: 'body',
                icon: svgIconSectionBody,
                hideForWeb: true,
            },
            {
                id: 'head',
                icon: svgIconSectionHead,
                hideForWeb: true,
            },
            {
                id: 'hair',
                icon: svgIconSectionHair,
            },
            {
                id: 'brow',
                icon: svgIconSectionBrows,
            },
            {
                id: 'eyes-shape',
                icon: svgIconSectionEyesShape,
                hideForWeb: true,
            },
            {
                id: 'nose',
                icon: svgIconSectionNose,
                hideForWeb: true,
            },
            {
                id: 'mouth',
                icon: svgIconSectionMouth,
                hideForWeb: true,
            },
            {
                id: 'pose',
                icon: svgIconSectionPose,
            },
        ],
    },
    {
        tabId: 'tab-button__category-2',
        submenuId: 'tab__category-2',
        icon: svgIconTabClothes,
        title: 'Одежда',
        subitems: [
            {
                id: 'sets',
                icon: svgIconSectionSets,
                activeFor: ['female'],
            },
            {
                id: 'top',
                icon: svgIconSectionTshirt,
            },
            {
                id: 'bottom',
                icon: svgIconSectionPants,
            },
            {
                id: 'skirts',
                icon: svgIconSectionSkirt,
                activeFor: ['female'],
            },
            {
                id: 'dress',
                icon: svgIconSectionDress,
                activeFor: ['female'],
            },
            {
                id: 'jackets',
                icon: svgIconSectionJacket,
            },
            {
                id: 'jumpsuits',
                icon: svgIconSectionJumpsuit,
                activeFor: ['female'],
            },
            {
                id: 'bodysuits',
                icon: svgIconSectionBodysuit,
                activeFor: ['female'],
            },
            {
                id: 'socks',
                icon: svgIconSectionSocks,
                activeFor: ['female'],
            },
            {
                id: 'shoes',
                icon: svgIconSectionBoots,
            },
            {
                id: 'hat',
                icon: svgIconSectionHat,
            },
            {
                id: 'gloves',
                icon: svgIconSectionGlove,
            },
            {
                id: 'glasses',
                icon: svgIconSectionGlasses,
            },
            {
                id: 'accessories',
                icon: svgIconSectionNecklace,
            },
            {
                id: 'bag',
                icon: svgIconSectionBackpack,
            },
        ],
    },
    {
      tabId: 'tab-button__category-3',
      submenuId: 'tab__category-3',
      icon: svgIconTabTatoo,
      title: 'Тату',
      subitems: [],
      isActive: false,
    }
];


export const poseData = [
  {
    source: '/animations/girl/ACp_G18_Idle_Main_00',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_idle_main.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/AC_G18_Dance_DoughMixer',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_Dance_DoughMixer.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/AC_G18_Dance_Jake',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_Dance_Jake.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/AC_G18_Dance_Snakes',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_Dance_Snakes.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/ACa_G18_Dance02_cycle',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_Dance2.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/ACa_G18_Dance_SexyTurnAround',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_Dance_SexyTurnAround.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/ACi_G18_Air_Kiss',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_Air_Kiss.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/ACp_G18_BeautyHalfTurn',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_BeautyHalfTurn.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/ACa_G18_Lotos_Meditation_Loop',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_Lotos.png',
    classes: 'pose js-apply-anim',
  },
  {
    source: '/animations/girl/ACp_G18_WhisperSign',
    imgAlt: 'poseImg',
    width: '130',
    src: '/img/animations/Ti_AC_G18_WhisperSign.png',
    classes: 'pose js-apply-anim',
  },
];

export const browsShapeData = [
  {
    type: 'brows',
    source: 'CH_G18_Brows_Downward_0',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Downward_0.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Downward_1',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Downward_1.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Downward_2',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Downward_2.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_HardAngle_0',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_HardAngle_0.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_HardAngle_1',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_HardAngle_1.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_HighArch_0',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_HighArch_0.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_HighArch_1',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_HighArch_1.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_HighArch_2',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_HighArch_2.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Round_0',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Round_0.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Round_1',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Round_1.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Round_2',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Round_2.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_SShape_0',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_SShape_0.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_SShape_1',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_SShape_1.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_SShape_2',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_SShape_2.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Straight_0',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Straight_0.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Straight_1',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Straight_1.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Straight_2',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Straight_2.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Upward_0',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Upward_0.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Upward_1',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Upward_1.png',
    classes: 'brows js-change-brows',
  },
  {
    type: 'brows',
    source: 'CH_G18_Brows_Upward_2',
    imgAlt: 'browsImg',
    width: '125',
    src: '/img/brows/girl/Ti_CH_G18_Brows_Upward_2.png',
    classes: 'brows js-change-brows',
  },
];

export const eyesShapeData = [
  {
    type: 'eyesShape',
    source: '9',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_01.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '10',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_02.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '11',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_03.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '52',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_04.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '53',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_05.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '54',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_06.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '55',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_07.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '56',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_08.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '57',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_09.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '58',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_10.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '59',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_11.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '60',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_12.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '61',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_13.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'eyesShape',
    source: '62',
    imgAlt: 'eyesImg',
    width: '125',
    src: '/img/eyes/Ti_CH_G18_Eyes_14.png',
    classes: 'morph js-change-morph-shape',
  },
];

export const noseShapeData = [
  {
    type: 'noseShape',
    source: '0',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_01.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '1',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_02.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '2',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_03.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '20',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_04.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '21',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_05.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '22',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_06.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '23',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_07.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '24',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_08.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '25',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_09.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '26',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_10.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '27',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_11.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '28',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_12.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '29',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_13.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'noseShape',
    source: '30',
    imgAlt: 'noseImg',
    width: '130',
    src: '/img/nose/Ti_CH_G18_Nose_14.png',
    classes: 'morph js-change-morph-shape',
  },
];

export const mouthShapeData = [
  {
    type: 'mouthShape',
    source: '3',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_01.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '4',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_02.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '5',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_03.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '31',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_04.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '32',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_05.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '33',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_06.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '34',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_07.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '35',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_08.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '36',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_09.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '37',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_10.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '38',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_11.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '39',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_12.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'mouthShape',
    source: '40',
    imgAlt: 'mouthImg',
    width: '130',
    src: '/img/mouth/Ti_CH_G18_Lips_13.png',
    classes: 'morph js-change-morph-shape',
  },
];

export const hairShapeData = [
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Bun_HighMessyClassic',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Bun_HighMessyClassic.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Lob_Mid_Straight',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Lob_Mid_Straight.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Pixie_FringeSide',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Pixie_FringeSide.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Garcon_Long_Curly',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Garcon_Long_Curly.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Pot_VeryShort',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Pot_VeryShort.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Undercut_Blowout',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Undercut_Blowout.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Pixie_Short_Straight',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Pixie_Short_Straight.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Bob_Short_FringeShort',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Bob_Short_FringeShort.png',
    classes: 'hair js-change-clothes',
  },
  {
    type: 'hair',
    color: 'Blonde',
    source: 'HC_G18_Wave_Bob',
    imgAlt: 'hairImg',
    width: '120',
    src: '/img/hair/Ti_HC_G18_Wave_Bob.png',
    classes: 'hair js-change-clothes',
  },
];

export const jawShapeData = [
  {
    type: 'jawShape',
    source: '6',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_01.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '7',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_02.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '8',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_03.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '41',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_04.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '42',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_05.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '43',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_06.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '44',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_07.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '45',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_08.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '46',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_09.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '47',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_10.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '48',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_11.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '49',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_12.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '50',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_13.png',
    classes: 'morph js-change-morph-shape',
  },
  {
    type: 'jawShape',
    source: '51',
    imgAlt: 'hairImg',
    width: '125',
    src: '/img/jaw/Ti_CH_G18_Jaw_14.png',
    classes: 'morph js-change-morph-shape',
  },
];

export const bodyMorphData = [
  {
    svg: svgIconMorphHeight,
    id: 'height',
    min: '18',
    max: '25',
    defaultValue: '20',
    label: 'Рост',
    isActive: false,
  },
  {
    svg: svgIconMorphShoulders,
    id: 'shoulders',
    min: '8',
    max: '15',
    defaultValue: '10',
    label: 'Плечи',
    isActive: false,
  },
  {
    svg: svgIconMorphBreast,
    id: 'breast',
    min: '0',
    max: '1',
    defaultValue: '0',
    source: '13',
    morphId: 'BS_G18_Body_Tits',
    label: 'Грудь',
    isActive: true,
  },
  {
    svg: svgIconMorphHips,
    id: 'hips',
    min: '0',
    max: '0.8',
    defaultValue: '0',
    source: '14',
    morphId: 'BS_G18_Body_Boots',
    label: 'Бедра',
    isActive: true,
  },
  {
    svg: svgIconMorphButt,
    id: 'butt',
    min: '0',
    max: '0.8',
    defaultValue: '0',
    source: '75',
    morphId: 'BS_G18_Body_Butt_Round_Max',
    label: 'Ягодицы',
    isActive: true,
  },
  {
    svg: svgIconMorphWeight,
    id: 'weight',
    min: '0',
    max: '0.8',
    defaultValue: '0',
    source: '12',
    morphId: 'BS_Girl18yo_Body_Paffy',
    label: 'Вес',
    isActive: true,
  }
];

export function getBodyMorphDataById(id) {
  const index = bodyMorphData.findIndex(item => { return item.id === id + ''});
  return index > -1 ? bodyMorphData[index] : undefined;
}

export function getActiveBodyMorphs() {
  return bodyMorphData.filter(item => item.isActive);
}

export const skinColorsData = [
  {
    color: '#ffccbc',
  },
  {
    color: '#feb79b',
  },
  {
    color: '#e69b7e',
  },
  {
    color: '#c9876d',
  },
  {
    color: '#b67861',
  },
  {
    color: '#9f6853',
  },
  {
    color: '#8e5d4c',
  },
  {
    color: '#7d5241',
  },
  {
    color: '#62443a',
  },
  {
    color: '#52372e',
  },
  {
    color: '#ffdbbb',
  },
  {
    color: '#ffcc9a',
  },
  {
    color: '#e6b37e',
  },
  {
    color: '#cb9b6d',
  },
  {
    color: '#db9e65',
  },
];

export const setsData = [
  {
    source: 'setOne',
    imgAlt: 'setImg',
    width: '120',
    src: '/img/sets/Set1.png',
    classes: 'top js-apply-set',
  },
  {
    source: 'setTwo',
    imgAlt: 'setImg',
    width: '120',
    src: '/img/sets/Set2.png',
    classes: 'top js-apply-set',
  },
  {
    source: 'setThree',
    imgAlt: 'setImg',
    width: '120',
    src: '/img/sets/Set3.png',
    classes: 'top js-apply-set',
  },
  {
    source: 'setFour',
    imgAlt: 'setImg',
    width: '120',
    src: '/img/sets/Set4.png',
    classes: 'top js-apply-set',
  },
  {
    source: 'setFive',
    imgAlt: 'setImg',
    width: '120',
    src: '/img/sets/Set5.png',
    classes: 'top js-apply-set',
  },
];

export const tshirtsData = [
  {
    type: 'tshirt',
    color: 'Standart',
    source: 'WR_G18_TShirt_Slim',
    imgAlt: 'tshirtImg',
    width: '120',
    src: '/img/clothes/tshirts/Ti_WR_G18_TShirt_Slim.png',
    classes: 'top t-shirt js-change-clothes',
  },
];

export const sweatersData = [
  {
    type: 'sweater',
    color: 'Yellow',
    source: 'WR_G18_Hoodie_001',
    imgAlt: 'sweaterImg',
    width: '120',
    src: '/img/clothes/sweater/Ti_WR_G18_Hoodie_001.png',
    classes: 'top sweater js-change-clothes',
  },
];

export const brasData = [
  {
    type: 'bra',
    color: 'Pink_Hot',
    source: 'WR_G18_Top_ShiningSet_MetalInserts',
    imgAlt: 'braImg',
    width: '120',
    src: '/img/clothes/tshirts/Ti_WR_G18_Top_ShiningSet_MetalInserts.png',
    classes: 'top bra js-change-clothes',
  },
  {
    type: 'bra',
    color: 'Standart',
    source: 'WR_G18_Singlet_003',
    imgAlt: 'braImg',
    width: '120',
    src: '/img/clothes/bra/Ti_WR_G18_Singlet_003.png',
    classes: 'top bra js-change-clothes',
  },
];

export const pantsData = [
  {
    type: 'pants',
    color: 'Green',
    source: 'WR_G18_Pants_Cargo',
    imgAlt: 'pantsImg',
    width: '120',
    src: '/img/clothes/pants/Ti_WR_G18_Pants_Cargo.png',
    classes: 'bottom js-change-clothes',
  },
  {
    type: 'pants',
    color: 'Yellow',
    source: 'WR_G18_Pants_NinjaCargo',
    imgAlt: 'pantsImg',
    width: '120',
    src: '/img/clothes/pants/Ti_WR_G18_Pants_NinjaCargo.png',
    classes: 'bottom js-change-clothes',
  },
  {
    type: 'pants',
    color: 'Black',
    source: 'WR_G18_Jeans_WideLeg',
    imgAlt: 'pantsImg',
    width: '120',
    src: '/img/clothes/pants/Ti_WR_G18_Jeans_WideLeg.png',
    classes: 'bottom js-change-clothes',
  },
  {
    type: 'pants',
    color: 'black',
    source: 'WR_G18_Pants_TrousersTapered',
    imgAlt: 'pantsImg',
    width: '120',
    src: '/img/clothes/pants/Ti_WR_G18_Pants_TrousersTapered.png',
    classes: 'bottom js-change-clothes',
  },
  {
    type: 'pants',
    color: 'Blue',
    source: 'WR_G18_Jeans_SkinnyFlare',
    imgAlt: 'pantsImg',
    width: '120',
    src: '/img/clothes/pants/Ti_WR_G18_Jeans_SkinnyFlare.png',
    classes: 'bottom js-change-clothes',
  },
];
