export const morphTarget = {
    CHs_G18_Body: {
        face: {
            jawShape: [
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 41
                },
                {
                    id: 42
                },
                {
                    id: 43
                },
                {
                    id: 44
                },
                {
                    id: 45
                },
                {
                    id: 46
                },
                {
                    id: 47
                },
                {
                    id: 48
                },
                {
                    id: 49
                },
                {
                    id: 50
                },
                {
                    id: 51
                }
            ],
            eyesShape: [
                {
                    id: 9
                },
                {
                    id: 10
                }
                ,
                {
                    id: 11
                }
                ,
                {
                    id: 52
                }
                ,
                {
                    id: 53
                }
                ,
                {
                    id: 54
                }
                ,
                {
                    id: 55
                }
                ,
                {
                    id: 56
                }
                ,
                {
                    id: 57
                }
                ,
                {
                    id: 58
                }
                ,
                {
                    id: 59
                }
                ,
                {
                    id: 60
                }
                ,
                {
                    id: 61
                }
                ,
                {
                    id: 62
                }
            ],
            noseShape:[
                {
                    id: 0
                },
                {
                    id: 1
                }
                ,
                {
                    id: 2
                }
                ,
                {
                    id: 20
                }
                ,
                {
                    id: 21
                }
                ,
                {
                    id: 22
                }
                ,
                {
                    id: 23
                }
                ,
                {
                    id: 24
                }
                ,
                {
                    id: 25
                }
                ,
                {
                    id: 26
                }
                ,
                {
                    id: 27
                }
                ,
                {
                    id: 28
                }
                ,
                {
                    id: 29
                }  ,
                {
                    id: 30
                }
            ],
            mouthShape:[
                {id:3},
                {id:4},
                {id:5},
                {id:31},
                {id:32},
                {id:33},
                {id:34},
                {id:35},
                {id:36},
                {id:37},
                {id:38},
                {id:39},
                {id:40},
            ]
        }
    },
    CH_B18_Body:{
        face:{
            jawShape: [
                {
                    id: 5
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },

            ],
            eyesShape: [
                {
                    id: 0
                },
                {
                    id: 1
                },
                {
                    id: 2
                },
                {
                    id: 3
                },
                {
                    id: 4
                },

            ],
            noseShape: [
                {
                    id: 15
                },
                {
                    id: 16
                },
                {
                    id: 17
                },
                {
                    id: 18
                },
                {
                    id: 19
                },
                {
                    id: 20
                },
                {
                    id: 21
                },

            ],
            mouthShape: [
                {
                    id: 10
                },
                {
                    id: 11
                },
                {
                    id: 12
                },
                {
                    id: 13
                },
                {
                    id: 14
                },

            ],
        }

    }

}