import * as THREE from 'three';

import { avatarApplication as app } from '../scene/application';
import { getGarmentMesh } from "./common";

async function setMaterial( options ) {

    const { garmentType, garment, color } = options;

    const garmentName = garment.name;

    const path = garmentType === 'hair' ? 'models' : 'models/clothes';

    if ( app.web ) {

        for (let i = 0; i < garment.children.length; i++) {

            if ( garment.children[i].type !== 'Bone' ) {

                // fetch(`${path}/${garmentType}/${garmentName}/${color}/${garment.children[i].material.name}.json`)
                //     .then((response) => response.json()).then((json) => {

                    const normalTexture = new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`);
                    normalTexture.flipY = false;

                    const mapTexture = new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__Main.png`);
                    mapTexture.flipY = false;

                    let garmentMaterial;

                    garmentMaterial = new THREE.MeshStandardMaterial({
                        normalMap: normalTexture,
                        map: mapTexture
                    });

                    // garmentMaterial.color.setRGB(json.r, json.g, json.b);
                    // garmentMaterial.name = garment.children[0].children[i].material.name

                    garment.children[i].material = garmentMaterial;

                // });
            }

        }

    } else {

        const mesh = getGarmentMesh(garment);

        //ЭТО ДЛЯ ВЕЩЕЙ, ГДЕ МАССИВ МАТЕРИАЛОВ

        if ( mesh.material.length ) {

            for (let i = 0; i < mesh.material.length; i++) {

                await fetch(`${path}/${garmentType}/${garmentName}/${color}/${mesh.material[i].name}.json`)
                    .then((response) => response.json()).then((json) => {

                    let garmentMaterial;

                    if ( garmentName === 'WR_G18_Singlet_003' 
                            || garmentName ==='WR_G18_Bag_SciFiJumpsuit' 
                            || garmentName === 'WR_G18_Shoes_Cossacs' 
                            || garmentName === 'WR_G18_Belt_SciFiJumpsuit') {

                        garmentMaterial = new THREE.MeshPhongMaterial({
                            normalMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`),
                        });
                    
                    } else if ( garmentName === 'WR_G18_Skirt_Native_American_Skirt_001' 
                                || garmentName === 'HC_G18_Pot_VeryShort' 
                                || garmentName === 'WR_G18_Bodysuit_LongSleeveHighNeck' 
                                || garmentType === 'glasses' 
                                || garmentType === 'necklace' ) {

                        garmentMaterial = new THREE.MeshPhongMaterial();

                    } else if ( mesh.material[i].name === 'W0_WR_G18_Jacket_SciFiJumpsuit' 
                                || garmentName === 'M0_WR_G18_Coat_Transparent_Raincoat' ) {

                        // const clearcoatNormal = new THREE.TextureLoader().load(
                        //     `/texture/cloth/${garmentType}/${garmentName}/Scratched_gold_01_1K_Normal.png`
                        // );

                        garmentMaterial = new THREE.MeshPhysicalMaterial({
                            normalMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`),
                            map: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__Main.png`),
                            roughness: 0.15,
                            clearcoat: 1,
                            clearcoatNormalMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`),
                            transmission: 1,
                            thickness: 4
                        });

                    } else {
                        
                        garmentMaterial = new THREE.MeshPhongMaterial({
                            normalMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`),
                            map: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__Main.png`)
                        });
                    }


                    if ( json.o ) {
                        garmentMaterial.transparent = true;
                        garmentMaterial.opacity = json.o;
                    }

                    if ( json.specular ) {
                        if(garmentName !== 'WR_G18_Jacket_SciFiJumpsuit'){
                            garmentMaterial.specular.setRGB(json.specular.r, json.specular.g, json.specular.b);
                        }

                    }

                    if ( json.emissive ) {
                        garmentMaterial.emissive.setRGB(json.emissive.r,json.emissive.g,json.emissive.b)
                    }

                    garmentMaterial.color.setRGB(json.r, json.g, json.b);

                    mesh.material[i] = garmentMaterial;

                });
            }

        } else {
            
            //ЭТО ДЛЯ ВЕЩЕЙ, ГДЕ НЕТ МАССИВА И ТОЛЬКО ОДИН МАТЕРИАЛ
            fetch(`${path}/${garmentType}/${garmentName}/${color}/${mesh.material.name}.json`)
                .then((response) => response.json()).then((json) => {

                let garmentMaterial;

                if ( garmentName === 'WR_G18_Singlet_003' 
                        || garmentName === 'WR_G18_Chains_Neck_01' ) {

                    garmentMaterial = new THREE.MeshPhongMaterial({
                        normalMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`),
                    });

                } else if ( garmentName === 'WR_G18_Skirt_Native_American_Skirt_001' 
                            || garmentName === 'HC_G18_Pixie_FringeSide' 
                            || garmentName === 'HC_G18_Garcon_Long_Curly' 
                            || garmentName === 'HC_G18_Pot_VeryShort' 
                            || garmentName === 'HC_G18_Undercut_Blowout' 
                            || garmentName === 'HC_G18_Pixie_Short_Straight' 
                            || garmentName === 'HC_G18_Bob_Short_FringeShort' 
                            || garmentName === 'HC_G18_Wave_Bob' 
                            || garmentName === 'HC_G18_Bun_HighMessyClassic' 
                            || garmentName === 'HC_G18_Lob_Mid_Straight' 
                            || garmentName === 'WR_G18_Choker_01' 
                            || garmentName ==='WR_G18_Jewelry_Earrings_ThinRings' 
                            || garmentName === 'WR_G18_Socks_Ankle' 
                            || garmentName === 'WR_G18_Sneakers_Classic' ) {

                    garmentMaterial = new THREE.MeshPhongMaterial({});

                } else if ( garmentName === 'WR_G18_Gloves_Boxing' ) {

                    garmentMaterial = new THREE.MeshPhongMaterial({
                        normalMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`),
                        map: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__Main.png`),
                        alphaMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__Mask.png`),
                    });

                } else {

                    garmentMaterial = new THREE.MeshPhongMaterial({
                        normalMap: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__N.png`),
                        map: new THREE.TextureLoader().load(`/texture/cloth/${garmentType}/${garmentName}/T_${garmentName}__Main.png`)
                    });

                }

                garmentMaterial.color.setRGB(json.r, json.g, json.b);

                mesh.material = garmentMaterial

            });
        }

    }
    return;

}

export { setMaterial }