import Mustache from 'mustache';
import menuHtml from '../../html/menu.html';
import maleMenuHtml from '../../html/male-menu.html';
import topmenuHtml from '../../html/topmenu.html';
import exportModelHtml from '../../html/export-model.html';
import colorPicker from '../../html/color-picker.html';
import loadingScreen from '../../html/loading-screen.html';

import { avatarApplication } from '../scene/application';

import { addStats } from '../scene/stats';
import { addScene } from '../scene/scene';
import { addLights } from '../scene/light';
import { addPlane } from '../scene/plane';

import { loadAvatar } from '../avatar/model';

import { addMenuHandlers } from './menu';
//import { addUploadHandlers } from './upload';
import { addColorPickerHandlers } from './color-picker';
import { addUndoRedoHandlers } from '../utils/historyByState';
import { exportModelHandler } from './export-model';

import { smoothingDebugMode } from './../processing/smoothing';
import { showBonesColorsHelper } from './../processing/bones';

import {
    jawShapeData,
    noseShapeData,
    mouthShapeData,
    poseData,
    eyesShapeData,
    browsShapeData,
    hairShapeData,
    bodyMorphData,
    skinColorsData,
    setsData,
    brasData,
    sweatersData,
    tshirtsData,
    pantsData,
} from './data';

function renderTemplate(data, target, templateName) {
    const template = document.getElementById(templateName).innerHTML;
    data.forEach((item) => {
        if (item.isActive !== false) {
            const rendered = Mustache.render(template, item);
            document.getElementById(target).innerHTML += rendered;
        }
    });
}

function launchAvatarEditor(avatarProps) {
    avatarProps = avatarProps || '';

    const bodyElement = document.querySelector('body');
    const pageLayout = document.createElement('div');
    pageLayout.classList.add('menu-layout');
    
    pageLayout.innerHTML = avatarApplication.isFemale() ? menuHtml : maleMenuHtml;

    bodyElement.replaceChildren(pageLayout);

    if (avatarApplication.isFemale()) {
        renderTemplate(jawShapeData, 'head-content', 'icon');
        renderTemplate(mouthShapeData, 'mouth-content', 'icon');
        renderTemplate(noseShapeData, 'nose-content', 'icon');
        renderTemplate(poseData, 'pose-content', 'icon');
        renderTemplate(eyesShapeData, 'eyes-content', 'icon');
        renderTemplate(browsShapeData, 'brows-content', 'icon');
        renderTemplate(hairShapeData, 'hair-content', 'iconWithColor');
        renderTemplate(bodyMorphData, 'sliders-content', 'slider');
        renderTemplate(skinColorsData, 'skin-colors-content', 'skinColor');
        renderTemplate(setsData, 'sets-content', 'icon');
        renderTemplate(brasData, 'bra-content', 'iconWithColor');
        renderTemplate(sweatersData, 'sweater-content', 'iconWithColor');
        renderTemplate(tshirtsData, 'tshirt-content', 'iconWithColor');
        renderTemplate(pantsData, 'pants-content', 'iconWithColor');
    }

    // bodyElement.insertAdjacentHTML('afterbegin', uploadHtml);
    bodyElement.insertAdjacentHTML('afterbegin', colorPicker);
    bodyElement.insertAdjacentHTML('afterbegin', topmenuHtml);
    bodyElement.insertAdjacentHTML('afterbegin', exportModelHtml);
    bodyElement.insertAdjacentHTML('afterbegin', loadingScreen);

    addStats();
    addScene();
    // saveAsImage(avatarApplication);
    addLights();
    addPlane();

    loadAvatar(avatarProps);

    addMenuHandlers();
    // addUploadHandlers();
    addColorPickerHandlers();
    addUndoRedoHandlers();
    exportModelHandler();

    if (smoothingDebugMode) {
        showBonesColorsHelper();
    } 
}

export { launchAvatarEditor };
