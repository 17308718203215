import { getSlotsByType, getGarmentSlots, getUnderwear } from '../store/store';
import { restoreInnerMeshes } from '../processing/smoothing';
import { clearGarmentFromFittingScene } from '../processing/fitting';
import { dressGarment } from './dressGarment';
import { resetFootBoneParams } from './setBoneParams';
import { onlyUnique } from '../utils/helpers';
import { avatarApplication as app } from '../scene/application';

function isUnderwear(id) {
    const avatarUnderwear = getUnderwear(app.avatarGender);
    
    const underwearIds = Object.keys(avatarUnderwear).map(slot => {
        return avatarUnderwear[slot].id;
    });

    return underwearIds.includes(id);
}

function isSmoothingSupported(garmentType) {
    return !['brows', 'glasses'].includes(garmentType)
}

function isFootGarment(garmentType) {
    return ['shoes', 'sandals'].includes(garmentType);
}

function addGarmentToBody(options) {
    let garmentBone = options.garmentBone;

    garmentBone.castShadow = true;
    garmentBone.updateMatrixWorld();
    garmentBone.position.set(0, 0, 0);
    garmentBone.rotation.set(0, 0, 0);

    options.bodyBone.add(garmentBone);
}

function restoreUnderwear(slot) {
    if (!!slot) {
        const defaultUnderwearId = app.underwearGarments[slot];

        if (!app.garmentSlots[slot].activeType) {
            dressGarment({ 
                id: defaultUnderwearId.id, 
                garmentType: defaultUnderwearId.type 
            });
        }
    } else {
        console.log('restoreUnderwear: slot is not defined');
    }
}

function clearGarments(options) {

    const {garmentType, undressMode} = options;

    if (isFootGarment(garmentType)) {
        resetFootBoneParams();    
    }

    const garmentTypeSlots = garmentType === 'dressSet' ? getGarmentSlots() : getSlotsByType(garmentType);

    let slotsToClear = garmentTypeSlots;

    let garmentSlotsToClear = [];
    let smoothingSlotsToClear = [];

    const currentHairId = app.garmentSlots['Hair'].activeGarment;

    if (undressMode) {
        smoothingSlotsToClear.push(garmentType);
    }

    let garmentSlotActiveType = '';
    let garmentsToClearFromScene = [];

    garmentTypeSlots.forEach(slotName => {
        garmentSlotActiveType = app.garmentSlots[slotName].activeType;
        
        if (!!garmentSlotActiveType) {
            garmentSlotsToClear = getSlotsByType(garmentSlotActiveType);

            slotsToClear = slotsToClear.concat(garmentSlotsToClear);

            if (garmentType !== 'dressSet') {
                garmentSlotsToClear.forEach(slot => {
                    garmentSlotActiveType = app.garmentSlots[slot].activeType;
            
                    if (!undressMode && garmentSlotActiveType !== garmentType) {
                        smoothingSlotsToClear.push(garmentSlotActiveType);
                    }
                   
                });
            } else {
                smoothingSlotsToClear.push(garmentSlotActiveType);
            }
        }
    });

    slotsToClear = onlyUnique(slotsToClear);
    smoothingSlotsToClear = onlyUnique(smoothingSlotsToClear);

    slotsToClear.forEach(slotName => {

        if (!!app.garmentSlots[slotName].activeGarment) {
            garmentsToClearFromScene.push(app.garmentSlots[slotName].activeGarment);
        }

        app.garmentSlots[slotName].activeType = '';
        app.garmentSlots[slotName].activeGarment = '';
        
        if (!!app.garmentSlots[slotName].color) {
            delete app.garmentSlots[slotName].color;
        }
    });

    garmentsToClearFromScene = onlyUnique(garmentsToClearFromScene);

    garmentsToClearFromScene.forEach(garmentName => {
        app.updateRenderQueue({
            method: 'remove', 
            id: garmentName,
            garmentType
        });
        clearGarmentFromFittingScene(garmentName);
    });

    if (garmentType === 'dressSet' && !!currentHairId) {
        const currentHairMesh = getGarmentMesh(app.scene.getObjectByName(currentHairId));
        currentHairMesh.geometry.setAttribute('position', app.smoothingSlots['hair'].originalMeshPos)
    }

    if (garmentType === 'dressSet') {
        for (const slot in app.smoothingSlots) {
            if (`${slot}` !== 'hair') {
                delete app.smoothingSlots[`${slot}`];
            }
        }
        app.smoothingScene.clear();
    } else {
        smoothingSlotsToClear.forEach(slotName => {
            if (!!app.smoothingSlots[slotName]) {
                delete app.smoothingSlots[slotName];
                app.smoothingScene.remove(app.smoothingScene.getObjectByName(slotName));
            }
        });
    }

    for (const underwearType in app.underwearGarments) {
        if ( garmentType === 'dressSet'
                || !!undressMode && garmentTypeSlots.includes(`${underwearType}`)
                || !garmentTypeSlots.includes(`${underwearType}`) && slotsToClear.includes(`${underwearType}`)) {
            restoreUnderwear(`${underwearType}`);
        }
    }

    if (undressMode) {
        restoreInnerMeshes({garmentType, undressMode});
    }
}


function undressGarment(garmentType) {
    clearGarments({
        garmentType: garmentType,
        undressMode: true
    });
}


function updateSlots(options) {

    const {garment, garmentType, garmentId, color, trigger} = options;

    clearGarments({garmentType});

    const slotsToFill = getSlotsByType(garmentType);

    slotsToFill.forEach(slotName => {
        app.garmentSlots[slotName].activeType = garmentType;
        app.garmentSlots[slotName].activeGarment = garmentId;
        
        if (!!color) {
            app.garmentSlots[slotName].color = color;
        }
    });

    if (trigger === 'menu') {
        restoreInnerMeshes({garmentType, undressMode: false})
    }

}

// Возвращает дочерний меш шмотки (с указанным именем или первый найденный, если имя не указано)
function getGarmentMesh(garment, name) {

    if (!!garment) {

        if (garment.type === 'SkinnedMesh') {
            if (!app.web) {
                console.log('* getGarmentMesh - garment is mesh!');
            }
            return app.web ? garment : undefined;
        }

        const map = garment.children.map(garmentItem => {
            return {
                'isMesh': !!garmentItem.isMesh || garmentItem.type === 'SkinnedMesh',
                'name': garmentItem.name
            };
        });

        const filter = !name
            ? (mesh) => mesh.isMesh
            : (mesh) => {
                return mesh.isMesh && mesh.name === name;
            }

        const index = map.findIndex(filter);

        return index > -1 ? garment.children[index] : undefined;
        
    } else 
        return undefined;

}


export { addGarmentToBody, clearGarments, updateSlots, undressGarment, isUnderwear, isSmoothingSupported, isFootGarment, getGarmentMesh }