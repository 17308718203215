import { isGarmentOn, isRemovable } from '../store/store';
import { undressGarment } from '../clothes/common';
import { setSkinColor } from '../avatar/skinColor';
import { loadAnimation } from '../avatar/animation';
import { dressSet } from '../clothes/dressSets';
import { dressGarment } from '../clothes/dressGarment';
import { debounce } from '../utils/helpers';
import { applyFaceMorph } from '../avatar/morphs';
import { currentAvatarProps, removeAvatarProperty, setAvatarProperty, saveAvatarProps } from '../avatar/avatarProps';
import { avatarApplication as app } from '../scene/application';
import { saveStateToHistory } from '../utils/historyByState';
import { menuStructure } from './data';

function addMenuHandlers() {
  let tabs = document.querySelectorAll('.wardrobe-panel-tab');
  let tabContent = document.querySelectorAll('.wardrobe-panel-tab__content');

  menuStructure.forEach(tabItem => {
    if (tabItem.isActive !== false) {
      const tabIcon = document.createElement('div');
      tabIcon.classList.add('icon');
      tabIcon.innerHTML = tabItem.icon;
  
      const tabTitle = document.createElement('span');
      tabTitle.innerHTML = tabItem.title;
  
      const tabDecorLeft = document.createElement('div');
      tabDecorLeft.classList.add('tab-decor-left');
  
      const tabDecorRight = document.createElement('div');
      tabDecorRight.classList.add('tab-decor-right');
  
      document.getElementById(tabItem.tabId).append(tabIcon, tabTitle, tabDecorLeft, tabDecorRight);

      const submenuContainer = document.createElement('div');
      submenuContainer.className = 'wardrobe-panel-tab__category-items-container';

      tabItem.subitems.forEach((subitem, index) => {
        const menuSection = document.querySelector('#' + subitem.id + '-id');

        const sectionIsActive = (!subitem.activeFor || subitem.activeFor?.includes(app.avatarGender)) 
          && (!app.web || subitem.hideForWeb !== true);

        if (sectionIsActive) {
          const sectionIcon = document.createElement('div');
          sectionIcon.innerHTML = subitem.icon;
          sectionIcon.id = subitem.id + '-button',
          sectionIcon.classList.add('wardrobe-panel-tab__category-item')
          if (index === 0) {
            sectionIcon.classList.add('wardrobe-panel-tab__category-item_active')
          }
          submenuContainer.append(sectionIcon);          
          addListenerToScrollIntoViewOnClick(sectionIcon, menuSection);
        } else {
          if (!!menuSection) {
            menuSection.style.display = 'none';
          }
        }
      });

      document.getElementById(tabItem.submenuId).append(submenuContainer);
    } else if (!!document.getElementById(tabItem.tabId)) {
      document.getElementById(tabItem.tabId).style.display = 'none';
    }
  });

  //Не удалять, эксперименты Малахова
  /*  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition;

      function isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      }

      class Recognizer {
        constructor() {
          this.recognition = new SpeechRecognition();
          this.recognition.lang = "ru-RU";
          if (!isMobile()) {
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
          }
          this.isRecognizing = false;
          this.transcript = "";
        }



        start(handler) {
          this.transcript = "";
          this.recognition.onresult = (event) => {
            this.onResult(event, handler);
          };
          this.recognition.start();
          this.isRecognizing = true;
          console.log("Started recognition");
        }

        stop() {
          this.recognition.abort();
          this.isRecognizing = false;
          console.log("Stopped recognition");
        }
        onResult(event, handler) {
          var interim_transcript = "";
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            var result = event.results[i];
            if (result.isFinal) {
              this.transcript += result[0].transcript;
            } else {
              interim_transcript += result[0].transcript;
            }
          }
          console.log(interim_transcript);

        }

      }
      const recognizer = new Recognizer();
      recognizer.start();*/

  tabs.forEach((tab) => {
    tab.addEventListener('click', function () {
      tabs.forEach((item) => {
        item.classList.remove('wardrobe-panel-tab_active');
      });
      this.classList.add('wardrobe-panel-tab_active');
      let tabName = this.getAttribute('data-tab');
      selectTabContent(tabName);
    });

    function selectTabContent(tabName) {
      tabContent.forEach((item) => {
        item.classList.contains(tabName)
          ? item.classList.add('wardrobe-panel-tab__content_active')
          : item.classList.remove('wardrobe-panel-tab__content_active');
      });
    }
  });

  let categories = document.querySelectorAll('.wardrobe-panel-tab__category-item');
  
  categories.forEach((category) => {
    category.addEventListener('click', function () {
      categories.forEach((category) => {
        category.classList.remove('wardrobe-panel-tab__category-item_active');
      });
      this.classList.add('wardrobe-panel-tab__category-item_active');
    });
  });

  let subcategoriesOfClothesTop = document.querySelectorAll('.wardrobe-panel-tab__subcategory-selection');

  subcategoriesOfClothesTop.forEach((subcategory) => {
    subcategory.addEventListener('click', function () {
      subcategoriesOfClothesTop.forEach((subcategory) => {
        subcategory.classList.remove('wardrobe-panel-tab__subcategory-selection_active');
      });
      this.classList.add('wardrobe-panel-tab__subcategory-selection_active');
    });
  });

  let topTabs = document.querySelectorAll('.top-subcategory-tab');
  let topContents = document.querySelectorAll('.top-subcategory-content');

  let bodyTabs = document.querySelectorAll('.body-subcategory-tab');
  let bodyContents = document.querySelectorAll('.body-subcategory-content');

  let headTabs = document.querySelectorAll('.head-subcategory-tab');
  let headContents = document.querySelectorAll('.head-subcategory-content');

  const colorButtons = document.querySelectorAll('.js-change-skin-color');
  colorButtons.forEach((button) => {
    button.addEventListener(
      'click',
      debounce(() => {
        const skinColor = button.style.backgroundColor;

        saveStateToHistory();
        setAvatarProperty('skin', skinColor);
        saveAvatarProps();
        setSkinColor(skinColor);

      }, 400)
    );
  });

  const animButtons = document.querySelectorAll('.js-apply-anim');
  animButtons.forEach((button) => {
    if (!!button.dataset.source) {
      button.addEventListener(
        'click',
        debounce(() => {
          const pose = button.dataset.source;

          saveStateToHistory();
          setAvatarProperty('pose', pose);
          saveAvatarProps();

          loadAnimation(button.dataset.source);

        }, 400)
      );
    }
  });

  const changeClothesButtons = document.querySelectorAll('.js-change-clothes');
  changeClothesButtons.forEach((button) => {
    if (!!button.dataset.source) {
      button.addEventListener(
        'click',
        debounce(() => {

          saveStateToHistory();
          
          const { type, source, color } = button.dataset;

          if (type === 'hat' && false) {
            // if (source === 'WR_G18_Hat_Panama_Classic' || source === 'WR_G18_Cap_Tennis') {
            //   dressGarment({
            //     id: currentAvatarProps.hair.model,
            //     garmentType: 'hair',
            //     color: currentAvatarProps.hair.color,
            //   });
            // }
            if (
              source !== 'WR_B18_Hat_KnitCossacs' &&
              source !== 'WR_B18_Cap_Visor' &&
              source !== 'WR_G18_Hat_Panama_Classic' &&
              source !== 'WR_G18_Cap_Tennis' &&
              source !== currentAvatarProps.hat.model
            ) {
              dressGarment({
                id: 'HC_G18_Pixie_FringeSide',
                garmentType: 'hair',
                color: currentAvatarProps.hair.color,
              });
            }
            if (
              source === currentAvatarProps.hat.model &&
              (source === 'WR_G18_Cap_Baseball' || source === 'WR_G18_Hat_Ski')
            ) {
              console.log(currentAvatarProps.hair.model);
              dressGarment({
                id: currentAvatarProps.hair.model,
                garmentType: 'hair',
                color: currentAvatarProps.hair.color,
              });
            }
          }

          const garmentType = button.dataset.type;
          const garmentId = button.dataset.source;

          if (isGarmentOn(app.garmentSlots, garmentType, garmentId)) {
            if (isRemovable(garmentType)) {
              removeAvatarProperty(garmentType);
              saveAvatarProps();

              undressGarment(garmentType);
            }
          } else {

            setAvatarProperty(type, { type, model: source, color });
            saveAvatarProps();

            const options = {
              id: garmentId,
              garmentType: garmentType,
              trigger: 'menu',
            };
            if (!!button.dataset.color) {
              options.color = button.dataset.color;
            }

            dressGarment(options);
          }
        }, 400)
      );
    }
  });

  const applySetButtons = document.querySelectorAll('.js-apply-set');
  applySetButtons.forEach((button) => {
    if (!!button.dataset.source) {
      button.addEventListener(
        'click',
        debounce(() => {
          saveStateToHistory();
          dressSet(button.dataset.source);
        }, 400)
      );
    }
  });
  const changeJawButtons = document.querySelectorAll('.js-change-morph-shape');
  changeJawButtons.forEach((button) => {
    if (!!button.dataset.source) {
      button.addEventListener(
        'click',
        debounce(() => {
          const { type, source } = button.dataset;

          const avatarProps = {
            'jawShape': 'face',
            'eyesShape': 'eyes',
            'noseShape': 'nose',
            'mouthShape': 'mouth',
          }

          saveStateToHistory();
          setAvatarProperty(avatarProps[type], { type: type, source: source });
          saveAvatarProps();

          applyFaceMorph({ 
            morphType: type, 
            id: source, 
          });
        }, 400)
      );
    }
  });

  const changeBrowsButtons = document.querySelectorAll('.js-change-brows');
  changeBrowsButtons.forEach((button) => {
    if (!!button.dataset.source) {
      button.addEventListener(
        'click',
        debounce(() => {
          const source = button.dataset.source;

          saveStateToHistory();
          setAvatarProperty('brows', source);
          saveAvatarProps();

          dressGarment({ id: source, garmentType: 'brows' });
        }, 400)
      );
    }
  });

  createTabsSwitching(topTabs, topContents, 'top-subcategory-tab_active', 'top-subcategory-content_active');
  createTabsSwitching(
    bodyTabs,
    bodyContents,
    'body-subcategory-tab_active',
    'body-subcategory-content_active',
    'display-block',
    'display-none'
  );

  createTabsSwitching(
    headTabs,
    headContents,
    'head-subcategory-tab_active',
    'head-subcategory-content_active',
    'display-block',
    'display-none'
  );
}

function addListenerToScrollIntoViewOnClick(button, anchor) {
  button.addEventListener('click', () => {
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  });
}

function createTabsSwitching(
  tabs,
  tabsContent,
  activeTabClass,
  activeContentClass,
  displayBlock = '',
  displayNone = ''
) {
  for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', (event) => {
      // сначала нам нужно удалить активный класс именно с вкладок
      let tabsChildren = event.target.parentElement.children;

      for (let t = 0; t < tabsChildren.length; t++) {
        tabsChildren[t].classList.remove(activeTabClass);
      }
      // добавляем активный класс
      tabs[i].classList.add(activeTabClass);
      // теперь нужно удалить активный класс с блоков содержимого вкладок
      let tabContentChildren = event.target.parentElement.parentElement.nextElementSibling.children;

      for (let c = 0; c < tabContentChildren.length; c++) {
        tabContentChildren[c].classList.remove(activeContentClass);
        if (displayBlock) tabContentChildren[c].classList.remove(displayBlock);
      }
      // добавляем активный класс
      tabsContent[i].classList.add(activeContentClass);

      if (displayNone && displayBlock) {
        if (tabsContent[i].classList.contains(displayNone)) {
          tabsContent[i].classList.add(displayBlock);
          tabsContent[i].classList.remove(activeContentClass);
        } else {
          tabsContent[i].classList.add(activeContentClass);
        }
      }
    });
  }
}

export { addMenuHandlers };
