import { cookies, getDateAfter30Days } from '../utils/cookies';

import { avatarService } from '../services/';
import { avatarApplication } from '../scene/application';
import { setAvatarProperty, saveAvatarProps } from '../avatar/avatarProps';
import { launchAvatarEditor } from '../menu/avatarEditor';


function renderAvatarCreate (options) {

    const { mode } = options;

    const defaultGender = 'girl';
    let gender = defaultGender;

    const createTopButton = document.getElementById('create-top-button');
    const modalWindow = document.getElementById('modal-window');
    const closeButton = document.getElementById('close-button');
    const overlay = document.querySelector('.overlay');

    const femaleButton = document.getElementById('female-button');
    const maleButton = document.getElementById('male-button');
    const createAvatar = document.getElementById('create-avatar');
    const maleIcon = document.getElementById('male-icon');
    const femaleIcon = document.getElementById('female-icon');
    const maleImage = document.getElementById('male-picture');
    const femaleImage = document.getElementById('female-picture');


    femaleButton.addEventListener('click', () => {
        femaleButton.classList.add('active-gender-button');
        maleButton.classList.remove('active-gender-button');
        femaleImage.classList.add('female-image-active');
        femaleImage.classList.remove('female-image');
        maleImage.classList.remove('male-image-active');
        maleImage.classList.add('male-image');
        femaleIcon.classList.add('active-icon');
        maleIcon.classList.remove('active-icon');

        gender = 'girl';
    });

    maleButton.addEventListener('click', () => {
        maleButton.classList.add('active-gender-button');
        femaleButton.classList.remove('active-gender-button');
        femaleIcon.classList.remove('active-icon');
        maleIcon.classList.add('active-icon');
        maleImage.classList.add('male-image-active');
        maleImage.classList.remove('male-image');
        femaleImage.classList.remove('female-image-active');
        femaleImage.classList.add('female-image');

        gender = 'boy';
    });

    if (mode === 'full') {
        const createButton = document.getElementById('create-button');

        createButton.addEventListener('click', () => {
            overlay.style.display = 'block';
            modalWindow.classList.remove('hidden');
            overlay.style.opacity = 1;
        });
    }

    createAvatar.addEventListener('click', async () => {
        const { auth, refreshToken } = cookies.get('auth');
        const { user } = cookies.get('user');

        let userEntityJson;
        await avatarService.createAvatar(user.id).then((response) => {
            userEntityJson = response;
        });

        console.warn(userEntityJson);

        cookies.set(
            'avatar-info',
            { avatarId: userEntityJson },
            {
                path: '/',
                secure: true,
                sameSite: 'lax',
                expires: getDateAfter30Days(),
            }
        );

        const maleBtn = document.querySelector('#male-button');
        const femaleBtn = document.querySelector('#female-button');
        const nameInput = document.querySelector('.input-name');

        if (maleBtn.classList.contains('active-gender-button')) {
            setAvatarProperty('gender', 'boy');
        }

        if (femaleBtn.classList.contains('active-gender-button')) {
            setAvatarProperty('gender', 'girl');
        }

        setAvatarProperty('name', nameInput.value);

        avatarApplication.applyGender(gender);
        avatarApplication.avatarId = userEntityJson;

        saveAvatarProps();

        launchAvatarEditor();
    });

    createTopButton.addEventListener('click', () => {
        overlay.style.display = 'block';
        modalWindow.classList.remove('hidden');
        overlay.style.opacity = 1;
    });
    
    closeButton.addEventListener('click', () => {
        modalWindow.classList.add('hidden');
        overlay.style.display = 'none';
        overlay.style.opacity = 0;
    });

    overlay.addEventListener('click', () => {
        modalWindow.classList.add('hidden');
        overlay.style.display = 'none';
        overlay.style.opacity = 0;
    });

}

export { renderAvatarCreate }