const onlyUnique = (origArray) => {
  return origArray.filter((value, index, array) => {
    return array.indexOf(value) === index;
  });
};

const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const intersect = (a, b) => {
  var setA = new Set(a);
  var setB = new Set(b);
  var intersection = new Set([...setA].filter(x => setB.has(x)));
  return Array.from(intersection);
}

export { onlyUnique, debounce, intersect };
