import * as THREE from 'three';
import { avatarApplication as app } from './application';

function addPlane() {

    const scene = app.globalScene;
    const web = app.web;
    const objGeometry = new THREE.SphereGeometry(25, 30, 30);
    const objMaterial = new THREE.MeshPhongMaterial();
    objMaterial.side = THREE.BackSide;
    objMaterial.color = new THREE.Color(0x1f1f1f);
    const earthMesh = new THREE.Mesh(objGeometry, objMaterial);
    earthMesh.name = 'plane';
    earthMesh.scale.setScalar(30);
    earthMesh.receiveShadow = true;
    earthMesh.position.set(0, 645, 0);
    if (!web){
        scene.add(earthMesh);
    }


}

export { addPlane}