const garmentBonesToCheckBySlot = [
    {
        name: 'Boots',
        bonesToCheck: [
            'Joint_Foot_L',
            'Joint_Foot_R',

            'Joint_Shin_L',
            'Joint_Shin_R',
            
            'Joint_Shin_Mid_L',
            'Joint_Shin_Mid_R',

            'Joint_Toe_L',
            'Joint_Toe_R',
        ]
    },
    {
        name: 'Chain',
        bonesToCheck: [
            'Joint_Breast_L', 
            'Joint_Breast_R', 

            'Joint_Chest', 

            'Joint_Clavicle_L', 
            'Joint_Clavicle_R', 

            'Joint_Neck1', 
            'Joint_Neck2', 

            'Joint_Shoulder_L', 
            'Joint_Shoulder_R',

            'Joint_Spine1', 
            'Joint_Spine2', 
        ]
    },
    {
        name: 'Gloves',
        bonesToCheck: [
            'Joint_Elbow_L',
            'Joint_Elbow_R',

            'Joint_ForeArm_L',
            'Joint_ForeArm_R',

            'Joint_ForeArm_Twist_L',
            'Joint_ForeArm_Twist_R',

            'Joint_Shoulder_L',
            'Joint_Shoulder_R',

            'Joint_UpperArm_L',
            'Joint_UpperArm_R',

            'Joint_UpperArm_Twist_L',
            'Joint_UpperArm_Twist_R',

            'Joint_Wrist_L',
            'Joint_Wrist_R',
        ]
    },
    {
        name: 'Hair',
        bonesToCheck: [
            'Joint_Head',
            'Joint_Neck1',
            'Joint_Face_Brow_In_L',
            'Joint_Face_Brow_In_R',
            'Joint_Face_Brow_Mid_L',
            'Joint_Face_Brow_Mid_R',
            'Joint_Face_Brow_Out_L',
            'Joint_Face_Brow_Out_R',        
        ]
    },
    {
        name: 'Jacket',
        bonesToCheck: [
            'Joint_Pelvis',

            'Joint_Chest',

            'Joint_Breast_L',
            'Joint_Breast_R',

            'Joint_Buttock_L',
            'Joint_Buttock_R',

            'Joint_Clavicle_L',
            'Joint_Clavicle_R',

            'Joint_Elbow_L',
            'Joint_Elbow_R',

            'Joint_ForeArm_L',
            'Joint_ForeArm_R',

            'Joint_ForeArm_Twist_L',
            'Joint_ForeArm_Twist_R',

            'Joint_Hip_L',
            'Joint_Hip_R',

            'Joint_Neck1',
            'Joint_Neck2',

            'Joint_Shoulder_L',
            'Joint_Shoulder_R',

            'Joint_Spine1',
            'Joint_Spine2',

            'Joint_UpperArm_L',
            'Joint_UpperArm_R',

            'Joint_UpperArm_Twist_L',
            'Joint_UpperArm_Twist_R',

            'Joint_Wrist_L',
            'Joint_Wrist_R',
        ]
    },
    {
        name: 'Pants',
        bonesToCheck: [
            'Joint_Buttock_L',
            'Joint_Buttock_R',

            'Joint_Chest',

            'Joint_Clavicle_L',
            'Joint_Clavicle_R',

            'Joint_Neck1',

            'Joint_Pelvis',

            'Joint_Shin_L',
            'Joint_Shin_R',
            
            'Joint_Shin_Mid_L',
            'Joint_Shin_Mid_R',

            'Joint_Shoulder_L',
            'Joint_Shoulder_R',

            'Joint_Spine1',
            'Joint_Spine2',
        ]
    },
    {
        name: 'Shirt',
        bonesToCheck: [
            'Joint_Pelvis',

            'Joint_Chest',

            'Joint_Breast_L',
            'Joint_Breast_R',

            'Joint_Buttock_L',
            'Joint_Buttock_R',

            'Joint_Clavicle_L',
            'Joint_Clavicle_R',

            'Joint_Elbow_L',
            'Joint_Elbow_R',

            'Joint_ForeArm_L',
            'Joint_ForeArm_R',

            'Joint_ForeArm_Twist_L',
            'Joint_ForeArm_Twist_R',

            'Joint_Neck1',
            'Joint_Neck2',

            'Joint_Shoulder_L',
            'Joint_Shoulder_R',

            'Joint_Spine1',
            'Joint_Spine2',

            'Joint_UpperArm_L',
            'Joint_UpperArm_R',

            'Joint_UpperArm_Twist_L',
            'Joint_UpperArm_Twist_R',

            'Joint_Wrist_L',
            'Joint_Wrist_R',
        ]
    },
    {
        name: 'Skirt',
        bonesToCheck: [
            'Joint_Pelvis',

            'Joint_Chest',

            'Joint_Buttock_L',
            'Joint_Buttock_R',

            'Joint_Spine1',
            'Joint_Spine2',

            'Joint_Hip_L',
            'Joint_Hip_R',
        ]
    },
    {
        name: 'Socks',
        bonesToCheck: [
            'Joint_Foot_L',
            'Joint_Foot_R',

            'Joint_Shin_L',
            'Joint_Shin_R',
            
            'Joint_Shin_Mid_L',
            'Joint_Shin_Mid_R',

            'Joint_Toe_L',
            'Joint_Toe_R',
        ]
    },
    {
        name: 'Underpants',
        bonesToCheck: [
            'Joint_Pelvis',

            'Joint_Spine1',
            'Joint_Spine2',

            'Joint_Buttock_L',
            'Joint_Buttock_R',

            'Joint_Shin_L',
            'Joint_Shin_R',
            
            'Joint_Shin_Mid_L',
            'Joint_Shin_Mid_R',

        ]
    },
    {
        name: 'Undershirt',
        bonesToCheck: [
            'Joint_Pelvis',

            'Joint_Chest',

            'Joint_Breast_L',
            'Joint_Breast_R',

            'Joint_Clavicle_L',
            'Joint_Clavicle_R',

            'Joint_Neck1',
            'Joint_Neck2',

            'Joint_Shoulder_L',
            'Joint_Shoulder_R',

            'Joint_Spine1',
            'Joint_Spine2',
        ]
    },
];

const garmentBonesToCheckByName = [
    {
        name: 'WR_G18_Singlet',
        'common': [],
        'WR_G18_Jacket_Leather': [
            'Joint_Spine1',
            'Joint_Spine2',
        ]
    },
    {
        name: 'WR_G18_Pants_NinjaCargo',
        'common': [
            'Joint_Pelvis',

            'Joint_Chest',

            'Joint_Breast_L',
            'Joint_Breast_R',

            'Joint_Clavicle_L',
            'Joint_Clavicle_R',

            'Joint_Neck1',
            'Joint_Neck2',

            'Joint_Shoulder_L',
            'Joint_Shoulder_R',

            'Joint_Spine1',
            'Joint_Spine2',
        ]
    },
];

const garmentsBlackList = [
    'WR_G18_Coat_Transparent_Raincoat',
    'WR_G18_Cap_Tennis',
];


const garmentsWithCollisionMesh = {
    'WR_G18_Hat_Panama_Classic': {
        collisionMesh: 'WR_G18_Hat_Panama_Classic_Col'
    },
    'WR_G18_Cap_Baseball': {
        collisionMesh: 'WR_G18_Cap_Baseball_Col'
    },
};

const bonesAdjacent = [
    {
        name: 'Joint_Breast_R',
        adjacent: [
            'Joint_Chest',
        ],
    },
    {
        name: 'Joint_Breast_L',
        adjacent: [
            'Joint_Chest',
        ],
    },
    {
        name: 'Joint_Buttock_L',
        adjacent: [
            'Joint_Pelvis',
            'Joint_Spine1',
            'Joint_Hip_L',
        ],
    },
    {
        name: 'Joint_Buttock_R',
        adjacent: [
            'Joint_Pelvis',
            'Joint_Spine1',
            'Joint_Hip_R',
        ],
    },
    {
        name: 'Joint_Clavicle_L',
        adjacent: [
            'Joint_Shoulder_L',
            'Joint_Chest',
            'Joint_Neck1',
        ],
    },
    {
         name: 'Joint_Clavicle_R',
         adjacent: [
            'Joint_Shoulder_R',
            'Joint_Chest',
            'Joint_Neck1',
        ],
    },
    {
        name: 'Joint_Chest',
        adjacent: [
            'Joint_Breast_L',
            'Joint_Breast_R',
            'Joint_Clavicle_L',
            'Joint_Clavicle_R',
            'Joint_Neck1',
            'Joint_Pelvis',
            'Joint_Spine1',
            'Joint_Spine2',
        ],
    },
    {
        name: 'Joint_Elbow_L',
        adjacent: [
            'Joint_ForeArm_L',
            'Joint_UpperArm_L',
            'Joint_ForeArm_Twist_L', 
            'Joint_Hand_L', 
            'Joint_Wrist_L', 
        ],
    },
    {
        name: 'Joint_Elbow_R',
        adjacent: [
            'Joint_ForeArm_R',
            'Joint_UpperArm_R',
        ],
    },
    {
        name: 'Joint_Face_Brow_In_L',
        adjacent: [
            'Joint_Head',
        ]
    },
    {
        name: 'Joint_Face_Brow_In_R',
        adjacent: [
            'Joint_Head',
        ]
    },
    {
        name: 'Joint_Face_Brow_Mid_L',
        adjacent: [
            'Joint_Head',
        ]
    },
    {
        name: 'Joint_Face_Brow_Mid_R',
        adjacent: [
            'Joint_Head',
        ]
    },
    {
        name: 'Joint_Face_Brow_Out_L',
        adjacent: [
            'Joint_Head',
        ]
    },
    {
        name: 'Joint_Face_Brow_Out_R',
        adjacent: [
            'Joint_Head',
        ]
    },
    {
        name: 'Joint_Foot_L',
        adjacent: [
            'Joint_Shin_L',
            'Joint_Toe_L',
        ]
    },
    {
        name: 'Joint_Foot_R',
        adjacent: [
            'Joint_Shin_R',
            'Joint_Toe_R',
        ]
    },
    {
        name: 'Joint_ForeArm_L',
        adjacent: [
            'Joint_ForeArm_Twist_L', 
            'Joint_UpperArm_L',
            'Joint_Elbow_L',
        ],
    },
    {
       name: 'Joint_ForeArm_R',
       adjacent: [
            'Joint_ForeArm_Twist_R', 
            'Joint_UpperArm_R',
            'Joint_Elbow_R',
        ],
    },
    {
        name: 'Joint_ForeArm_Twist_R',
        adjacent: [
            'Joint_ForeArm_R', 
            'Joint_Wrist_R',
        ],
    },
    {
        name: 'Joint_ForeArm_Twist_L',
        adjacent: [
            'Joint_ForeArm_L', 
            'Joint_Wrist_L',
        ],
    },
    {
        name: 'Joint_Hand_L',
        adjacent: [
            'Joint_Wrist_L',
        ],
    },
    {
        name: 'Joint_Hand_R',
        adjacent: [
            'Joint_Wrist_R',
        ],
    },
    {
        name: 'Joint_Hip_L',
        adjacent: [
            'Joint_Buttock_L',
            'Joint_Pelvis',
            'Joint_Spine1',
        ],
    },
    {
        name: 'Joint_Hip_R',
        adjacent: [
            'Joint_Buttock_R',
            'Joint_Pelvis',
            'Joint_Spine1',
        ],
    },
    {
        name: 'Joint_Neck1',
        adjacent: [
            'Joint_Chest',
        ],
    },
    {
        name: 'Joint_Pelvis',
        adjacent: [
            'Joint_Spine1',
            'Joint_Spine2',
            'Joint_Hip_L',
            'Joint_Hip_R',
        ],
    },
    {
        name: 'Joint_Shin_L',
        adjacent: [
            'Joint_Foot_L',
            'Joint_Shin_Mid_L',
        ]
    },
    {
        name: 'Joint_Shin_R',
        adjacent: [
            'Joint_Foot_R',
            'Joint_Shin_Mid_R',
        ]
    },
    {
        name: 'Joint_Shin_Mid_L',
        adjacent: [
            'Joint_Foot_L',
            'Joint_Shin_L',
        ]
    },
    {
        name: 'Joint_Shin_Mid_R',
        adjacent: [
            'Joint_Foot_R',
            'Joint_Shin_R',
        ]
    },
    {
        name: 'Joint_Shoulder_L',
        adjacent: [
            'Joint_Clavicle_L',
            'Joint_UpperArm_L',
            'Joint_UpperArm_Twist_L',
        ],
    },
    {
        name: 'Joint_Shoulder_R',
        adjacent: [
            'Joint_Clavicle_R',
            'Joint_UpperArm_R',
            'Joint_UpperArm_Twist_R',
        ],
    },
    {
        name: 'Joint_Spine1',
        adjacent: [
            'Joint_Hip_L',
            'Joint_Hip_R',
            'Joint_Spine2', 
            'Joint_Pelvis',
        ],
    },
    {
        name: 'Joint_Spine2',
        adjacent: [
            'Joint_Hip_L',
            'Joint_Hip_R',
            'Joint_Spine1', 
            'Joint_Chest',
        ],
    },
    {
        name: 'Joint_Toe_L',
        adjacent: [
            'Joint_Foot_L',
        ]
    },
    {
        name: 'Joint_Toe_R',
        adjacent: [
            'Joint_Foot_R',
        ]
    },
    {
        name: 'Joint_UpperArm_L',
        adjacent: [
            'Joint_ForeArm_L', 
            'Joint_UpperArm_Twist_L',
        ],
    },
    {
        name: 'Joint_UpperArm_R',
        adjacent: [
            'Joint_ForeArm_R', 
            'Joint_UpperArm_Twist_R',
        ],
    },
    {
        name: 'Joint_UpperArm_Twist_L',
        adjacent: [
            'Joint_Shoulder_L', 
            'Joint_UpperArm_L',
        ],
    },
    {
        name: 'Joint_UpperArm_Twist_R',
        adjacent: [
            'Joint_Shoulder_R', 
            'Joint_UpperArm_R',
        ],
    },
    {
        name: 'Joint_Wrist_L',
        adjacent: [
            'Joint_ForeArm_Twist_L',
        ],
    },
    {
        name: 'Joint_Wrist_R',
        adjacent: [
            'Joint_ForeArm_Twist_R',
        ],
    },
  ];

function isGarmentBlackListed(garmentId) {
    return garmentsBlackList.includes(garmentId);
}

function getBonesListToCheck(slotName, innerGarmentName, outerGarmentName) {

    let bones = [];
    let slotIndex = -1;
    let garmentIndex = -1;
    let garment = {};
    const extraGarments = ['common', outerGarmentName];

    slotIndex = garmentBonesToCheckBySlot.findIndex(slot => slot.name === slotName);

    if (slotIndex > -1) {
        bones = garmentBonesToCheckBySlot[slotIndex].bonesToCheck;
    }

    garmentIndex = garmentBonesToCheckByName.findIndex(garment => garment.name === innerGarmentName);

    if (garmentIndex > -1) {
        garment = garmentBonesToCheckByName[garmentIndex];
        
        extraGarments.forEach(element => {
            if (!!garment[element]) {
                garment[element].forEach(bone => {
                    bones.push(bone);
                });
            }
        })
    }

    return bones;
}

// Возвращает дополнительный меш для приминания за пределами основной шмотки 
function getCollisionMeshName(id) {    
    return !!id && garmentsWithCollisionMesh[id] 
        ? garmentsWithCollisionMesh[id].collisionMesh
        : undefined;
}

export { bonesAdjacent, getBonesListToCheck, isGarmentBlackListed, getCollisionMeshName }