// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__item/item-selector__item.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item-selector {\n  width: 256px;\n  height: 72vh;\n  list-style: none;\n  overflow-y: scroll;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.item-selector::-webkit-scrollbar {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/css/components/item-selector/item-selector.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf","sourcesContent":["@import url(./__item/item-selector__item.css);\n\n.item-selector {\n  width: 256px;\n  height: 72vh;\n  list-style: none;\n  overflow-y: scroll;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.item-selector::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
