export default [
  {
    type: 'skirt',
    model: 'WR_G18_Skirt_CyberAngel',
    colors: {
      yellow: '#f3c92a',
      Green: '#7DB059',
      Red_Dark: 'blue',
    },
  },
  {
    type: 'skirt',
    model: 'WR_G18_Skirt_Native_American_Skirt_001',
    colors: {
      Brown_Light: '#FFE338',
      Orange: 'lightblue',
      Black: 'ivory',
    },
  },
  {
    type: 'skirt',
    model: 'WR_G18_Skirt_PufferCropped',
    colors: {
      Green: '#3BA8A3',
    },
  },
  {
    type: 'skirt',
    model: 'WR_G18_Skirt_TubeShortHighWaist',
    colors: {
      Brown_Light: 'tan',
      Black: 'dimgray',
      Green: '#4A967A',
      Pink_Light: 'pink',
      White: 'white',
      Yellow: '#EBC23B',
    },
  },
  {
    type: 'pants',
    model: 'WR_G18_Pants_Cargo_Sport_01',
    colors: {
      test: 'tan',
      test1: 'red',
      test2: 'pink',
    },
  },
  {
    type: 'hair',
    model: 'HC_G18_Bun_HighMessyClassic',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },
  {
    type: 'hair',
    model: 'HC_G18_Lob_Mid_Straight',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },
  {
    type: 'hair',
    model: 'HC_G18_Pixie_FringeSide',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },
  {
    type: 'hair',
    model: 'HC_G18_Garcon_Long_Curly',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },

  {
    type: 'hair',
    model: 'HC_G18_Pot_VeryShort',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },

  {
    type: 'hair',
    model: 'HC_G18_Undercut_Blowout',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },

  {
    type: 'hair',
    model: 'HC_G18_Bob_Short_FringeShort',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },

  {
    type: 'hair',
    model: 'HC_G18_Wave_Bob',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },
  {
    type: 'hair',
    model: 'HC_G18_Pixie_Short_Straight',
    colors: {
      Blonde: '#FFCB8E',
      Black: 'grey',
      DeepPink: 'pink',
      GreenDark: 'green',
    },
  },
];
