// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!./_selected/side-menu__section_selected.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".side-menu__section {\n  width: 164px;\n  height: 164px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 2px solid transparent;\n  border-radius: 48px;\n  cursor: pointer;\n  transition: border 0.3s ease;\n}\n\n.side-menu__section:hover {\n  border: 2px solid rgba(255, 255, 255, 0.4);\n}\n", "",{"version":3,"sources":["webpack://./src/css/components/side-menu/__section/side-menu__section.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":["@import url(./_selected/side-menu__section_selected.css);\n\n.side-menu__section {\n  width: 164px;\n  height: 164px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 2px solid transparent;\n  border-radius: 48px;\n  cursor: pointer;\n  transition: border 0.3s ease;\n}\n\n.side-menu__section:hover {\n  border: 2px solid rgba(255, 255, 255, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
