import { intersect } from '../utils/helpers';

const garmentLayers = {
    'backpack': {
        slot: 'Backpack',
        weight: 10,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'body',
            'bra',
            'dress',
            'jacket',
            'jumpsuit',
            'sweater',
            'tshirt',
        ]
    },
    'bag': {
        slot: 'Handbag',
        weight: 10,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'belt',
            'body',
            'bra',
            'dress',
            'jacket',
            'jumpsuit',
            'pants',
            'shirt',
            'skirt',
            'sweater',
            'tshirt',
        ],
        receiveShadow: false,
    },
    'belt': {
        slot: 'Belt',
        weight: 6,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'body',
            'jumpsuit',
            'pants',
            'skirt',
        ]
    },
    'body': {
        slots: ['Shirt','Underpants','Undershirt'],
        weight: 1,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },
    'bra': {
        slot: 'Undershirt',
        weight: 4,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'skirt',
        ]
    },
    'bracelet': {
        slots: ['Bracelet','Gloves'],
        weight: 2,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'body'
        ],
        castShadow: false,
        receiveShadow: false,
    },
    'brows': {
        slot: 'Brows',
        weight: 1,
        affectedBy: [],
        unremovable: true,
        innerTypes: []
    },
    'coat': {
        slot: 'Jacket',
        weight: 8,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'belt',
            'body',
            'bra',
            'bracelet',
            'dress',
            'gloves',
            'jumpsuit',
            'necklace',
            'pants',
            'skirt',
            'sweater',
            'tshirt',
        ]
    },
    'dress': {
        slots: ['Pants','Shirt','Skirt','Undershirt','Underpants'],
        weight: 4,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },
    'earrings': {
        slot: 'Earrings',
        weight: 2,
        affectedBy: [],
        innerTypes: [],
        receiveShadow: false,
    },
    'glasses': {
        slot: 'Glasses',
        weight: 2,
        affectedBy: [],
        innerTypes: [],
        receiveShadow: false,
    },
    'gloves': {
        slots: ['Bracelet','Gloves'],
        weight: 4,
        affectedBy: [],
        innerTypes: [
            'body',
        ]
    },
    'hair': {
        slot: 'Hair',
        weight: 2,
        affectedBy: [
            'smoothing',
        ],
        unremovable: true,
        innerTypes: []
    },
    'hat': {
        slot: 'Hat',
        weight: 6,
        affectedBy: [],
        innerTypes: ['hair']
    },
    'hoodies': {
        slots: ['Shirt','Skirt','Undershirt'],
        weight: 4,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },
    'jacket': {
        slot: 'Jacket',
        weight: 8,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'belt',
            'body',
            'bra',
            'bracelet',
            'dress',
            'gloves',
            'jumpsuit',
            'necklace',
            'pants',
            'shirt',
            'skirt',
            'sweater',
            'tshirt',
        ]
    },
    'jumpsuit': {
        slots: ['Shirt','Skirt','Pants','Underpants','Undershirt'],
        weight: 5,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'necklace'
        ]
    },
    'necklace': {
        slot: 'Chain',
        weight: 4,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'body',
            'shirt',
            'tshirt'
        ],
        castShadow: false,
        receiveShadow: false,
    },
    'panties': {
        slots: ['Underpants'],
        weight: 1,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },
    'pants': {
        slots: ['Skirt','Pants'],
        weight: 3,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'body',
            'socks',
            'tshirt',
        ]
    },
    'sandals': {
        slots: ['Boots','Socks'],
        weight: 3,
        affectedBy: [
            'smoothing',
        ],
        innerTypes: []
    },
    'shirt': {
        slots: ['Shirt','Undershirt'],
        weight: 4,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'pants',
        ]
    },
    'shoes': {
        slot: 'Boots',
        weight: 3,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'socks',
        ]
    },
    'skirt': {
        slots: ['Skirt'],
        weight: 3,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'body',
            'tshirt',
        ]
    },
    'sneakers': {
        slot: 'Boots',
        weight: 3,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: [
            'socks',
        ]
    },
    'socks': {
        slot: 'Socks',
        weight: 1,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },
    'sweater': {
        slots: ['Shirt','Undershirt'],
        weight: 7,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },
    'tshirt': {
        slots: ['Shirt','Undershirt'],
        weight: 2,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },
    'widePants': {
        slots: ['Pants','Skirt'],
        weight: 7,
        affectedBy: [
            'smoothing',
            'morphing',
        ],
        innerTypes: []
    },

};

const garmentSlots = [
    'Backpack',
    'Belt',
    'BeltBag',
    'Boots',
    'Bracelet',
    'Chain',
    'Earrings',
    'Glasses',
    'Gloves',
    'Handbag',
    'Hat',
    'Jacket',
    'Pants',
    'Shirt',
    'Skirt',
    'Socks',
    'Underpants',
    'Undershirt',
];

const bodySlots = [
    'Brows',
    'Hair',
]

const avatarUnderwear = {
    'female': {
        Underpants: {
            type: 'panties',
            id: 'WR_G18_Panties_000',
        },
        Undershirt: {
            type: 'bra',
            id: 'WR_G18_Bra_000',
        },    
    },
    'male': {
        Underpants: {
            type: 'panties',
            id: 'WR_B18_Panties_Boxer',
        },
    }
}

function initGarmentSlots() {
    const appGarmentSlots = {};

    getAllSlots().forEach(slot => {
        appGarmentSlots[slot] = {
            activeType: '',
            activeGarment: ''
        }
    });

    return appGarmentSlots;
}

function getSlotsByType(type) {
    const garmentType = garmentLayers[type];

    return !!garmentType 
        ? !!garmentType.slot
            ? Array.of(garmentType.slot)
            : garmentType.slots
        : [];
}

// Возвращает типы шмоток, которые делят те же слоты, что и передаваемый type
function getSlotsSharingTypes(type) {
    const typeSlots = getSlotsByType(type);
    return Object.keys(garmentLayers).filter((key) => {
        return key !== type && intersect(typeSlots, getSlotsByType(key)).length > 0;
    });
}

function getGarmentSlots() {
    return garmentSlots;
}

function getAllSlots() {
    return garmentSlots.concat(bodySlots);
}

function getLayerInnerTypes(garmentLayer) {
    return garmentLayers[garmentLayer].innerTypes;
}

function isGarment(type) {
    return Object.keys(garmentLayers).includes(type);
}

function isRemovable(type) {
    return !garmentLayers[type].unremovable;
}

function isGarmentOn(appGarmentSlots, garmentType, garmentId) {
    const garmentSlots = getSlotsByType(garmentType);
    return appGarmentSlots[garmentSlots[0]].activeGarment === garmentId;
}

function isAffectedBySmoothing(type) {
    return isGarment(type) && garmentLayers[type].affectedBy.includes['smoothing'];
}

function isAffectedByMorphing(type) {
    return !type || (isGarment(type) && garmentLayers[type].affectedBy?.includes('morphing'));
}

function initSmoothingSlots() {
    return {};
}

function initFittingSlots() {
    return {};
}

function getUnderwear(gender) {
    return avatarUnderwear[gender];
}
  

export { 
    garmentLayers, 
    initGarmentSlots, 
    getSlotsByType, 
    getSlotsSharingTypes,
    getGarmentSlots, 
    getAllSlots, 
    initSmoothingSlots, 
    initFittingSlots,
    isGarment,
    isGarmentOn, 
    isAffectedBySmoothing,
    isAffectedByMorphing,
    isRemovable,
    getLayerInnerTypes,
    getUnderwear,
}