
import sexSelection from '../../html/sex-selection.html';
import sexSelectionWithCreatedAvatar from '../../html/sex-selection-with-created-avatar.html';

import { getAvatars } from '../services/getAvatars';

import { renderUsermenu } from './usermenu';
import { renderAvatarsList } from './avatarsList';
import { renderAvatarCreate } from './createAvatar';

async function renderLauncherScreen(name, lastname, email) {
    
    const bodyElement = document.querySelector('body');

    const avatars = await getAvatars();

    if (!avatars) {
        bodyElement.innerHTML = sexSelection;
        renderUsermenu(name, lastname, email);
        renderAvatarCreate({ mode: 'full' });
    } else {
        bodyElement.innerHTML = sexSelectionWithCreatedAvatar;
        renderUsermenu(name, lastname, email);
        renderAvatarsList(avatars);
        renderAvatarCreate({ mode: 'brief' });
    }

}

export { renderLauncherScreen }
