// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!./_theme_blue/side-menu__section-content_theme_blue.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!./_theme_green/side-menu__section-content_theme_green.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!./_theme_purple/side-menu__section-content_theme_purple.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".side-menu__section-content {\n  width: 155px;\n  height: 155px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.25);\n/*  backdrop-filter: blur(40px);*/\n  border-radius: 48px;\n  position: relative;\n  overflow: hidden;\n  text-align: center;\n  letter-spacing: -0.02em;\n  opacity: 0.9;\n\n  color: var(--color-primary-white);\n}\n", "",{"version":3,"sources":["webpack://./src/css/components/side-menu/__section-content/side-menu__section-content.css"],"names":[],"mappings":"AAIA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,6CAA6C;AAC/C,iCAAiC;EAC/B,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;;EAEZ,iCAAiC;AACnC","sourcesContent":["@import url(./_theme_blue/side-menu__section-content_theme_blue.css);\n@import url(./_theme_green/side-menu__section-content_theme_green.css);\n@import url(./_theme_purple/side-menu__section-content_theme_purple.css);\n\n.side-menu__section-content {\n  width: 155px;\n  height: 155px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.25);\n/*  backdrop-filter: blur(40px);*/\n  border-radius: 48px;\n  position: relative;\n  overflow: hidden;\n  text-align: center;\n  letter-spacing: -0.02em;\n  opacity: 0.9;\n\n  color: var(--color-primary-white);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
