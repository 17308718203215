import { avatarApplication as app } from '../scene/application';
import { morphTarget } from '../../../public/morphs/morphs';
import { getActiveBodyMorphs } from '../menu/data';
import { getAvatarProperty, setAvatarProperty, saveAvatarProps } from './avatarProps';
import { saveStateToHistory } from '../utils/historyByState';
import { applyMorphTargetImpact, getBodyMorphDelta } from '../processing/fitting'

function applyFaceMorph(options) {
    const { morphType, id } = options;

    const morphId = Number(id);
    const bodyId = app.getAvatarBodyName();
    const body = app.getAvatarBodyMesh();

    clearFaceMorph(morphType);

    body.morphTargetInfluences[morphId] = 1;
}

function clearFaceMorph(morphType) {
    const bodyId = app.getAvatarBodyName();
    const body = app.getAvatarBodyMesh();

    for (let i = 0; i < morphTarget[bodyId].face[morphType].length; i++) {
        body.morphTargetInfluences[morphTarget[bodyId].face[morphType][i].id] = 0;
    }
}

function applyBodyMorph(options) {
    const { morphId, morphValue } = options;
    const body = app.getAvatarBodyMesh();

    body.morphTargetInfluences[parseInt(morphId)] = parseFloat(morphValue);
}

function initFaceMorphs(avatarProps) {
    const faceMorphs = [ 'face', 'eyes', 'nose', 'mouth' ];
  
    faceMorphs.forEach(morphProp => {
      if (avatarProps[morphProp]) {
        applyFaceMorph({ 
            morphType: avatarProps[morphProp].type,
            id: avatarProps[morphProp].source, 
        });
      }
    });
  
  }
  
function initBodyMorphs(avatarProps) {
  
    if (app.enableFitting) {
  
        const bodyMorphs = getActiveBodyMorphs().map(morph => morph.id);
  
        bodyMorphs.forEach(morphProp => {
    
            const loadedValue = avatarProps[morphProp];
        
            const sliderId = morphProp + 'Slider';
            const sliderElement = document.getElementById(sliderId);
        
            if (!!sliderElement) {
        
                if (!!loadedValue) {
                    sliderElement.value = loadedValue;
                    applyBodyMorph({ 
                        morphId: sliderElement.dataset.source, 
                        morphValue: loadedValue
                    });    
                }
        
                sliderElement.oninput = function() {
                    applyBodyMorph({ 
                        morphId: sliderElement.dataset.source, 
                        morphValue: sliderElement.value
                    });
                };
        
                sliderElement.onchange = function() {
                    if (getAvatarProperty(morphProp) !== sliderElement.value) {
        
                        saveStateToHistory();
                        setAvatarProperty(morphProp, sliderElement.value);
                        saveAvatarProps();
                        applyMorphTargetImpact();
    
                    }
                };
            }
        });
    
        getBodyMorphDelta();
    }
  
}
  
// Cлайдеры для фигуры на основе костей
function initBodyMorphsLegacy(avatarProps) {
    const scene = app.scene;

    const sliderHeight = document.getElementById('heightSlider');
    const sliderShoulders = document.getElementById('shoulderSlider');
    const sliderHips = document.getElementById('hipsSlider');

    if (!!sliderHeight) {
        if (!!avatarProps.height) {
            scene.getObjectByName('avatar').scale.setScalar(avatarProps.height / 10);
            sliderHeight.value = avatarProps.height;
        }    
        sliderHeight.oninput = function () {
            scene.getObjectByName('avatar').scale.setScalar(this.value / 10);
        };
        sliderHeight.onchange = function () {
            setAvatarProperty('height', this.value);
            saveAvatarProps();
        };
    }

    if (!!sliderHips) {
        if (!!avatarProps.hips) {
            sliderHips.value = avatarProps.hips;
            setTimeout(() => {
                scene.getObjectByName('Joint_Hip_R').scale.setScalar(avatarProps.hips / 10);
                scene.getObjectByName('Joint_Hip_L').scale.setScalar(avatarProps.hips / 10);
            }, 1000);
        }
        sliderHips.oninput = function () {
            scene.getObjectByName('Joint_Hip_R').scale.setScalar(this.value / 10);
            scene.getObjectByName('Joint_Hip_L').scale.setScalar(this.value / 10);
        };
        sliderHips.onchange = function () {
            setAvatarProperty('hips', this.value);
            saveAvatarProps();
        };    
    }

    if (!!sliderShoulders) {
        if (!!avatarProps.shoulders) {
            sliderShoulders.value = avatarProps.shoulders;
            setTimeout(() => {
                scene.getObjectByName('Joint_Shoulder_R').scale.setScalar(avatarProps.shoulders / 10);
                scene.getObjectByName('Joint_Shoulder_L').scale.setScalar(avatarProps.shoulders / 10);
            }, 1000);
        }
        sliderShoulders.oninput = function () {
            scene.getObjectByName('Joint_Shoulder_R').scale.setScalar(this.value / 10);
            scene.getObjectByName('Joint_Shoulder_L').scale.setScalar(this.value / 10);
        };
        sliderShoulders.onchange = function () {
            setAvatarProperty('shoulders', this.value);
            saveAvatarProps();
        };    
    }
}

export { applyFaceMorph, clearFaceMorph, applyBodyMorph, initFaceMorphs, initBodyMorphs, initBodyMorphsLegacy };
