import { avatarService } from '../services';
import { avatarApplication as app } from '../scene/application';
import { getSlotsSharingTypes } from "../store/store";

let defaultAvatarProps = {
    gender: 'girl'
}

let currentAvatarProps = { ...defaultAvatarProps };

function resetStore(obj) {
    obj = obj || defaultAvatarProps;
    currentAvatarProps = { ...obj };
}

function removeEmptyProps(avatarProps) {
    const parsedProps = !!avatarProps ? { ...avatarProps} : {};

    Object.keys(avatarProps).forEach(property => {
        if (!parsedProps[property] || parsedProps[property] === 'none' || parsedProps[property] === '0') {
            delete parsedProps[property];
        }
    });

    return parsedProps;
}

function removeAvatarProperty(property) {
    delete currentAvatarProps[property];
}

function getAvatarProperty(property) {
    return currentAvatarProps[property];
}

function setAvatarProperty(property, newValue) {
    const slotsSharingTypes = getSlotsSharingTypes(property);

    slotsSharingTypes.forEach(type => {
        delete currentAvatarProps[type];
    });

    currentAvatarProps[property] = newValue;
}

function saveAvatarProps(options) {
    options = options || {};
    const avatarId = options.avatarId || app.avatarId;
    const avatarProps = options.avatarProps || currentAvatarProps;
    avatarService.postItems(avatarId, avatarProps);
}

export { 
    currentAvatarProps,
    defaultAvatarProps,
    getAvatarProperty,
    setAvatarProperty,
    removeAvatarProperty,
    resetStore,
    removeEmptyProps,
    saveAvatarProps,
};
