import authPage from '../../html/auth.html';

import { signUp, signIn } from './sign-up';
import { cookies, getDateAfter30Days } from '../utils/cookies';

import { renderLauncherScreen } from '../launcher/layout';

function renderAuthScreen() {

    const bodyElement = document.querySelector('body');
    bodyElement.innerHTML = authPage;

    const signUpForm = document.querySelector('.js-sign-up-form');
    const signInForm = document.querySelector('.js-sign-in-form');
    const signUpBtn = document.querySelector('.js-sign-up');
    const signInBtn = document.querySelector('.js-sign-in');
    const signInContainer = document.querySelector('.js-sign-in-container');
    const signUpContainer = document.querySelector('.js-sign-up-container');
    const signUpFormInputs = signUpForm.getElementsByTagName('input');
    const signInFormInputs = signInForm.getElementsByTagName('input');

    signUpContainer.style.display = 'none';

    signInBtn.addEventListener('click', () => {
        signInContainer.style.display = 'flex';
        signUpContainer.style.display = 'none';
    });

    signUpBtn.addEventListener('click', () => {
        signInContainer.style.display = 'none';
        signUpContainer.style.display = 'flex';
    });

    signUpForm.addEventListener('submit', (e) => signUp(e, signUpFormInputs, signInContainer, signUpContainer));
    signInForm.addEventListener('submit', async (e) => {

        const response = await signIn(e, signInFormInputs, signInContainer, signUpContainer);
        if (!response) {
            return;
        }

        if (response.accessToken) {
            cookies.set(
                'user',
                { user: response.user },
                {
                    path: '/',
                    secure: true,
                    sameSite: 'lax',
                    expires: getDateAfter30Days(),
                }
            );

            renderLauncherScreen(response.user.firstname, response.user.lastname, response.user.email);
        }
    });
    
    const passwordInput = document.querySelector('#password');
    const passwordInputSignUp = document.querySelector('#password-sign-up');
    const eye = document.querySelector('#eye');
    const eyeSignUp = document.querySelector('#eye-sign-up');

    eye.addEventListener('click', function () {
        this.classList.toggle('fa-eye-slash');
        const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
        passwordInput.setAttribute('type', type);
    });

    eyeSignUp.addEventListener('click', function () {
        this.classList.toggle('fa-eye-slash');
        const type = passwordInputSignUp.getAttribute('type') === 'password' ? 'text' : 'password';
        passwordInputSignUp.setAttribute('type', type);
    });

}

export { renderAuthScreen }