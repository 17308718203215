import { isGarment, getSlotsSharingTypes } from '../store/store';
import { dressGarment } from '../clothes/dressGarment';
import { currentAvatarProps } from '../avatar/avatarProps';
import { avatarApplication as app } from '../scene/application';

function dressAllStoredClothes(json) {
    if (!!json) {
        for (const [key, value] of Object.entries(json)) {
            if (isGarment(key)) {

                const options = {
                    garmentType: json[key].type,
                    id: json[key].model
                };

                if (!!json[key].color) {
                    options.color = json[key].color
                }

                setTimeout(() => {
                    dressGarment(options);
                }, 500);
            }
        }
    }
};

function getAvatarStartupProps(json) {
    const avatarProps = !!json ? { ...json } : { ...currentAvatarProps };
    const configProps = app.avatarConfig[app.avatarGender].startupProps;
 
    const brows = avatarProps.brows;
    avatarProps.brows = {
        type: 'brows',
        model: !brows ? configProps.brows : brows
    }

    if (!avatarProps.hair) {
        avatarProps.hair = {
            type: 'hair',
            model: configProps.hair,
        }
        if (!!configProps.hairColor) {
            avatarProps.hair.color = configProps.hairColor;
        }
    }

    if (app.isFemale() 
            && !!avatarProps.hat?.model 
            && !Array.of('WR_G18_Hat_Panama_Classic', 'WR_G18_Cap_Tennis').includes(avatarProps.hat.model)) {
        avatarProps.hair.model = 'HC_G18_Pixie_FringeSide';
    }

    if (!avatarProps.pose) {
        avatarProps.pose = configProps.pose;
    }

    for (const underwearSlot in app.underwearGarments) {
        const underwear = app.underwearGarments[underwearSlot];

        const coveringTypes = getSlotsSharingTypes(underwear.type).filter(type => {
            return !!avatarProps[type];
        });

        if (coveringTypes.length === 0 && !avatarProps[underwear.type]) {
            avatarProps[underwear.type] = {
                type: underwear.type,
                model: underwear.id
            }
        }
    }

    return avatarProps;
}



export { dressAllStoredClothes, getAvatarStartupProps }