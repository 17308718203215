import { avatarApplication as app } from '../scene/application';

function setSkinColor(color) {
    
    const bodyMesh = app.getAvatarBodyMesh();

    if (!!color) {
        bodyMesh.material.color.set(color);
    } else {
        bodyMesh.material.color.setRGB(0.89411765, 0.64705884, 0.5686275);
    }
    
}

export { setSkinColor }