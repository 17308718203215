import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { saveAsImage } from '../scene/scene';
import { avatarApplication as app } from '../scene/application';

function exportModelHandler() {
    const input = document.querySelector('.selection-screen__input');
    const inputForImage = document.querySelector('.selection-screen__input-1');
    const exportButton = document.querySelector('.js-button-export');
    const selectionScreenButton = document.querySelector('.selection-screen-export-button');
    const exportScreenshotImage = document.querySelector('.selection-screen-export-button-1');
    const selectionScreen = document.querySelector('.selection-screen');
    const overlay = document.querySelector('.overlay');

    const selectionScreenTabs = document.querySelectorAll('.selection-screen__tabs');

    exportButton.addEventListener('click', () => {
        var strMime = 'image/jpeg';
        var snapshotPreview = document.getElementById('snapshot-preview');
        snapshotPreview.src = app.canvas.toDataURL(strMime)
        input.value = '';
        inputForImage.value = '';
        selectionScreen.classList.add('selection-screen_shown');
        selectionScreen.style.zIndex = '100000';
        selectionScreenButton.style.width = '308px';
        overlay.style.display = 'block';
        overlay.style.opacity = 1;
    });



    selectionScreenButton.addEventListener('click', () => {
        const scene = app.scene;
        const model = scene.getObjectByName('avatar').parent.toJSON();
        console.log(model);
        let loadingIcon = document.getElementById('loaderWrapper');
        loadingIcon.classList.remove('hidden');
        const inputValue = input.value.split(' ').join('_');
        const exporter = new GLTFExporter();
        console.log(scene.getObjectByName('avatar'));
        exporter.parse(
            scene.getObjectByName('avatar').parent,
            function (gltf) {

                var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(gltf));
                var downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', dataStr);
                downloadAnchorNode.setAttribute('download', `${inputValue || 'untitled'}` + '.gltf');
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();

                overlay.style.display = 'none';
                overlay.style.opacity = 0;
                selectionScreen.classList.remove('selection-screen_shown');
                selectionScreen.style.zIndex = '-10000';
                let loadingIcon = document.getElementById('loaderWrapper');
                loadingIcon.classList.add('hidden');
            },
            function (error) {
                console.log('An error happened');
            }
        );
    });

    overlay.addEventListener('click', () => {
        overlay.style.display = 'none';
        overlay.style.opacity = 0;
        input.value = '';
        inputForImage.value = '';
        selectionScreen.classList.remove('selection-screen_shown');
        selectionScreen.style.zIndex = '-10000';
    });

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            overlay.style.display = 'none';
            input.value = '';
            inputForImage.value = '';
            selectionScreen.classList.remove('selection-screen_shown');
            selectionScreen.style.zIndex = '-10000';
        }
    });

    for (let i = 0; i < selectionScreenTabs.length; i++) {
        selectionScreenTabs[i].addEventListener('click', (event) => {
            let tabsChildren = event.target.parentElement.children;

            for (let t = 0; t < tabsChildren.length; t++) {
                tabsChildren[t].classList.remove('selection-screen__tab_active');
            }
            event.target.classList.add('selection-screen__tab_active');

            let tabContent = event.target.parentElement.nextElementSibling;

            if (event.target.classList.contains('elem1')) {
                tabContent.querySelector('.elem1').classList.add('selection-screen__content_active');

                tabContent.querySelector('.elem2').classList.remove('selection-screen__content_active');
            }

            if (event.target.classList.contains('elem2')) {
                tabContent.querySelector('.elem1').classList.remove('selection-screen__content_active');
                tabContent.querySelector('.elem2').classList.add('selection-screen__content_active');
            }
        });
    }


    exportScreenshotImage.addEventListener('click', () => {
        overlay.style.display = 'none';
        input.value = '';
        selectionScreen.classList.remove('selection-screen_shown');
        selectionScreen.style.zIndex = '-10000';
    });
    saveAsImage(exportScreenshotImage, inputForImage);
}

export { exportModelHandler }
