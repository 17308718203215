
import './css/styles.css';
import './css/index.css';
import './../public/fonts.css';

import { cookies } from './js/utils/cookies';

import { renderAuthScreen } from './js/auth/layout';
import { renderLauncherScreen } from './js/launcher/layout';

const authCookie = cookies.get('auth');
const userCookie = cookies.get('user');

if (authCookie?.auth && userCookie?.user) {
    renderLauncherScreen(userCookie.user.firstname, userCookie.user.lastname, userCookie.user.email);
} else {
    renderAuthScreen();
}
