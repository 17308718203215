import * as THREE from 'three';

import { onlyUnique } from '../utils/helpers';

import { initSmoothingSlots, initGarmentSlots, initFittingSlots, getUnderwear, isAffectedByMorphing } from '../store/store';
import { smoothingDebugMode, initGarmentsSmoothing } from '../processing/smoothing';
import { applyMorphTargetImpact } from '../processing/fitting';

import { disableLoadingScreen } from '../menu/loading';

import { isFootGarment, getGarmentMesh } from '../clothes/common';
import { setBonesParams } from '../clothes/setBoneParams';

import { initHistoryByState } from '../utils/historyByState';

import avatarConfig from '../../config/config.json';
import avatarConfigWeb from '../../config/config_web.json';

const enableDebugLogs = false;

function debugLog(log1, log2, log3) {
    if (enableDebugLogs) {
        if (!!log3) {
            console.log(log1, log2, log3);
        } else if (!!log2) {
            console.log(log1, log2);
        } else {
            console.log(log1);
        }
    }
}

class AvatarApp {

    constructor() {
        const that = this;

        const params = new URLSearchParams(window.location.search);

        if (params.has('web')) {
            this.web = true;
        } else {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|diordnA 9|Opera Mini/i.test(navigator.userAgent)) {
                this.web = true;
            } else {
                this.web = false;
            }
        }

        this.avatarConfig = this.web ? avatarConfigWeb.avatar : avatarConfig.avatar;
        this.avatarModelName = 'avatar';

        this.fullScreen = false;

        this.enableSmoothing = true; // приминание
        this.enableFitting = true; // блендшейпы для одежды

        this.smoothingDebugMode = smoothingDebugMode || false;

        this.enableSmoothingCache = true;
        this.verticesCache = [];

        this.garmentsLoading = 0;

        this.garmentsLoadingManager = new THREE.LoadingManager();
        this.commonLoadingManager = new THREE.LoadingManager();

        this.commonLoadingManager.onProgress = function (item, loaded, total) {
            debugLog('commonLoadingManager', item, loaded + '/' + total);
        };
    
        this.garmentsLoadingManager.onProgress = function (item, loaded, total) {
            debugLog('garmentsLoadingManager', item, loaded + '/' + total);
        };
    
        this.garmentsLoadingManager.onLoad = function () {    
            debugLog('...Loading completed...');
            const countdownToSmoothing = setInterval(function () {
                if (that.garmentsLoading === 0) {
                    clearInterval(countdownToSmoothing);
                    initGarmentsSmoothing();
                }
            }, 100);
        };

        this.smoothingSavedPositions = {};
        this.fittingRegistry = {
            vertices: [],
            bones: {},
            processedMeshes: []
        };

        this.garmentSlots = initGarmentSlots();
        this.smoothingSlots = initSmoothingSlots();
        this.fittingSlots = initFittingSlots();
        this.historyByState = initHistoryByState();
    
        this.sceneRenderQueue = [];
    
    }

    applyGender(gender) {
        this.avatarGender = gender === 'boy' ? 'male' : 'female';
        this.underwearGarments = getUnderwear(this.avatarGender);
        this.enableFitting = this.enableFitting && !this.web && this.isFemale();
    }

    getAvatarBodyName() {
        return this.avatarConfig[this.avatarGender].model.bodyMeshName;
    }

    getAvatarBodyMesh() {
        return this.model.getObjectByName(this.getAvatarBodyName());
    }

    getGarmentMeshFromScene(garmentName) {
        const garment = this.scene.getObjectByName(garmentName);
        return !!garment ? getGarmentMesh(garment) : undefined;
    }

    isFemale() {
        return this.avatarGender === 'female';
    }

    toggleFullScreen() {
        this.fullScreen = !this.fullScreen;
    }

    instantSceneUpdate(options) {
        const {garment, garmentType} = options;
    
        if (!!garmentType) {
            this.removeFromUpdateQueueByType(garmentType);
        }
        if (!!garment) {
            this.scene.add(garment);
            this.applyMorphTargetToMesh(garmentType, getGarmentMesh(garment));
        }
    }

    removeFromUpdateQueueByType(type) {
        const itemsToRemove = this.sceneRenderQueue.filter(item => item.garmentType === type)        
        let item;

        this.sceneRenderQueue = this.sceneRenderQueue.filter(item => item.garmentType !== type);

        while (itemsToRemove.length > 0) {
            item = itemsToRemove.shift();
            debugLog('Removing:', item.id);
            this.scene.remove(this.scene.getObjectByName(item.id));
          }
    }

    updateRenderQueue(options) {
        const { method, id, garment, garmentType } = options;

        const index = this.sceneRenderQueue.findIndex(item => {
            return item.id === id;
        });

        if (method === 'update') {
            this.sceneRenderQueue.push(options);
        } else {
            if (index > -1) {
                this.sceneRenderQueue.splice(index, 1);
            } 
            this.sceneRenderQueue.push({method, id, garmentType, garment});
        }

        debugLog('updateRenderQueue: ' + options.method + ' ' + options.garmentType + ' (' + options.id + ')', this.sceneRenderQueue.length);
    };

    applyMorphTargetToMesh(garmentType, mesh) {

        if (this.enableFitting && isAffectedByMorphing(garmentType)) {
        
            setTimeout(() => {
                applyMorphTargetImpact({
                    mesh: mesh,
                    updateMorphDelta: false
                });
            }, 100);

        }

    }

    processUpdateQueue() {
        debugLog('processUpdateQueue', this.sceneRenderQueue.length);
        
        const that = this;
        const scene = this.scene;
        let item;

        while (this.sceneRenderQueue.length > 0) {
            item = this.sceneRenderQueue.shift();

            if (item.method === 'update') {
                debugLog('Updating mesh:', item.id);
                if (!!item.garment) {
                    item.garment.geometry.setAttribute('position', item.newPositions.clone());
                    this.applyMorphTargetToMesh(item.garmentType, item.garment);
                }
            } else if (item.method === 'add') {
                debugLog('Adding to scene:', item.id);
                this.removeFromSceneById(item.id, function() {
                    if (isFootGarment(item.garmentType)) {
                        setBonesParams(item.id);
                    }
                    scene.add(item.garment);
                    that.applyMorphTargetToMesh(item.garmentType, getGarmentMesh(item.garment));
                });
            } else {
                debugLog('Removing from scene:', item.id);
                this.removeFromSceneById(item.id);
            }
        }

        disableLoadingScreen(200);
    }

    removeFromSceneById(id, callback) {
        const itemsToRemove = this.scene.children.filter(item => item.name === id);
        if (itemsToRemove.length > 0) {
            itemsToRemove.forEach((item, key, arr) => {
                this.scene.remove(item);
                if (Object.is(arr.length - 1, key) && typeof callback === 'function') {
                    callback();
                }
            });
        } else if (typeof callback === 'function') {
            callback();            
        }
    }

    getActiveGarmentTypes() {
        let activeType = '';
        let activeTypes = [];
    
        for (const [key, slot] of Object.entries(this.garmentSlots)) {
            activeType = slot.activeType;
            if (!!activeType) {
                activeTypes.push(activeType);
            }
          }
    
        return onlyUnique(activeTypes);
    }
    
    getActiveGarmentTypesForSmoothing() {    
        const activeTypes = this.getActiveGarmentTypes();
        
        return activeTypes.filter(item => !!this.smoothingSlots[item]);    
    }

    saveSmoothingSavedPositions(options) {

        const {innerGarmentId, outerGarmentId, outerGarmentPath, smoothingResult} = options;
        let outerGarmentPathById = [];
        
        if (!this.smoothingSavedPositions[innerGarmentId]) {
            this.smoothingSavedPositions[innerGarmentId] = {};
        }

        let savedPositionsSlot = this.smoothingSavedPositions[innerGarmentId];

        if (!!outerGarmentPath && outerGarmentPath.length > 0) {
            outerGarmentPathById = outerGarmentPath.map(type => {
                return this.smoothingSlots[type].garmentId;
            });

            outerGarmentPathById.forEach(garmentId => {
                savedPositionsSlot = savedPositionsSlot[garmentId];
            });
        }
        
        if (!savedPositionsSlot[outerGarmentId]) {
            savedPositionsSlot[outerGarmentId] = {};
        }

        savedPositionsSlot[outerGarmentId] = {
            positions: smoothingResult
        };

    }

    getSmoothingSavedPositions(options) {

        const {innerGarmentId, outerGarmentId, outerGarmentPath} = options;

        let outerGarmentPathById = [];
        let savedPositions;
        
        if (!this.smoothingSavedPositions[innerGarmentId]) {
            return;
        }

        let savedPositionsSlot = this.smoothingSavedPositions[innerGarmentId];

        if (!!outerGarmentPath && outerGarmentPath.length > 0) {
            outerGarmentPathById = outerGarmentPath.map(type => {
                return this.smoothingSlots[type].garmentId;
            });

            outerGarmentPathById.forEach(garmentId => {
                if (!savedPositionsSlot[garmentId]) {
                    return;
                } else {
                    savedPositionsSlot = savedPositionsSlot[garmentId];
                }
            });
        }
        
        if (!!savedPositionsSlot[outerGarmentId] && savedPositionsSlot[outerGarmentId].positions) {
            savedPositions = savedPositionsSlot[outerGarmentId].positions;
        }

        return savedPositions;
    }

    getOffsetFromCache(vertexPoint) {

        if (!this.enableSmoothingCache || this.verticesCache.length === 0) {
            return;
        }

        const closePoints = this.verticesCache.filter(item => item['originalPoint'].distanceTo(vertexPoint) < 0.0001);

        return closePoints.length > 0 ? closePoints[0].offsetPoint : undefined;
    }

    saveOffsetToCache(vertexPoint, offsetPoint) {

        offsetPoint = offsetPoint || vertexPoint;

        if (this.enableSmoothingCache) {
            this.verticesCache.push({
                originalPoint: vertexPoint.clone(),
                offsetPoint: offsetPoint.clone()
            });
        }
    }

    resetVerticesCache() {
        this.verticesCache = [];
    }

    getPathToModel() {
        const avatarConfig = this.avatarConfig;
        return avatarConfig.common.model.path + avatarConfig[this.avatarGender].model.fileName;
    }

    getModelEyesMesh() {
        const meshName = this.avatarConfig[this.avatarGender].model.eyesMeshName;
        return this.scene.getObjectByName(meshName);
    }

    getModelMouthMesh() {
        const meshName = this.avatarConfig[this.avatarGender].model.mouthMeshName;
        return this.scene.getObjectByName(meshName);
    }

}

const avatarApp = new AvatarApp();

export { avatarApp as avatarApplication };
