import { dressGarment } from '../clothes/dressGarment';
import modelsData from '../../../public/colors-for-items';
import { currentAvatarProps, setAvatarProperty, saveAvatarProps } from '../avatar/avatarProps';

const modelsWithColors = modelsData.map((modelData) => modelData.model);

function isWithColors(target) {
  return modelsWithColors.includes(target);
}

function addColorPickerHandlers() {

  document.addEventListener('click', (e) => {
    let colorPicker = document.querySelector('.color-picker');

    if (
      isWithColors(e.target.dataset.source) ||
      e.target.classList.contains('color-picker__color-container') ||
      e.target.classList.contains('color-picker__color') ||
      e.target === colorPicker
    ) {
      colorPicker.style.display = 'flex';
      if (e.target !== colorPicker) colorPicker.replaceChildren();
    } else {
      colorPicker.style.display = 'none';
    }

    const [selectedModel] = modelsData.filter((model) => model.model === e.target.dataset.source);

    if (selectedModel) {
      const colors = selectedModel.colors;
      const childElements = [];

      for (const color in colors) {
        const element = document.createElement('div');
        element.classList.add('js-color-picker');
        element.classList.add('color-picker__color-container');

        element.addEventListener('click', () => {
          dressGarment({ id: selectedModel.model, color: color, garmentType: selectedModel.type });
          setAvatarProperty(selectedModel.type, { type: selectedModel.type, model: selectedModel.model, color: color });
          saveAvatarProps();
        });

        const innerElement = document.createElement('div');
        innerElement.classList.add('color-picker__color');
        innerElement.style = `--bgcolor: ${colors[color]}`;

        element.appendChild(innerElement);
        // if (selectedModel.type === 'hair') {
        //   if (innerElement.style.cssText.split(': ')[1].slice(0, -1) === colors[currentAvatarProps.hair.color]) {
        //     element.classList.add('color-picker__color_active');
        //   }
        // }
        // if (selectedModel.type === 'skirt') {
        //   if (innerElement.style.cssText.split(': ')[1].slice(0, -1) === colors[currentAvatarProps.skirt.color]) {
        //     element.classList.add('color-picker__color_active');
        //   }
        // }

        childElements.push(element);
        // if (selectedModel.type === 'skirt') {
        //   if (currentAvatarProps.skirt === 'none') {
        //     childElements[0].classList.add('color-picker__color_active');
        //   }
        // }
        // if (selectedModel.type === 'hair') {
        //   if (currentAvatarProps.hair === 'none') {
        //     childElements[0].classList.add('color-picker__color_active');
        //   }
        // }
      }

      childElements.forEach((childElement) => colorPicker.appendChild(childElement));

      let colorPickers = document.querySelectorAll('.js-color-picker');

      colorPickers.forEach((colorPicker) => {
        colorPicker.addEventListener('click', (e) => {
          e.stopImmediatePropagation();
          colorPickers.forEach((colorPicker) => colorPicker.classList.remove('color-picker__color_active'));
          if (!colorPicker.classList.contains('color-picker__color_active'))
            colorPicker.classList.add('color-picker__color_active');
        });
      });
    }
  });
}

export { addColorPickerHandlers };
