import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { avatarApplication as app } from '../scene/application';

import { getAvatarStartupProps, dressAllStoredClothes } from '../utils/dress-utils';

import { cloneBodyMeshForSmoothing } from '../processing/smoothing'
import { cloneBodyMeshForFitting } from '../processing/fitting'

import { loadAnimation } from './animation';
import { loadBodyMaterial, loadTeethMaterial, loadEyesMaterial } from './materials';
import { setSkinColor } from './skinColor';
import { initFaceMorphs, initBodyMorphs, initBodyMorphsLegacy } from './morphs';

function loadAvatar(avatarProps) {
  if (app.web) {
    loadModelWeb(avatarProps);
  } else {
    loadModel(avatarProps)
  }
}

function loadModel(avatarProps) {
    //TODO Брать avatarProps из currentAvatarProps

    if (app.scene.getObjectByName(app.avatarModelName)) {
        return;
    }

    const manager = app.commonLoadingManager;
    const fbxLoader = new FBXLoader(manager);
    const path = app.getPathToModel();

    fbxLoader.load(`${path}`, (model) => {

        cloneBodyMeshForSmoothing(model);
        cloneBodyMeshForFitting(model);

        if (app.isFemale()) {
            model.getObjectByName('CHs_G18_Eyes_Shell').material.visible = false;
            model.getObjectByName('CH_Girl18yo_Brow0').visible = false;
        } else {
            model.getObjectByName('CH_B18_Eyes_Shell').material.visible = 0;
        }
            
        model.name = app.avatarModelName;

        app.model = model;
        app.mixer = new THREE.AnimationMixer(model);
      
        model.scale.setScalar(2);

        avatarProps = getAvatarStartupProps(avatarProps);
    
        loadAnimation(avatarProps.pose);
      
        app.scene.add(model);
      
        const bodyMesh = app.getAvatarBodyMesh();
      
        bodyMesh.castShadow = true;
        bodyMesh.receiveShadow = true;
      
        loadBodyMaterial();      
        loadEyesMaterial();
        loadTeethMaterial();
            
        setSkinColor(avatarProps.skin);

        initFaceMorphs(avatarProps);
        initBodyMorphs(avatarProps);

        if (!app.isFemale()) {
            initBodyMorphsLegacy(avatarProps);
        }
      
        dressAllStoredClothes(avatarProps);
    
    });
  
}


function loadModelWeb(avatarProps) {

    if (app.scene.getObjectByName('avatar')) {
        return;
    }

    const manager = app.commonLoadingManager;
    const glbLoader = new GLTFLoader(manager);
    const path = app.getPathToModel();

    glbLoader.load(`${path}`, (object) => {
        
        const model = object.scene;

        model.position.set(0, -100, 0);
        model.scale.setScalar(2);
        model.name = 'avatar';
        
        app.model = model;
        app.mixer = new THREE.AnimationMixer(model);
        
        avatarProps = getAvatarStartupProps(avatarProps);

        loadAnimation(avatarProps.pose);

        app.scene.add(model);
        
        loadBodyMaterial();
        loadEyesMaterial();

        setSkinColor();

        dressAllStoredClothes(avatarProps);

    });
}

export { loadAvatar };