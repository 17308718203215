const bonesList = [
    'Joint_Breast_L',
    'Joint_Breast_R',
    'Joint_Buttock_L',
    'Joint_Buttock_R',
    'Joint_Chest',
    'Joint_Clavicle_L',
    'Joint_Clavicle_R',
    'Joint_Elbow_L',
    'Joint_Elbow_R',
    'Joint_Face',
    'Joint_Foot_L',
    'Joint_Foot_R',
    'Joint_ForeArm_L',
    'Joint_ForeArm_R',
    'Joint_ForeArm_Twist_L',
    'Joint_ForeArm_Twist_R',
    'Joint_Hand_L',
    'Joint_Hand_R',
    'Joint_Head',
    'Joint_Hip_L',
    'Joint_Hip_R',
    'Joint_Knee_L',
    'Joint_Knee_R',
    'Joint_Neck1',
    'Joint_Neck2',
    'Joint_Pelvis',
    'Joint_Root',
    'Joint_Shin_L',
    'Joint_Shin_R',
    'Joint_Shin_Mid_L',
    'Joint_Shin_Mid_R',
    'Joint_Shoulder_L',
    'Joint_Shoulder_R',
    'Joint_Spine1',
    'Joint_Spine2',
    'Joint_Thigh_L',
    'Joint_Thigh_R',
    'Joint_Thigh_Mid_L',
    'Joint_Thigh_Mid_R',
    'Joint_Toe_L',
    'Joint_Toe_R',
    'Joint_UpperArm_L',
    'Joint_UpperArm_R',
    'Joint_UpperArm_Twist_L',
    'Joint_UpperArm_Twist_R',
    'Joint_Wrist_L',
    'Joint_Wrist_R',
    ];

const faceBonesList = [
    'Joint_Face_Brow_In_L',
    'Joint_Face_Brow_In_R',
    'Joint_Face_Brow_Mid_L',
    'Joint_Face_Brow_Mid_R',
    'Joint_Face_Brow_Out_L',
    'Joint_Face_Brow_Out_R',
    'Joint_Face_Lips_Corner_R',
    'Joint_Face_Lips_Corner_L',
    'Joint_Face_Lids_L',
    'Joint_Face_Lids_Lower_0_L',
    'Joint_Face_Lids_Lower_1_L',
    'Joint_Face_Lids_Lower_2_L',
    'Joint_Face_Lids_Lower_3_L',
    'Joint_Face_Lids_Lower_4_L',
    'Joint_Face_Lids_Corner_Internal_L',
    'Joint_Face_Lids_Upper_0_L',
    'Joint_Face_Lids_Upper_1_L',
    'Joint_Face_Lids_Upper_2_L',
    'Joint_Face_Lids_Upper_3_L',
    'Joint_Face_Lids_Upper_4_L',
    'Joint_Face_Lids_Corner_Lateral_L',
    'Joint_Face_Lids_R',
    'Joint_Face_Lids_Corner_Internal_R',
    'Joint_Face_Lids_Lower_0_R',
    'Joint_Face_Lids_Lower_1_R',
    'Joint_Face_Lids_Lower_2_R',
    'Joint_Face_Lids_Lower_3_R',
    'Joint_Face_Lids_Lower_4_R',
    'Joint_Face_Lids_Corner_Lateral_R',
    'Joint_Face_Lids_Upper_0_R',
    'Joint_Face_Lids_Upper_1_R',
    'Joint_Face_Lids_Upper_2_R',
    'Joint_Face_Lids_Upper_3_R',
    'Joint_Face_Lids_Upper_4_R',
]

const fingerBonesList = [
    'Joint_Finger_Big0_L',
    'Joint_Finger_Big1_L',
    'Joint_Finger_Big2_L',
    'Joint_Finger_Aim0_L',
    'Joint_Finger_Aim1_L',
    'Joint_Finger_Aim2_L',
    'Joint_Finger_Mid0_L',
    'Joint_Finger_Mid1_L',
    'Joint_Finger_Mid2_L',
    'Joint_Finger_Ring0_L',
    'Joint_Finger_Ring1_L',
    'Joint_Finger_Ring2_L',
    'Joint_Finger_Pinky0_L',
    'Joint_Finger_Pinky1_L',
    'Joint_Finger_Pinky2_L',
    'Joint_Finger_Big0_R',
    'Joint_Finger_Big1_R',
    'Joint_Finger_Big2_R',
    'Joint_Finger_Aim0_R',
    'Joint_Finger_Aim1_R',
    'Joint_Finger_Aim2_R',
    'Joint_Finger_Mid0_R',
    'Joint_Finger_Mid1_R',
    'Joint_Finger_Mid2_R',
    'Joint_Finger_Ring0_R',
    'Joint_Finger_Ring1_R',
    'Joint_Finger_Ring2_R',
    'Joint_Finger_Pinky0_R',
    'Joint_Finger_Pinky1_R',
    'Joint_Finger_Pinky2_R',
];

const boneColors = [
    '#0076b6',
    '#00984f',
    '#ca222f',
    '#00abbd',
    '#6c4a97',
    '#00a751',
    '#8dc740',
    '#fad500',
    '#af762e',
    '#8c4b36',
    '#fd2b81',
    '#8ed7e0',
    '#82c09b',
    '#2986cc',
    '#c27ba0',
    '#93c47d',
    '#f1c232',
    '#7f6000',
    '#783f04',
    '#c09f30',
    '#737f8c',
    '#e296b4',
    '#cd5f2d',
    '#e8d9bd',
    '#6ac9ce',
    '#f57d32',
    '#ec008d',
    '#4ba941',
    '#ff1111',
    '#b0e0e6',
    '#4a6448',
    '#e6d1d1',
    '#a8d8ea',
    '#ffb347',
    '#e6b89c',
    '#b2b2b2',
    '#9c9e9f',
    '#6b7a8f',
    '#1111ee',
    '#83b4df',
    '#6179ff',
    '#d01e38',
    '#f77e9f',
    '#d6bbb2',
    '#c3aa81',
    '#81aac3',
    '#b2bbd6',
]

function getBoneColor(bone) {
    return getColorByName(bone.name);
}

function getColorByName(boneName) {
    let index = -1;
    index = bonesList.indexOf(boneName);
    return index > -1 ? boneColors[index] : '#000000';
}

function getBonesWithColors(bones) {

    let bonesWithColors = [];
    let index = -1;
    let boneObj = {};

    bones.forEach(bone => {
        index = bonesList.indexOf(bone.name);
        
        boneObj.name = bone.name;
        boneObj.color = getBoneColor(bone);

        // if (index < 0) console.log('- Not found in bones list:', bone.name);

        bonesWithColors.push({...boneObj});
    });

    return bonesWithColors;
}

function showBonesColorsHelper() {
    const colorsContainer = document.createElement('div');
    colorsContainer.className = 'js-bones-colors';
    colorsContainer.style = 'border: 1px solid gray; position:fixed; bottom: 10px; right: 10px; text-align: center; font-size: 14px; padding: 5px; width: 400px; background: rgba(50,50,50,0.5); border-radius: 2px;';
    document.body.appendChild(colorsContainer);

    bonesList.forEach((boneName, index) => {
        colorsContainer.appendChild(createBoneColorSpan(boneName));
    });
}

function createBoneColorSpan(boneName){
  
    const boneSpan = document.createElement('span');
    boneSpan.style = 'display: inline-block; vertical-align: middle; border: 1px solid #888; padding: 3px 6px; white-space: nowrap; margin: 3px; cursor: default;';
    boneSpan.innerHTML = boneName;
    boneSpan.style.color = '#000';
    boneSpan.style.background = getColorByName(boneName);
    boneSpan.dataset.color = getColorByName(boneName);

    boneSpan.addEventListener('click', (e) => {
        console.log(boneSpan.innerHTML, boneSpan.dataset.color);
    });

    return boneSpan;
  };


export { bonesList, boneColors, getBonesWithColors, getBoneColor, getColorByName, showBonesColorsHelper };