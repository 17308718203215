import { cookies } from '../utils/cookies';
import api, { refreshTokens, setApiTokens } from '../api';
import { avatarService } from './avatarService';

async function setToken(auth) {
    api.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${auth}`;
        return config;
    });
}

async function getAvatars() {
    const { auth, refreshToken } = await cookies.get('auth');

    refreshTokens();
    
    const { user } = cookies.get('user');

    let avatarOptions;

    await avatarService
        .fetchUserAvatars(user.id)
        .then((response) => {
            avatarOptions = response.data.filter(event => event.avatarType === 'AvatarEngine');
        })
        .catch((error) => {
            console.log(error);
        });

    return avatarOptions;

}

export { getAvatars };
