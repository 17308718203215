import axios from "axios";
import {cookies, getDateAfter30Days} from '../utils/cookies';
import {authService} from "../services";

const api = axios.create({
    baseURL: 'https://pg.sysdyn.ru/api/v1/',
    responseType: 'json',

    headers: {

        Accept: '*/*',
        Authorization: `Bearer ${cookies.get('auth')?.auth}`,
        'Content-Type': 'application/json',




    },
});

api.interceptors.response.use(
    (response) => {
        setTimeout(()=>{
           api.defaults.headers.Authorization = `Bearer ${cookies.get('auth')?.auth}`;
            response.config.headers.Authorization = `Bearer ${cookies.get('auth')?.auth}`;

        },2000)

     return   response
    },
    (error) => {
        const {
            config,
            response: {status},
        } = error;

        if (status === 500){
            console.log('ЕГГОГ 500');
            refreshTokens().then(()=>{
                config.headers.Authorization = `Bearer ${cookies.get('auth')?.auth}`;


            });
        }
        if (status === 400){
            cookies.remove('auth', { path: '/' });
            cookies.remove('user', { path: '/' });
            cookies.remove('avatar-info', { path: '/' });
            window.location.reload();
        }
        if (status === 401 && cookies.get('auth')?.auth && cookies.get('auth')?.refreshToken) {
            refreshTokens().then(()=>{
                config.headers.Authorization = `Bearer ${cookies.get('auth')?.auth}`;
       window.location.assign('/');

            });

        }
    }
);

async function refreshTokens() {
    if(cookies.get('auth')?.refreshToken){
        authService.refreshToken(cookies.get('auth')?.refreshToken).then((e)=>{

            cookies.set(
                'auth',
                {auth: e.data.accessToken, refreshToken: e.data.refreshToken},
                {
                    path: '/',
                    secure: true,
                    sameSite: 'lax',
                    expires: getDateAfter30Days(),
                }
            );
        })
    }

}

setInterval(() => {
    refreshTokens();
}, 60000);



export default api;
export { refreshTokens};