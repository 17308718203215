import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { addGarmentToBody, updateSlots, isUnderwear, isSmoothingSupported, getGarmentMesh } from './common';
import { isGarmentBlackListed } from '../processing/relations';
import { cloneForSmoothing } from "../processing/smoothing";
import { garmentLayers } from '../store/store';
import { cloneGarmentForFitting } from "../processing/fitting";
import { setMaterial } from './setMaterial';

import { avatarApplication as app } from '../scene/application';


function dressGarment(options) {
    const {id, color, garmentType, trigger} = options;

    const manager = isUnderwear(id) || !isSmoothingSupported(garmentType)
        ? app.commonLoadingManager
        : app.garmentsLoadingManager;
    
    app.garmentsLoading++;

    let loadingIcon = document.getElementById('loaderWrapper');
    loadingIcon.classList.remove('hidden');

    const path = getAssetPath({garmentType, id});

    if (app.web) {
        const garmentLoader = new GLTFLoader(manager);
        garmentLoader.load(`${path}_web.glb`,
            // called when the resource is loaded
            function (event) {
                const garment = event.scene.children[0];
                prepareForSmoothing({garment, garmentType, id})
                addGarmentToScene({garment, garmentType, id, color, trigger});
            },
            // called while loading is progressing
            function (xhr) {
                // console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // called when loading has errors
            function (error) {
                app.garmentsLoading--;
            });

    } else {
        const garmentLoader = new FBXLoader(manager);
        garmentLoader.load(`${path}.fbx`,
            // called when the resource is loaded
            function (garment) {
                cloneGarmentForFitting({garment, garmentType, id});
                prepareForSmoothing({garment, garmentType, id});
                addGarmentToScene({garment, garmentType, id, color, trigger});            
            },
            // called while loading is progressing
            function (xhr) {
                // console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // called when loading has errors
            function (error) {
                app.garmentsLoading--;
            });
    }
}

function getAssetPath(options) {
    const {garmentType, id} = options;
    let path;
    
    if (garmentType === 'brows') {
        path = `models/brows/${id}`
    } else if (garmentType === 'hair') {
        path = `models/hair/${id}/${id}`
    } else {
        path = `models/clothes/${garmentType}/${id}/${id}`
    }

    return path;
}

function loadHairMaps(garment) {

    const materialMap = new THREE.TextureLoader().load(`/texture/avatar/girlAvatar.png`);
    const mesh = getGarmentMesh(garment);

    mesh.material = new THREE.MeshStandardMaterial({
        metalness: 0,
        roughness: 1,
        map: materialMap,
    });
}

function prepareForSmoothing(options) {
    const {garmentType, id} = options;

    let isGarmentSupported = true;

    if (['brows', 'dressSet'].includes(garmentType)) {
        isGarmentSupported = false;
    }

    for (const underwearSlot in app.underwearGarments) {
        if (app.underwearGarments[`${underwearSlot}`].type === garmentType && app.underwearGarments[`${underwearSlot}`].id === id) {
            isGarmentSupported = false;
        }
    }

    if (isGarmentBlackListed(id)) {
        isGarmentSupported = false;
    }

    if (isGarmentSupported) {
        cloneForSmoothing(options);
    } else {
        delete app.smoothingSlots[garmentType];
    }

}

function attachGarmentToBody(options, garment) {
    const {garmentType} = options;

    const body = app.scene.getObjectByName('avatar');
    let matches = [];
    garment.traverse((garmentItem) => {
        if (garmentItem.isMesh) {
            garmentItem.receiveShadow = garmentLayers[garmentType].receiveShadow !== false;
            garmentItem.castShadow = garmentLayers[garmentType].castShadow !== false;
        }
        if (garmentItem.isBone) {
            let bodyBone = body.getObjectByName(garmentItem.name);

            if (!!bodyBone) {
                matches.push({
                    bodyBone: bodyBone,
                    garmentBone: garmentItem,
                });
            }
        }
    });

    matches.forEach((match) => {
        addGarmentToBody(match);
    });

}

function addGarmentToScene(options) {
    const {id, color, garment, garmentType, trigger} = options;

    let loadingIcon = document.getElementById('loaderWrapper');

    garment.name = id;

    if (garmentType === 'brows') {
        getGarmentMesh(garment).material.color.setRGB(0,0,0);
    }

    if (!!color) {
        setMaterial({ garmentType, garment, color }).then((event) => {

            updateSlots({
                garment,
                garmentType,
                garmentId: id,
                color,
                trigger,
            });

            if (isUnderwear(id) || !isSmoothingSupported(garmentType)) {
                app.instantSceneUpdate({garment, garmentType});
            } else {
                app.updateRenderQueue({method: 'add', id, garment, garmentType});
            }

            loadingIcon.classList.add('hidden');

            app.garmentsLoading--;
        });
    } else {   //TODO: надо настроить материалы для волос, потом удалить этот else

        // if (garmentType === 'hair') {
        //     loadHairMaps(garment);
        // }

        updateSlots({
            garment,
            garmentType,
            garmentId: id,
            trigger,
        });

        if (garmentType === 'panties') {
            const mesh = getGarmentMesh(garment);
            mesh.material.color.setRGB(0.05, 0.05, 0.05);
        }

        if (isUnderwear(id) || !isSmoothingSupported(garmentType)) {
            app.instantSceneUpdate({garment, garmentType});
        } else {
            app.updateRenderQueue({method: 'add', id, garment, garmentType});
        }

        loadingIcon.classList.add('hidden');
        app.garmentsLoading--;
    }

    attachGarmentToBody(options, garment);
}

export {dressGarment};
