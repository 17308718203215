// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__apply-btn/avatar-controls__apply-btn.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./__rotate-btn/avatar-controls__rotate-btn.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avatar-controls {\n  gap: 14px;\n  height: 77px;\n  color: var(--color-primary-white);\n  transition: all 0.3s ease;\n}\n", "",{"version":3,"sources":["webpack://./src/css/components/avatar-controls/avatar-controls.css"],"names":[],"mappings":"AAGA;EACE,SAAS;EACT,YAAY;EACZ,iCAAiC;EACjC,yBAAyB;AAC3B","sourcesContent":["@import url(./__apply-btn/avatar-controls__apply-btn.css);\n@import url(./__rotate-btn/avatar-controls__rotate-btn.css);\n\n.avatar-controls {\n  gap: 14px;\n  height: 77px;\n  color: var(--color-primary-white);\n  transition: all 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
