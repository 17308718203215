import { clearGarments } from './common'
import { dressGarment } from './dressGarment'
import { currentAvatarProps, setAvatarProperty, removeAvatarProperty, saveAvatarProps } from '../avatar/avatarProps';
import { isGarment, isRemovable } from '../store/store';

const dressSets = {
    setOne: [
        {
            type: 'sweater',
            id: 'WR_G18_Hoodie_001', 
            color: 'Yellow'
        },
        {
            type: 'pants',
            id: 'WR_G18_Pants_Cargo', 
            color: 'Green'
        },
        {
            type: 'shoes',
            id: 'WR_G18_Boots_001', 
            color: 'Beige'
        },
        {
            type: 'glasses',
            id: 'WR_G18_Glasses_RoundThin', 
            color: 'Beige'
        },
    ],
    setTwo: [
        {
            type: 'dress',
            id: 'WR_G18_Dress_Apron_001', 
            color: 'Blue'
        },
        {
            type: 'shoes',
            id: 'WR_G18_HeelStrap_001', 
            color: 'Black'
        },
        {
            type: 'earrings',
            id: 'WR_G18_Jewelry_Earrings_Twist_Chains', 
            color: 'Yellow'
        },
        {
            type: 'necklace',
            id: 'WR_G18_Choker_01', 
            color: 'Black'
        },
    ],
    setThree: [
        {
            type: 'tshirt',
            id: 'WR_G18_TShirt_Slim',
            color: 'Standart'
        },
        {
            type: 'pants',
            id: 'WR_G18_Pants_NinjaCargo', 
            color: 'Yellow'
        },
        {
            type: 'shoes',
            id: 'WR_G18_Sneakers_CnvrsAnkle', 
            color: 'Black'
        },
    ],
    setFour: [
        {
            type: 'body',
            id: 'WR_G18_Bodysuit_LongSleeveHighNeck', 
            color: 'Black'
        },
        {
            type: 'pants',
            id: 'WR_G18_Jeans_WideLeg', 
            color: 'Black'
        },
        {
            type: 'shoes',
            id: 'WR_G18_Sneakers_Classic', 
            color: 'Red'
        },
        {
            type: 'bracelet',
            id: 'WR_G18_Watch_AppWatch', 
            color: 'Pink_Light'
        },
        {
            type: 'hat',
            id: 'WR_G18_Cap_Tennis', 
            color: 'Black'
        },
    ],
    setFive: [
        {
            type: 'bra',
            id: 'WR_G18_Singlet_003', 
            color: 'Standart'
        },
        {
            type: 'jacket',
            id: 'WR_G18_Jacket_Leather_00', 
            color: 'Black'
        },
        {
            type: 'necklace',
            id: 'WR_G18_Chains_Neck_01', 
            color: 'Gray_Light'
        },
        {
            type: 'skirt',
            id: 'WR_G18_Skirt_TubeShortHighWaist', 
            color: 'Brown_Light'
        },
        {
            type: 'shoes',
            id: 'WR_G18_Boots_001', 
            color: 'Beige'
        },
    ],
    setSix: [
        {
            type: 'dress',
            id: 'WR_G18_Dress_Combination', 
        },
        {
            type: 'jacket',
            id: 'WR_G18_Vest_PufferCrop', 
        },
        {
            type: 'shoes',
            id: 'WR_G18_Shoes_Boots_MickeyMouse', 
        },
        {
            type: 'bag',
            id: 'WR_G18_Bag_RoundShoulderWideStrapChainBubbles', 
        },
        {
            type: 'earrings',
            id: 'WR_G18_Jewelry_Earrings_ThinRings', 
        },
    ],
}

function dressSet(id) {

    clearGarments({garmentType: 'dressSet'});

    Object.keys(currentAvatarProps).forEach(type => {
        if (isGarment(type) && isRemovable(type)) {
            removeAvatarProperty(type);
        }
    });
    
    dressSets[id].forEach(garment => {
        const options = {
            garmentType: garment.type,
            id: garment.id
        }
    
        if (!!garment.color) {
            options.color = garment.color;
        }
    
        dressGarment(options);

        const garmentProps = {
            type: garment.type,
            model: garment.id,
            color: garment.color,
        };
        setAvatarProperty(garment.type, garmentProps);
    });

    // resetStore(JSON.parse(resJson[0]['itemIds']));

    saveAvatarProps();

}

export { dressSet }