// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/avatar-controls/avatar-controls.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/editor-selector/editor-selector.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/item-selector/item-selector.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/side-menu/side-menu.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/export-modal/topmenu.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/export-modal/export-modal.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/launcher/launcher.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/created-avatar/created-avatar.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../node_modules/css-loader/dist/cjs.js!./components/color-selector/color-selector.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n}\n\nhtml {\n  line-height: 1.15; /* 1 */\n  -webkit-text-size-adjust: 100%; /* 2 */\n}\n\nbody {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  margin: 0;\n  padding: 0;\n  background-color: black;\n  position: relative;\n  overflow: hidden;\n  font-family: 'Suisse Intl', 'Helvetica Neue', Verdana, sans-serif;\n}\n\n.scene-3d {\n  width: 50%;\n  height: 100vh;\n  background:#1f1f1f;\n}\n", "",{"version":3,"sources":["webpack://./src/css/index.css"],"names":[],"mappings":"AAUA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB,EAAE,MAAM;EACzB,8BAA8B,EAAE,MAAM;AACxC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,SAAS;EACT,UAAU;EACV,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,iEAAiE;AACnE;;AAEA;EACE,UAAU;EACV,aAAa;EACb,kBAAkB;AACpB","sourcesContent":["@import url(../css/components/avatar-controls/avatar-controls.css);\n@import url(../css/components/editor-selector/editor-selector.css);\n@import url(../css/components/item-selector/item-selector.css);\n@import url(../css/components/side-menu/side-menu.css);\n@import url(../css/components/export-modal/topmenu.css);\n@import url(../css/components/export-modal/export-modal.css);\n@import url(../css/components/launcher/launcher.css);\n@import url(../css/components/created-avatar/created-avatar.css);\n@import url(../css/components/color-selector/color-selector.css);\n\n* {\n  box-sizing: border-box;\n}\n\nhtml {\n  line-height: 1.15; /* 1 */\n  -webkit-text-size-adjust: 100%; /* 2 */\n}\n\nbody {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  margin: 0;\n  padding: 0;\n  background-color: black;\n  position: relative;\n  overflow: hidden;\n  font-family: 'Suisse Intl', 'Helvetica Neue', Verdana, sans-serif;\n}\n\n.scene-3d {\n  width: 50%;\n  height: 100vh;\n  background:#1f1f1f;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
