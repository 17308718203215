import * as THREE from 'three';

const _vector = /*@__PURE__*/ new THREE.Vector3();
const _boneMatrix = /*@__PURE__*/ new THREE.Matrix4();
const _matrixWorldInv = /*@__PURE__*/ new THREE.Matrix4();

class SkeletonHelper2 extends THREE.SkeletonHelper {

	constructor( object ) {
        super (object)

        this.matrixWorldAutoUpdate = false;
    }

    updateMatrixWorld( force ) {

		const bones = this.bones;

		const geometry = this.geometry;
		const position = geometry.getAttribute( 'position' );

		_matrixWorldInv.copy( this.root.matrixWorld ).invert();

		for ( let i = 0, j = 0; i < bones.length; i ++ ) {

			const bone = bones[ i ];

			if ( bone.parent && bone.parent.isBone ) {

				_boneMatrix.multiplyMatrices( _matrixWorldInv, bone.matrixWorld );
				_vector.setFromMatrixPosition( _boneMatrix );
				position.setXYZ( j, _vector.x, _vector.y, _vector.z );

				_boneMatrix.multiplyMatrices( _matrixWorldInv, bone.parent.matrixWorld );
				_vector.setFromMatrixPosition( _boneMatrix );
				position.setXYZ( j + 1, _vector.x, _vector.y, _vector.z );

				j += 2;

			}

		}

		geometry.getAttribute( 'position' ).needsUpdate = true;

//		super.updateMatrixWorld( force );

	}
}

class SkeletonBoneHelper extends THREE.Line {

	constructor( object, bone, color, visible ) {

		const geometry = new THREE.BufferGeometry();

		const vertices = [];
		const colors = [];
		
        const color1 = !!color ? new THREE.Color(color) : new THREE.Color( 1, 0, 0 );
		const color2 = !!color ? new THREE.Color(color) : new THREE.Color( 1, 0.6, 0 );

        vertices.push( 0, 0, 0 );
        vertices.push( 0, 0, 0 );
        colors.push( color1.r, color1.g, color1.b );
        colors.push( color2.r, color2.g, color2.b );

		geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
		geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

		const material = new THREE.LineBasicMaterial( { 
            vertexColors: true, 
            depthTest: false, 
            depthWrite: false, 
            toneMapped: false,
			// color: '#0000FF',
			visible: !!visible,
            transparent: true 
        } );

		super( geometry, material );

		this.isBoneHelper = true;

		this.type = 'BoneHelper';
		this.name = bone.name;

		this.root = object;
		this.bones = [];

        this.bones.push(bone);

		this.matrix = object.matrixWorld;
		this.matrixAutoUpdate = false;

        this.matrixWorldAutoUpdate = false;

    }

    updateMatrixWorld( force ) {

		const bones = this.bones;

		const geometry = this.geometry;
		const position = geometry.getAttribute( 'position' );

		_matrixWorldInv.copy( this.root.matrixWorld ).invert();

		for ( let i = 0, j = 0; i < bones.length; i ++ ) {

			const bone = bones[ i ];

			if ( bone.parent && bone.parent.isBone ) {

				_boneMatrix.multiplyMatrices( _matrixWorldInv, bone.matrixWorld );
				_vector.setFromMatrixPosition( _boneMatrix );
				position.setXYZ( j, _vector.x, _vector.y, _vector.z );

				_boneMatrix.multiplyMatrices( _matrixWorldInv, bone.parent.matrixWorld );
				_vector.setFromMatrixPosition( _boneMatrix );
				position.setXYZ( j + 1, _vector.x, _vector.y, _vector.z );

				j += 2;

			}

		}

		// geometry.getAttribute( 'position' ).needsUpdate = true;

		// super.updateMatrixWorld( force );

	}
}

export { SkeletonHelper2, SkeletonBoneHelper };